import * as types from "../types";
import {dispatch, getTodayStringValue} from "./util";
import {instructorService} from "../services";
import messages from "../util/messages";
import {saveAs} from "file-saver";


export const addInstructorAction = (name, lastname, email, biography, curriculum1, avatar1) => { // change de param

  const formData = new FormData();
  let avatar = avatar1;
  let curriculum = curriculum1;
  avatar.lastModifiedDate = new Date();
  avatar.name = name;
  formData.append('avatar', avatar);
  formData.append('Name', name || "");
  formData.append('LastName', lastname || "");
  formData.append('Email', email || "");
  formData.append('Biography', biography || "");
  formData.append('Curriculum', curriculum || "");

  dispatch(types.FETCHING);
  instructorService().addInstructor(formData).then(res => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_INSTRUCTOR_SUCCESS, {
      data: {
        name,
        lastname,
        biography,
        avatar1
      }
    });
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: messages[types.ADD_INSTRUCTOR_SUCCESS],
      color: 'info'
    });

    dispatch(types.TOGGLE_INSTRUCTOR_FROM);
    dispatch(types.RESET_INSTRUCTOR);


  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_INSTRUCTOR_FAILURE);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: messages[types.ADD_INSTRUCTOR_FAILURE],
      color: 'danger'
    });
  });
};
export const getInstructorAction = (id) => {
  dispatch(types.FETCHING);
  instructorService().getInstructor({id}).then(res => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_INSTRUCTOR, res);
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_INSTRUCTOR, {data: {}});
  });
};

let cancelGetInstructorsAction = false;
export const cancelGetInstructorsActionFn = (option = false) => {cancelGetInstructorsAction = option};

export const getInstructorsAction = (limit, skip, filter, expertiseId = 0, gradeLevelId = 0, columnSort = '', descending = true) => {

  dispatch(types.FETCHING);
  instructorService().getInstructors(limit, skip, filter, expertiseId, gradeLevelId, columnSort, descending).then(res => {
    if (!cancelGetInstructorsAction) {
      dispatch(types.GET_INSTRUCTOR_SUCCESS, res);
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_INSTRUCTOR_PAGINATION, {
        data: {
          count: parseInt(res.headers['x-total-count'], 10),
          limit: parseInt(res.headers['x-total-limit'], 10),
          skip: parseInt(res.headers['x-total-skip'], 10),
          filter, expertiseId, gradeLevelId, columnSort, descending
        }
      });
    } else {
      cancelGetInstructorsAction = false
    }
  }).catch((err) => {
    if (!cancelGetInstructorsAction) {
      dispatch(types.FETCHING_END);
    } else {
      cancelGetInstructorsAction = false
    }
    console.log(err)
  });
};
export const getExpertisesAction = (limit, skip, filter, columnSort, descending) => {
  dispatch(types.FETCHING);
  instructorService().getExpertises(limit, skip, filter, columnSort, descending).then(res => {
    dispatch(types.GET_EXPERTISE_SUCCESS, res);
    dispatch(types.FETCHING_END);

  }).catch((err) => {
    dispatch(types.FETCHING_END);
    console.log(err)
  });
};
export const getGradeLevelsAction = (limit, skip, filter, columnSort, descending) => {
  dispatch(types.FETCHING);
  instructorService().getGradeLevels(limit, skip, filter, columnSort, descending).then(res => {
    dispatch(types.GET_GRADELEVEL_SUCCESS, res);
    dispatch(types.FETCHING_END);

  }).catch((err) => {
    dispatch(types.FETCHING_END);
    console.log(err)
  });
};
export const updateInstructorAction = (instructor) => {
  dispatch(types.FETCHING);
  const formData = new FormData();
  let avatar = instructor.blob;
  avatar.lastModifiedDate = new Date();
  avatar.name = instructor.name;
  let curriculum = instructor.curriculum;
  formData.append('avatar', avatar);
  formData.append('Name', instructor.name || "");
  formData.append('LastName', instructor.lastName || "");
  formData.append('Email', instructor.email || "");
  formData.append('Biography', instructor.biography || "");
  formData.append('Curriculum', curriculum || "");

  instructorService().updateInstructor(formData, instructor.id).then(res => {
    dispatch(types.UPDATE_INSTRUCTOR_SUCCESS, {});
    dispatch(types.FETCHING_END);

    dispatch(types.TOGGLE_INSTRUCTOR_FROM);
    dispatch(types.RESET_INSTRUCTOR);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: messages[types.UPDATE_INSTRUCTOR_SUCCESS],
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: messages[types.UPDATE_INSTRUCTOR_FAILURE],
      color: 'danger'
    });
  });
};


export const deleteInstructorAction = (id) => {
  dispatch(types.FETCHING);
  instructorService().deleteInstructor(id).then(res => {
    dispatch(types.FETCHING_END);
    getInstructorsAction(10, 0)
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: messages[types.DELETE_INSTRUCTOR_SUCCESS],
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: messages[types.DELETE_INSTRUCTOR_FAILURE],
      color: 'danger'
    });
  });
};


export const deleteInstructorExpertiseAction = (instructorId, expertiseId) => {
  dispatch(types.FETCHING);
  instructorService().deleteInstructorExpertise(instructorId, expertiseId).then(res => {
    dispatch(types.FETCHING_END);
    getExpertisesInstructorAction(instructorId)
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'Delete expertise instructor',
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'Delete expertise instructor',
      color: 'danger'
    });
  });
};


export const addInstructorExpertiseAction = (id, expertiseId) => {
  dispatch(types.FETCHING);
  instructorService().addInstructorExpertise(id, expertiseId).then(res => {
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: "Add Expertise",
      color: 'info'
    });
    dispatch(types.FETCHING_END);
    getExpertisesInstructorAction(id);
  }).catch((err) => {
    console.log(err.response)

    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: "Add Expertise",
      color: 'danger'
    });

    dispatch(types.FETCHING_END);
    console.log(err)
  });
};


export const deleteInstructorGradeLevelsAction = (instructorId, gradeLevelsId) => {
  dispatch(types.FETCHING);
  instructorService().deleteInstructorGradeLevels(instructorId, gradeLevelsId).then(res => {
    dispatch(types.FETCHING_END);
    getGradeLevelsInstructorAction(instructorId)
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'Delete grade level',
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'Delete grade level',
      color: 'danger'
    });
  });
};


export const addInstructorGradeLevelsAction = (instructorId, gradeLevelsId) => {
  dispatch(types.FETCHING);
  instructorService().addInstructorGradeLevels(instructorId, gradeLevelsId).then(res => {
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: "Add Grade Levels",
      color: 'info'
    });
    dispatch(types.FETCHING_END);
    getGradeLevelsInstructorAction(instructorId);
  }).catch((err) => {
    console.log(err.response)

    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: "Add Grade Levels",
      color: 'danger'
    });

    dispatch(types.FETCHING_END);
    console.log(err)
  });
};
export const getExpertisesInstructorAction = (id) => {
  dispatch(types.FETCHING);
  instructorService().getExpertisesInstructor(id)
    .then(res => {
      dispatch(types.GET_EXPERTISE_INSTRUCTOR_SUCCESS, res);
      dispatch(types.FETCHING_END);
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      console.log(err)
    });
};

export const getGradeLevelsInstructorAction = (id) => {
  dispatch(types.FETCHING);
  instructorService().getGradeLevelsInstructor(id)
    .then(res => {
      dispatch(types.GET_GRADELEVELS_INSTRUCTOR_SUCCESS, res);
      dispatch(types.FETCHING_END);
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      console.log(err)
    });
};
export const getInstructorsExcelAction = () => {
  dispatch(types.FETCHING);
  instructorService().getInstructorsExcel().then(res => {
    dispatch(types.FETCHING_END);
    saveAs(res.data, "Instructors" + getTodayStringValue() + ".xlsx");
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'File downloaded instructor list',
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'File download instructor list',
      color: 'danger'
    });
  });
};

export const getInstructorCurriculumAction = (instructorId) => {
  dispatch(types.FETCHING);
  instructorService().getInstructorCurriculum(instructorId).then(res => {
    dispatch(types.FETCHING_END);
    saveAs(res.data, "Curriculum" + getTodayStringValue() + ".pdf");
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'File downloaded instructor curriculum',
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'File downloaded instructor curriculum',
      color: 'danger'
    });
  });
};

export const uploadInstructorCurriculumAction = (instructorId, pdf) => {
  dispatch(types.FETCHING);
  const formData = new FormData();
  formData.append('curriculum', pdf)
  instructorService().uploadInstructorCurriculum(instructorId, formData)
    .then(res => {
      dispatch(types.FETCHING_END);
      // getGroupAction(groupId)
      dispatch(types.ADD_ALERT_NOTIFICATION, {
        message: "Upload intructor curriculum",
        color: 'info'
      });
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_ALERT_NOTIFICATION, {
        message: "Upload intructor curriculum",
        color: 'danger'
      });

    });
};