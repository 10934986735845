import React from 'react';
import {Col, Label, Row, UncontrolledCollapse} from 'reactstrap';
import {getDateValue1, registerDispatch} from '../../actions/util';
import {connect} from 'react-redux';

const EventsView = ({index, event}) => {

  let eventlist = []
  eventlist.push(event.id)

  const filterStyle = {
    border: '1px dashed #e9e9e9',
    borderRadius: '6px',
    padding: '.75rem .75rem 0',
    marginBottom: '5px',
    marginTop: '5px',
    backgroundColor: '#fafafa'
  };
  return (
    <UncontrolledCollapse toggler={"#toggler-event-view-" + index}>
      <div style={filterStyle}>
        <Row>
          <h5 className='col-8'>Event Details</h5>
          <Col xs="5">
            <div>Workshop:<Label className='ml-2' style={{color: 'gray', fontWeight: 'normal'}}>{event.workshop.title}</Label></div>
            <div>Description:<Label className='ml-2' style={{textAlign: 'justify', color: 'gray', fontWeight: 'normal'}}>{event.workshop.detail}</Label></div>
            <div>Name:<Label className='ml-2' style={{textAlign: 'justify', color: 'gray', fontWeight: 'normal'}}>{event.name}</Label></div>
            <div>Title:<Label className='ml-2' style={{textAlign: 'justify', color: 'gray', fontWeight: 'normal'}}>{event.title}</Label></div>

          </Col>
          <Col xs="5">
            <div>Start Date: <Label className='ml-2' style={{color: 'gray', fontWeight: 'normal'}}> {getDateValue1(event.startdate) + " at " + event.starttime}</Label></div>
            <div>End Date: <Label className='ml-2' style={{color: 'gray', fontWeight: 'normal'}}> {getDateValue1(event.enddate) + " at " + event.endtime}</Label></div>
            <div>Credit Hours:<Label className='ml-2' style={{color: 'gray', fontWeight: 'normal'}}>  {event.credithours}</Label></div>
            <div>Capacity:<Label className='ml-2' style={{color: 'gray', fontWeight: 'normal'}}> {event.capacity} </Label></div>
            <div>Address: <Label className='ml-2' style={{color: 'gray', fontWeight: 'normal'}}> {event.eventLocation.address}</Label></div>
            <div>Showing: <Label className='ml-2' style={{color: 'gray', fontWeight: 'normal'}}> {getDateValue1(event.startshowingdate)}</Label> <Label> to </Label><Label className='ml-2' style={{
              color: 'gray',
              fontWeight: 'normal'
            }}> {getDateValue1(event.endshowingdate)}</Label></div>
          </Col>
          <Col xs="2">
            {event.instructors.length > 0 && <div>Instructor:
              <div>
                {event.instructors.map((e, i) => {
                  return (
                    <div key={'expertise-instructor-' + i} style={{color: 'gray', fontWeight: 'normal'}}> {e.name + ' ' + e.lastName}
                    </div>
                  );
                })}
              </div>
            </div>}
          </Col>
        </Row>
      </div>
    </UncontrolledCollapse>
  );
};
const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {};
};
export default connect(undefined, mapDispatchProps)(EventsView);