import React, {useState} from 'react';
import {Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import {EventsContainer, GroupContainer, InstructorContainer, LocationContainer, WorkshopsContainer} from '../index';

const RegistrationContainer = () => {
  const [activeTab, setActiveTab] = useState('event');
  const [isEventActive, setIsEventActive] = useState(true);
  const [isWSActive, setIsWSActive] = useState(true);
  const [isLocActive, setIsLocActive] = useState(true);
  const [isGroupActive, setIsGroupActive] = useState(true);
  const [isInstructorActive, setIsInstructorActive] = useState(true);
  return (
    <div className='mt-3'>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={
              'cursor-pointer ' + (activeTab === 'event' ? 'active' : '')
            }
            onClick={() => {
              setActiveTab('event');
              setIsEventActive(true);
            }}
          >
            Events
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={
              'cursor-pointer ' + (activeTab === 'workshop' ? 'active' : '')
            }
            onClick={() => {
              setActiveTab('workshop');
              setIsWSActive(true);
            }}
          >
            Workshops
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={
              'cursor-pointer ' + (activeTab === 'location' ? 'active' : '')
            }
            onClick={() => {
              setActiveTab('location');
              setIsLocActive(true);
            }}
          >
            Locations
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={
              'cursor-pointer ' + (activeTab === 'group' ? 'active' : '')
            }
            onClick={() => {
              setActiveTab('group');
              setIsGroupActive(true);
            }}
          >
            Groups
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={
              'cursor-pointer ' + (activeTab === 'instructor' ? 'active' : '')
            }
            onClick={() => {
              setActiveTab('instructor');
              setIsInstructorActive(true);
            }}
          >
            Instructors
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId='workshop'>
          {activeTab === "workshop" && <WorkshopsContainer
            isActive={isWSActive}
            setIsActive={setIsWSActive}
          />}
        </TabPane>
        <TabPane tabId='event'>
          {activeTab === "event" && <EventsContainer
            isActive={isEventActive}
            setIsActive={setIsEventActive}
          />}
        </TabPane>
        <TabPane tabId='location'>
          {activeTab === "location" && <LocationContainer
            isActive={isLocActive}
            setIsActive={setIsLocActive}
          />}
        </TabPane>
        <TabPane tabId='group'>
          {activeTab === "group" && <GroupContainer
            isActive={isGroupActive}
            setIsActive={setIsGroupActive}
          />}
        </TabPane>
        <TabPane tabId='instructor'>
          {activeTab === "instructor" && <InstructorContainer
            isActive={isInstructorActive}
            setIsActive={setIsInstructorActive}
          />}
        </TabPane>
      </TabContent>
    </div>
  );
};

export default RegistrationContainer;
