import * as types from "../types";


export const member = {
  CRUDmemberRegistration: (state = types.CREATE_MEMBER_REGISTRATION, action) => {
    switch (action.type) {
      case types.GET:
        return types.GET;
      case types.CREATE_MEMBER_REGISTRATION:
        return types.CREATE_MEMBER_REGISTRATION;
      case types.UNREGISTER_MEMBER_REGISTRATION:
        return types.UNREGISTER_MEMBER_REGISTRATION;
      default:
        return state
    }
  },

  getMembers: (state = [], action) => {
    switch (action.type) {
      case types.GET_MEMBER_SUCCESS:
        return action.data;
      case types.GET_MEMBER_FAILURE:
        return [];
      default:
        return state
    }
  },
  getRegistrationsMember: (state = [], action) => {
    switch (action.type) {
      case types.GET_MEMBER_REGISTRATION_SUCCESS:
        return action.data;
      case types.GET_MEMBER_REGISTRATION_FAILURE:
        return [];
      case types.RESET_MEMBER_REGISTRATION:
        return [];
      default:
        return state
    }
  },
  getWaitingMember: (state = [], action) => {
    switch (action.type) {
      case types.GET_MEMBER_WAITING_SUCCESS:
        return action.data;
      case types.GET_MEMBER_WAITING_FAILURE:
        return [];
      case types.RESET_MEMBER_WAITING:
        return [];
      default:
        return state
    }
  },
  paginationMemberRegistration: (
    state = {count: 0, limit: 10, skip: 0, columnSort: "", descending: false},
    action
  ) => {
    switch (action.type) {
      case types.ADD_MEMBERREGISTRATION_PAGINATION:
        return {...state, ...action.data};
      case types.RESET_MEMBERREGISTRATION_PAGINATION:
        return {count: 0, limit: 10, skip: 0};
      default:
        return state;
    }
  },
  paginationEventReminder: (
    state = {count: 0, limit: 10, skip: 0},
    action
  ) => {
    switch (action.type) {
      case types.ADD_EVENTREMINDER_PAGINATION:
        return {...state, ...action.data};
      case types.RESET_EVENTREMINDER_PAGINATION:
        return {count: 0, limit: 10, skip: 0};
      default:
        return state;
    }
  },
  paginationMemberWaiting: (
    state = {count: 0, limit: 10, skip: 0, columnSort: "", descending: true},
    action
  ) => {
    switch (action.type) {
      case types.ADD_MEMBERWAITING_PAGINATION:
        return {...state, ...action.data};
      case types.RESET_MEMBERWAITING_PAGINATION:
        return {count: 0, limit: 10, skip: 0};
      default:
        return state;
    }
  },
  paginationSearchMember: (
    state = {count: 0, limit: 10, skip: 0},
    action
  ) => {
    switch (action.type) {
      case types.ADD_MEMBERSEARCH_PAGINATION:
        return {...state, ...action.data};
      case types.RESET_MEMBERSEARCH_PAGINATION:
        return {count: 0, limit: 10, skip: 0};
      default:
        return state;
    }
  }
};
