import React from 'react';
import {Button, Col, Collapse, Label, Media, Row} from 'reactstrap';
import {registerDispatch} from '../../actions/util';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileArchive} from '@fortawesome/free-solid-svg-icons';
import {getInstructorCurriculumAction} from '../../actions/instructor';


const InstructorView = ({index, instructor, avatar, getInstructorCurriculumAction, isOpen}) => {


  const filterStyle = {
    border: '1px dashed #e9e9e9',
    borderRadius: '6px',
    padding: '.75rem .75rem 0',
    marginBottom: '5px',
    marginTop: '5px',
    backgroundColor: '#fafafa'
  };

  return (
    <Collapse isOpen={isOpen === instructor.id} toggler={"#toggler-instructor-view-" + index}>
      <div style={filterStyle}>
        <Row>
          <h5 className='col-8'>{instructor.name + ' ' + instructor.lastName}</h5>
          <div className='col-4 text-right'>
            {instructor.curriculumModelId !== null && <Button
              color='link'
              style={
                {color: '#28a745'}
              }

              onClick={() => {
                getInstructorCurriculumAction(instructor.id);
              }}
            >
              <FontAwesomeIcon icon={faFileArchive}/>
              <span style={{marginLeft: '5px'}}>Download Curriculum</span>
            </Button>}
          </div>
          <Col xs="4">
            <Row>
              <Media src={avatar} alt=''/>

            </Row>
          </Col>
          <Col xs="4">
            {instructor.instructorExpertise.length > 0 && <div>Expertise:
              <div>
                {instructor.instructorExpertise.map((e, i) => {
                  return (
                    <div key={'expertise-instructor-' + i} style={{color: 'gray', fontWeight: 'normal'}}> {e.expertise.name}
                    </div>
                  );
                })}
              </div>
            </div>}

          </Col>
          <Col xs='4'>
            {instructor.instructorGradeLevels.length > 0 && <div>Grade Levels:
              <div>
                {instructor.instructorGradeLevels.map((e, i) => {
                  return (
                    <div key={'grade-instructor-' + i} style={{color: 'gray', fontWeight: 'normal'}}> {e.gradeLevels.name}
                    </div>
                  );
                })}
              </div>
            </div>}
          </Col>
        </Row>
        <Row>
          <div>Biography:<Label className='ml-2 mr-2' style={{textAlign: 'justify', color: 'gray', fontWeight: 'normal'}}>{instructor.biography}</Label></div>

        </Row>
      </div>
    </Collapse>
  );
};
const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    getInstructorCurriculumAction
  };
};
export default connect(undefined, mapDispatchProps)(InstructorView);