import React, { useEffect, useState }                      from 'react';
import { Button, CustomInput, FormGroup, Row, Table }      from 'reactstrap';
import { ColumnSort, MemberWaitingItems, MyPagination }    from '../index';
import { registerDispatch }                                from '../../actions/util';
import { connect }                                         from 'react-redux';
import { getMembersWaitingAction, getWaitingsExcelAction } from '../../actions/member';
import { FontAwesomeIcon }                                 from '@fortawesome/react-fontawesome';
import { faFileExcel }                                     from '@fortawesome/free-solid-svg-icons';

const MemberWaitingList = ({
                             className,
                             event,
                             waitingMembers,
                             getMembersWaitingAction,
                             getWaitingsExcelAction,
                             paginationMemberWaiting
                           }) => {

  const [showNoWaiting, setShowNoWaiting] = useState(false);
  const marginStyle = {
    marginLeft: '10px'
  };

  useEffect(() => {
    getMembersWaitingAction(event.id, 10, 0, true);
    return () => {
      sessionStorage.removeItem("showNoWaiting")
    }
    // eslint-disable-next-line
  }, [event]);

  let items = [];

  if (waitingMembers.length) {
    for (let i in waitingMembers) {
      items.push(
        <MemberWaitingItems
          key={'memberwaiting-item' + i}
          member={waitingMembers[i]}
          index={i}
        />
      );
    }
  }
  const linkStyle = {
    color: '#28a745'
  };
  return (
    <div className={className + ' mt-5'} style={marginStyle}>
      <div className='card'>
        <div className='col-12'>
          <Row>
            <h3 className='ml-1'>Waiting List</h3>
          </Row>
        </div>
        <Row>
          <div className='col-12'>
            <Button
              hidden={!waitingMembers.length > 0}
              color='link'
              style={linkStyle}
              onClick={() => {
                getWaitingsExcelAction(event.id);
              }}
            >
              <FontAwesomeIcon icon={faFileExcel}/>
              <span style={{marginLeft: '5px'}}>Export to Excel</span>
            </Button>
            <FormGroup className="ml-2">
              <CustomInput
                id='ShowNonWaiting'
                type='checkbox'
                label='Show non Waiting'
                inline
                onClick={() => {
                  setShowNoWaiting(!showNoWaiting);
                  if (!showNoWaiting) {
                    sessionStorage.setItem("showNoWaiting", true)
                  } else {
                    sessionStorage.removeItem("showNoWaiting")
                  }
                  getMembersWaitingAction(event.id, 10, 0, showNoWaiting);
                }}
              />
              <div
                className='float-right'
                style={{padding: '10px 12px 6px', height: '38px'}}
              >
                {paginationMemberWaiting.count > waitingMembers.length &&
                paginationMemberWaiting.skip + 1 + ' to '}
                {paginationMemberWaiting.skip + waitingMembers.length}
                {paginationMemberWaiting.count > waitingMembers.length &&
                ' of ' + paginationMemberWaiting.count}
                &nbsp;{waitingMembers.length === 1 ? 'record' : 'records'}
              </div>
            </FormGroup>
          </div>
        </Row>
        <Table className='col-12 mt-2' responsive>
          <thead className='card-header'>
          <tr>
            <th>
              Member
              <ColumnSort columnName="Member" columnSort={paginationMemberWaiting.columnSort} descending={paginationMemberWaiting.descending} changeSortAction={(object) => {
                getMembersWaitingAction(event.id, paginationMemberWaiting.limit, paginationMemberWaiting.skip, !showNoWaiting, object.columnSort, object.descending);
              }}/>
            </th>
            <th>
              Registration Date
              <ColumnSort columnName="ENTRYDATE" columnSort={paginationMemberWaiting.columnSort} descending={paginationMemberWaiting.descending} changeSortAction={(object) => {
                getMembersWaitingAction(event.id, paginationMemberWaiting.limit, paginationMemberWaiting.skip, !showNoWaiting, object.columnSort, object.descending);
              }}/>
            </th>
            <th>Priority Member</th>
            <th>Status</th>
          </tr>
          </thead>

          <tbody>{waitingMembers.length ? (
            items
          ) : (
            <tr>
              <th colSpan='6' className='text-center'>
                No content data...
              </th>
            </tr>
          )}</tbody>
        </Table>
        {waitingMembers.length > 0 && paginationMemberWaiting.limit < paginationMemberWaiting.count && (
          <MyPagination
            count={paginationMemberWaiting.count}
            limit={paginationMemberWaiting.limit}
            skip={paginationMemberWaiting.skip}
            action={skip => {
              getMembersWaitingAction(event.id, paginationMemberWaiting.limit, skip, !showNoWaiting);
            }}
          />
        )}
      </div>
    </div>
  );
};
const mapStateToProps = ({isFetching, event, getWaitingMember, paginationMemberWaiting}) => ({
  isFetching,
  event,
  waitingMembers: getWaitingMember,
  paginationMemberWaiting
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {getMembersWaitingAction, getWaitingsExcelAction};
};
export default connect(
  mapStateToProps,
  mapDispatchProps
)(MemberWaitingList);
