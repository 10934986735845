let actionTree = {};
actionTree['AddUser'] = ['GlobalUser', 'SuperUser'];
actionTree['UpdateUser'] = ['GlobalUser', 'SuperUser'];
actionTree['DeleteUser'] = ['GlobalUser', 'SuperUser'];


export const isAllow = (action, isGlobal) => {


  let roles = localStorage.getItem("rol");

  roles = roles.split(',');
  if (!roles) {
    return false;
  }
  for (let i in roles) {
    let a = actionTree[action];
    for (let j in a) {
      if (roles[i] === a[j]) {
        return (!(roles[i] === 'SuperUser' && isGlobal));
      }
    }
  }
  return false;
};