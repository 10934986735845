import React from 'react';
import {Button, FormGroup, Input, Label} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilePdf, faTimes}                                              from '@fortawesome/free-solid-svg-icons';
import { getDateValue1, registerDispatch }                 from '../../actions/util'
import {attendAction, getCertificationPDFAction, unRegisterMemberAction} from '../../actions/member'
import {connect} from 'react-redux';
import confirm from 'reactstrap-confirm';
import {ReadOnly, SuperUser} from "../index";


const MemberRegistrationItems = ({event, member, index, showUnregistered, paginationMemberRegistration, status, unRegisterMemberAction, getCertificationPDFAction, isActiveMarkAttendBtn, attendAction}) => {

  let nameButton = 'Unregistered';
  let textColor = '#6c757d';
  const linkStyle = {
    color: '#28a745',
    fontSize: '12px'
  };

  if (member.status === 2) {
    textColor = '#17a2b8';
    nameButton = 'Registered';
  } else if (member.status === 3) {
    textColor = '#17a2b8';
    nameButton = 'Moved';
  }

  const textStyle = {
    backgroundColor: textColor,
    color: 'white',
    fontWeight: 500,
    padding: '0 5px 2px 5px'
  };

  const getCategory = (name) => {
    if (name) {
      name = name.trim();
      for (let i in status) {
        if (status.hasOwnProperty(i) && status[i].member === name) {
          return status[i].category;
        }
      }
    }
    return "";
  };

  return (
    <tr>
      <td id={"member" + index} className="cursor-pointer">
        <ReadOnly>
          <span style={{color: 'gray', fontSize: '12px'}}>{member.event.title}</span> <br/>
          <span>{member.member.lastname + ', ' + member.member.frstname}</span><br/>
          <span style={{color: 'gray', fontSize: '12px'}}>{getCategory(member.member.status)}</span>
        </ReadOnly>
        <SuperUser>
          <br/>
          <Button
            color='link'
            style={linkStyle}
            onClick={() => {
              getCertificationPDFAction(member.id);
            }}
          >
            <FontAwesomeIcon color="#df0011" icon={faFilePdf}/>
            <span style={{marginLeft: '5px'}}>Preview Certificate</span>
          </Button>
        </SuperUser>
      </td>
      <td id={"member" + index} className="cursor-pointer">
        <ReadOnly>
          {getDateValue1(member.registrationdate)}
        </ReadOnly>
      </td>

      <td id={"member" + index} className="cursor-pointer">
        <ReadOnly>
          <FormGroup check>
            <Label check>
              <Input disabled={!isActiveMarkAttendBtn} type="checkbox" checked={member.attended} onChange={() => {
                attendAction(member.id, !member.attended, event.id, paginationMemberRegistration, showUnregistered)
              }}/>
            </Label>
          </FormGroup>
        </ReadOnly>
      </td>
      <SuperUser>
        <td>
          {event.iscancelled &&
          <span id={'tooltip-event-open' + index} style={textStyle}>{nameButton}</span>}
          {(!event.iscancelled && member.status === 2) ?
          <Button outline size='sm' color='primary' className="mr-2"
                  onClick={async () => {
                    const result = await confirm({
                      title: "Unregister",
                      message:
                        'Are you sure unregister member from this Event?',
                      confirmText: 'Yes',
                      cancelText: 'No'
                    });
                    if (result) {
                      unRegisterMemberAction(event.id, member.id, showUnregistered);
                    }
                  }}>
            <FontAwesomeIcon
              className='float-left mr-1'
              style={{
                color: 'primary',
                cursor: 'pointer',
                marginTop: '3px'
              }}
              icon={faTimes}
            /> Unregister</Button> : (!event.iscancelled && member.status === 1 && <span><span className="border-danger border text-danger px-1">Unregistered</span> <br/> on {getDateValue1(member.unregistrationdate)}</span>)}
        </td>
      </SuperUser>
    </tr>

  );
};
const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    unRegisterMemberAction,
    getCertificationPDFAction,
    attendAction

  }
};

export default connect(undefined, mapDispatchProps)(MemberRegistrationItems);
