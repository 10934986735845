import React from 'react';
import DatePicker from "react-datepicker";


const MyDatePicker = (props) => {

  let tmp = {
    ...props, onChange: (value, e) => {
      const inputValue = e.target.value;
      if (!inputValue || (inputValue && inputValue.replace(/\//g, "").length === 8)) {
        props.onChange(value, e)
      }
    },
    showMonthDropdown: true,
    showYearDropdown: true,
    dropdownMode: "select"
  };

  return (
    <DatePicker
      {...tmp}/>
  );
};

export default MyDatePicker;