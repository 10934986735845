import {client} from '../util/createRestApiClient';

const base = "Event/";
export default () => ({
  addEvent: (params) => client.request(
    {
      method: 'POST',
      url: base + 'AddEvent',
      data: params
    }
  ),
  getEvent: (params) => client.request(
    {
      method: 'GET',
      url: base + 'getEvent/' + params.id
    }
  ),
  getEvents: (limit = 10, skip = 0, filter = '', viewPast = false, year = 0, columnSort = '', descending = true) => client.request(
    {
      method: 'GET',
      url: base + 'GetEvents?limit=' + limit + '&skip=' + skip + '&filter=' + filter + '&viewPast=' + viewPast + '&year=' + year + '&columnSort=' + columnSort + '&descending=' + descending
    }
  ),
  getEventInstructors: (id) => client.request(
    {
      method: 'GET',
      url: base + 'GetInstructors/' + id
    }
  ),
  addEventInstructor: (id = 0, eliUserId = 0) => client.request(
    {
      method: 'POST',
      url: base + 'AddInstructor/' + id + "?eliUserId=" + eliUserId
    }
  ),
  deleteEventInstructor: (id = 0) => client.request(
    {
      method: 'DELETE',
      url: base + 'RemoveInstructor/' + id
    }
  ),
  updateEvent: (params, id) => client.request(
    {
      method: 'PUT',
      url: base + 'UpdateEvent/' + id,
      data: params
    }
  ),
  deleteEvent: (id) => client.request(
    {
      method: 'DELETE',
      url: base + 'DeleteEvent/' + id
    }
  ),
  cancelEvent: (id) => client.request(
    {
      method: 'POST',
      url: base + 'CancelEvent/' + id
    }
  ),
  openEvent: (id) => client.request(
    {
      method: 'POST',
      url: base + 'OpenEvent/' + id
    }
  ),
  getTitles: () => client.request(
    {
      method: 'GET',
      url: base + 'getTitles/'
    }
  ),
  getEventTitles: (id, limit, skip) => client.request(
    {
      method: 'GET',
      url: base + 'GetEventTitles/' + id + '?limit=' + limit + '&skip=' + skip
    }
  ),
  addEventTitle: (eventId, titleId) => client.request(
    {
      method: 'POST',
      url: base + 'AddEventTitle/' + eventId + "?titleId=" + titleId
    }
  ),
  deleteEventTitle: (id = 0) => client.request(
    {
      method: 'DELETE',
      url: base + 'RemoveEventTitle/' + id
    }
  ),
  getEventsExcel: (filter = '',) => client.request(
    {
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetEventsExcel?filter=' + filter
    }
  ),
  getEventGroups: (id, limit, skip) => client.request(
    {
      method: 'GET',
      url: base + 'GetEventGroups/' + id + '?limit=' + limit + '&skip=' + skip
    }
  ),
  addEventGroup: (eventId, groupId) => client.request(
    {
      method: 'POST',
      url: base + 'AddEventGroup/' + eventId + "?groupId=" + groupId
    }
  ),
  deleteEventGroup: (eventId, groupId) => client.request(
    {
      method: 'POST',
      url: base + 'RemoveEventGroup/' + eventId + "?groupId=" + groupId
    }
  ),
  addEventFileNumber: (eventId, memberId) => client.request(
    {
      method: 'POST',
      url: base + 'AddFileNumber/' + eventId + "?memberId=" + memberId
    }
  ),
  addEventFileNo: (eventId, fileno) => client.request(
    {
      method: 'POST',
      url: base + 'AddFileNumberByFileno/' + eventId + "?fileno=" + fileno
    }
  ),
  getEventFileNumbers: (id, limit, skip) => client.request(
    {
      method: 'GET',
      url: base + 'GetEventFileNumbers/' + id + '?limit=' + limit + '&skip=' + skip
    }
  ),
  deleteEventFileNumber: (eventFileNumberId) => client.request(
    {
      method: 'DELETE',
      url: base + 'RemoveFileNumber/' + eventFileNumberId
    }
  ),
  getFileNumbersExcel: (eventId) => client.request(
    {
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetEventFileNumbersExcel/' + eventId
    }
  ),
  importFileNumbersExcel: (eventId, formData) => client.request(
    {
      method: 'POST',
      url: base + 'AddFileNumberByExcel/' + eventId,
      data: formData

    }
  ), getEventsMemberView: (limit = 10, skip = 0, filter = '', columnSort = '', descending = true, futureEvents = true) => client.request(
    {
      method: 'GET',
      url: base + 'GetEventsByLoginMember?limit=' + limit + '&skip=' + skip + '&filter=' + filter + '&columnSort=' + columnSort + '&descending=' + descending + '&futureEvents=' + futureEvents

    }
  ),
  getEventsIcalFile: (eventIds) => client.request(
    {
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetEventsIcalFile?' + eventIds.map((value) => ("eventIds=" + value)).toString().replace(",", "&")

    }
  ),
  getEventsIcalFileAll: () => client.request(
    {
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetAllRegisteredEventsIcalFile?'
    }
  ),
  sendCustomNotification: (eventId, subject, body) => client.request(
    {
      method: 'POST',
      url: base + 'SendCustomNotification/' + eventId + "?Subject=" + subject + "&Body=" + body
    }
  ),
  getReportEventsExcel: (startDate = '', endDate = '') => client.request(
    {
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetReportEventsByDate/?startDate=' + startDate + '&endDate=' + endDate
    }
  ),
  getReportMembersExcel: (startDate = '', endDate = '') => client.request(
    {
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetReportEventsMembers?startDate=' + startDate + '&endDate=' + endDate
    }
  ),
  getReportInstructorsExcel: (startDate = '', endDate = '') => client.request(
    {
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetReportEventsByInstructor?startDate=' + startDate + '&endDate=' + endDate
    }
  ),
  sendReminder: (eventId) => client.request(
    {
      method: 'POST',
      url: base + 'SentEventReminders/' + eventId
    }
  ),
  getEventReminder: (id, limit, skip) => client.request(
    {
      method: 'GET',
      url: base + 'GetEventNotifications?eventId=' + id + '&skip=' + skip + '&limit=' + limit
    }
  )
});
