import * as types from "../types";
import {dispatch} from "./util";
import {groupService} from "../services";
import messages from "../util/messages";

export const addGroupAction = (name, description) => {
  dispatch(types.FETCHING);
  groupService().addGroup({Name: name, Description: description})
    .then(res => {
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_GROUP, {data: {name, description}});
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.ADD_GROUP_SUCCESS], color: 'info'});
      dispatch(types.TOGGLE_GROUP_FROM);
      dispatch(types.RESET_LOCATION);

    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_GROUP_FAILURE);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.ADD_GROUP_FAILURE], color: 'danger'});
    });
};
export const getGroupAction = (id, cb) => {
  dispatch(types.FETCHING);
  groupService().getGroup({id})
    .then(res => {
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_GROUP, res);
      if (cb) {
        cb(res.data);
      }
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_GROUP, {data: {}});
    });
};

let cancelGetGroupsAction = false;
export const cancelGetGroupsActionFn = (option = false) => {cancelGetGroupsAction = option};

export const getGroupsAction = (limit, skip, filter,columnSort,descending) => {
  dispatch(types.FETCHING);
  groupService().getGroups(limit, skip, filter,columnSort,descending)
    .then(res => {
      if (!cancelGetGroupsAction) {
        dispatch(types.GET_GROUP_SUCCESS, res);
        dispatch(types.FETCHING_END);
        dispatch(types.ADD_GROUP_PAGINATION, {data: {count: parseInt(res.headers['x-total-count'], 10), limit: parseInt(res.headers['x-total-limit'], 10), skip: parseInt(res.headers['x-total-skip'], 10), filter, columnSort, descending}});
      } else {
        cancelGetGroupsAction = false
      }
    })
    .catch((err) => {
      if (!cancelGetGroupsAction) {
        dispatch(types.FETCHING_END);
      } else {
        cancelGetGroupsAction = false
      }
      console.log(err)
    });
};

export const updateGroupAction = (group, listid) => {
  dispatch(types.FETCHING);
  groupService().updateGroup({name: group.name, description: group.description, memberIds: listid}, group.id)
    .then(res => {
      dispatch(types.UPDATE_GROUP_SUCCESS, {});
      dispatch(types.FETCHING_END);
      dispatch(types.TOGGLE_GROUP_FROM);
      dispatch(types.RESET_GROUP);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.UPDATE_GROUP_SUCCESS], color: 'info'});
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.UPDATE_GROUP_FAILURE], color: 'danger'});
    });
};

export const deleteGroupAction = (id) => {
  dispatch(types.FETCHING);
  groupService().deleteGroup(id)
    .then(res => {
      dispatch(types.FETCHING_END);
      getGroupsAction(10, 0)
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.DELETE_GROUP_SUCCESS], color: 'info'});
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.DELETE_GROUP_FAILURE], color: 'danger'});
    });
};

export const getMembersGroupAction = (id) => {
  dispatch(types.FETCHING);
  groupService.getMembersGroup(id)
    .then(res => {
      dispatch(types.GET_MEMBER_GROUP_SUCCESS, res);
      dispatch(types.FETCHING_END);
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      console.log(err)
    });
};

export const addMemberGroupAction = (id, rol, cb) => {
  dispatch(types.FETCHING);
  groupService().addMemberGroup(id, rol)
    .then(res => {
      dispatch(types.FETCHING_END);
      getGroupsAction();
      getMembersGroupAction(id);
      cb();
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.ADD_GROUPEVENT_SUCCESS], color: 'info'});
    })
    .catch((err) => {
      console.log(err)
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_GROUPEVENT_FAILURE);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.ADD_GROUPEVENT_FAILURE], color: 'danger'});
    });
};

export const deleteMemberGroupAction = (groupid, member) => {
  dispatch(types.FETCHING);
  groupService().deleteMemberGroup(groupid, member)
    .then(res => {
      dispatch(types.FETCHING_END);
      getMembersGroupAction(groupid);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.DELETE_GROUPEVENT_SUCCESS], color: 'info'});
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.DELETE_GROUPEVENT_FAILURE], color: 'danger'});
    });
};

export const importMembersExcelAction = (groupId, excel) => {
  dispatch(types.FETCHING);
  const formData = new FormData();
  formData.append('fileNumbers', excel);

  let message = "Import Member";
  groupService().importMembersExcel(groupId, formData)
    .then(res => {
      dispatch(types.FETCHING_END);
      getGroupAction(groupId);
      if (res.data && typeof res.data === "string") {
        message = res.data
      } else if (res.data && typeof res.data.message === "string" && res.data.message) {
        message = res.data.message
      }
      dispatch(types.ADD_ALERT_NOTIFICATION, {
        message: message,
        color: 'info'
      });
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      if (err.response && err.response.data) {
        message = err.response.data
      }
      dispatch(types.ADD_ALERT_NOTIFICATION, {
        message: message,
        color: 'danger'
      });

    });
};