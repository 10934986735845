import React, {useEffect, useState} from 'react';
import {Button, FormGroup, InputGroup, InputGroupAddon, Row, Table} from 'reactstrap';
import {ColumnSort, LocationItems, MyPagination, SuperUser} from '../index';
import {myDispatch, registerDispatch} from '../../actions/util';
import {connect} from 'react-redux';
import {getLocationsAction, getLocationsExcelAction} from '../../actions/location';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileExcel} from '@fortawesome/free-solid-svg-icons';
import * as types from "../../types";


const LocationList = ({
                        isFetching,
                        locations,
                        getLocationsAction,
                        paginationLocation,
                        getLocationsExcelAction,
                        changeSortAction,
                        setIsActive
                      }) => {
  const filterStyle = {
    border: '1px dashed #e9e9e9',
    borderRadius: '6px',
    padding: '.75rem .75rem 0',
    marginBottom: '5px',
    backgroundColor: '#fafafa'
  };
  const linkStyle = {
    color: '#28a745'
  };
  useEffect(() => {
    getLocationsAction(10, 0);
    // eslint-disable-next-line
  }, [getLocationsAction]);

  const [filter, setFilter] = useState('');

  let items = [];

  if (locations.length) {
    for (let i in locations) {
      items.push(
        <LocationItems
          key={'location-item' + i}
          location={locations[i]}
          index={i}
          setIsActive={setIsActive}
        />
      );
    }
  }

  return (
    <div>
      <div style={filterStyle}>
        <Row style={{alignItems: 'center'}}>
          <FormGroup className='col-12 col-md-8'>
            <InputGroup>
              <input
                type='text'
                value={filter}
                name='filterName'
                className='form-control'
                placeholder='Type the Name or Address'
                onChange={e => {
                  setFilter(e.target.value);
                }}
                onKeyUp={e => {
                  if (e.keyCode === 27) {
                    setFilter('');
                    getLocationsAction();
                  }
                  if (e.keyCode === 13) {
                    setFilter(e.target.value);
                    getLocationsAction(
                      undefined,
                      undefined,
                      e.target.value
                    );
                  }
                }}
              />
              <InputGroupAddon addonType='append'>
                <Button
                  outline
                  color='primary'
                  onClick={() => {
                    getLocationsAction(
                      undefined,
                      undefined,
                      filter
                    );
                  }}
                >
                  Search
                </Button>
                <Button
                  outline
                  color='primary'
                  onClick={() => {
                    setFilter('');
                    getLocationsAction();
                  }}
                >
                  Reset
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        </Row>
      </div>
      <div>
        <div>
          <Button
            hidden={!locations.length > 0}
            color='link' style={linkStyle}
            onClick={() => {
              getLocationsExcelAction(filter);
            }}

          >
            <FontAwesomeIcon icon={faFileExcel}/>
            <span style={{marginLeft: '5px'}}>Export to Excel</span>
          </Button>
          <div
            className='float-right'
            style={{padding: '10px 12px 6px', height: '38px'}}
          >
            {paginationLocation.count > locations.length &&
            paginationLocation.skip + 1 + ' to '}
            {paginationLocation.skip + locations.length}
            {paginationLocation.count > locations.length &&
            ' of ' + paginationLocation.count}
            &nbsp;{locations.length === 1 ? 'record' : 'records'}
          </div>
        </div>
        <Table responsive className='col-12'>
          <thead>
          <tr className='card-header'>
            <th>Name<ColumnSort columnName="name" columnSort={paginationLocation.columnSort} descending={paginationLocation.descending} changeSortAction={(object) => {
              changeSortAction({...paginationLocation, ...object});
              getLocationsAction(paginationLocation.limit, paginationLocation.skip, filter, object.columnSort, object.descending)
            }}/></th>
            <th>Address<ColumnSort columnName="address" columnSort={paginationLocation.columnSort} descending={paginationLocation.descending} changeSortAction={(object) => {
              changeSortAction({...paginationLocation, ...object});
              getLocationsAction(paginationLocation.limit, paginationLocation.skip, filter, object.columnSort, object.descending)
            }}/></th>
            <SuperUser><th/></SuperUser>
          </tr>
          </thead>
          <tbody>
          {locations.length ? (
            items
          ) : (
            <tr>
              <th colSpan='6' className='text-center'>
                No content data...
              </th>
            </tr>
          )}
          </tbody>
        </Table>
        {locations.length > 0 &&
        paginationLocation.limit < paginationLocation.count && (
          <MyPagination
            count={paginationLocation.count}
            limit={paginationLocation.limit}
            skip={paginationLocation.skip}
            action={skip => {
              getLocationsAction(paginationLocation.limit, skip, filter, paginationLocation.columnSort, paginationLocation.descending);
            }}
          />
        )}

      </div>
    </div>
  );
};

const mapStateToProps = ({isFetching, getLocations, paginationLocation}) => ({
  isFetching,
  locations: getLocations,
  paginationLocation
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    getLocationsAction,
    getLocationsExcelAction,
    changeSortAction: (data) => {
      dispatch(myDispatch(types.ADD_LOCATION_PAGINATION, {data}));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(LocationList);
