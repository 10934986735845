import {client} from '../util/createRestApiClient';

const base = "Member/";
export default () => ({
    getMember: (params) => client.request({
      method: 'GET',
      url: base + 'getMember/' + params.id
    }),
    getMembers: (limit = 10, skip = 0, filter = '', columnSort = '', descending = true) => client.request({
      method: 'GET',
      url: base + 'GetMembers?limit=' + limit + '&skip=' + skip + '&filterName=' + filter + '&columnSort=' + columnSort + '&descending=' + descending
    }),

  }
);
