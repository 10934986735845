import React, {useState} from 'react';
import {Button, Label, Row} from 'reactstrap';
import {registerDispatch} from '../../actions/util'
import {connect} from 'react-redux';
import {MyDatePicker} from '../index';

import {getReportEventsExcelAction, getReportInstructorsExcelAction, getReportMembersExcelAction} from '../../actions/event';


const ReportView = ({getReportEventsExcelAction, getReportMembersExcelAction, getReportInstructorsExcelAction}) => {
  const filterStyle = {
    border: '1px dashed #e9e9e9',
    borderRadius: '6px',
    padding: '.75rem .75rem 0',
    marginBottom: '5px',
    backgroundColor: '#fafafa'
  };
  const sectionStyle = {
    border: '1px solid #e9e9e9',
    borderRadius: '6px',
    padding: '.75rem .75rem',
    marginBottom: '5px'
  };

  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  return (
    <div>
      <Row>
        <h3 className='col-6'>Download Reports</h3>
        <div className='col-6 float-right' style={sectionStyle}>
          <Row style={{paddingBottom: "10px"}}>
            <div className="col-12 col-sm-6">
              <Label>From</Label>
              <MyDatePicker
                dateFormat='MM/dd/yyyy'
                selected={from}
                maxDate={to}
                onChange={value => {
                  setFrom(value);
                  //  if(value>to){
                  //  setTo(value)}
                }}
              />
            </div>
            <div className="col-12 col-sm-6">
              <Label>to</Label>
              <MyDatePicker
                dateFormat='MM/dd/yyyy'
                showDisabledMonthNavigation
                minDate={from}
                selected={to}
                onChange={value => {
                  setTo(value);
                }}
              />
            </div>
          </Row>
        </div>
      </Row>
      <div className='col-4' style={filterStyle}>
        <h5>Registration</h5>
        <Button color='primary'
                outline
                className='col-12 mt-2'
                onClick={() => {
                  getReportMembersExcelAction(from ? from.toUTCString() : '', to ? to.toUTCString() : '')
                }}>Members Registered </Button>
        <Button color='primary'
                outline
                className='col-12 mt-2'
                onClick={() => {
                  getReportEventsExcelAction(from ? from.toUTCString() : '', to ? to.toUTCString() : '');
                }}>Events </Button>
        <Button color='primary'
                outline
                className='col-12 mt-2 mb-2'
                onClick={() => {
                  getReportInstructorsExcelAction(from ? from.toUTCString() : '', to ? to.toUTCString() : '')
                }}>Instructor</Button>
      </div>
    </div>

  );
};

const mapStateToProps = ({isFetching}) => ({
  isFetching

});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    getReportEventsExcelAction,
    getReportMembersExcelAction,
    getReportInstructorsExcelAction
  }
};

export default connect(mapStateToProps, mapDispatchProps)(ReportView);