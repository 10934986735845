import * as types from "../types";

let messages = {};
//login
messages[types.LOGIN_FAILURE] = "Login";
messages[types.CONNECTION_ERROR] = "Connection error, contact with Admin";

//Workshop
messages[types.ADD_WORKSHOP_SUCCESS] = "Save new Workshop";
messages[types.ADD_WORKSHOP_FAILURE] = "Save new Workshop";
messages[types.UPDATE_WORKSHOP_SUCCESS] = "Workshop Update";
messages[types.UPDATE_WORKSHOP_FAILURE] = "Workshop Update";
messages[types.DELETE_WORKSHOP_SUCCESS] = "Workshop Delete";
messages[types.DELETE_WORKSHOP_FAILURE] = "Workshop Delete";
messages[types.CLONE_WORKSHOP_SUCCESS] = "Workshop Clone";
messages[types.CLONE_WORKSHOP_FAILURE] = "Workshop Clone";

//Goup
messages[types.ADD_GROUP_SUCCESS] = "Save new Group";
messages[types.ADD_GROUP_FAILURE] = "Save new Group";
messages[types.UPDATE_GROUP_SUCCESS] = "Group Update";
messages[types.UPDATE_GROUP_FAILURE] = "Group Update";
messages[types.DELETE_GROUP_SUCCESS] = "Group Delete";
messages[types.DELETE_GROUP_FAILURE] = "Group Delete";
messages[types.ADD_GROUPEVENT_FAILURE] = "Save Group Event";
messages[types.ADD_GROUPEVENT_SUCCESS] = "Save Group Event";
messages[types.DELETE_GROUPEVENT_FAILURE] = "Delete Group Event";
messages[types.DELETE_GROUPEVENT_SUCCESS] = "Delete Group Event";

//Location
messages[types.ADD_LOCATION_SUCCESS] = "Save new Location";
messages[types.ADD_LOCATION_FAILURE] = "Save new Location";
messages[types.UPDATE_LOCATION_SUCCESS] = "Location Update";
messages[types.UPDATE_LOCATION_FAILURE] = "Location Update";
messages[types.DELETE_LOCATION_SUCCESS] = "Location Delete";
messages[types.DELETE_LOCATION_FAILURE] = "Location Delete";

//Member
messages[types.ADD_MEMBER_REGISTRATION_SUCCESS] = "Save member registration";
messages[types.ADD_MEMBER_REGISTRATION_FAILURE] = "Save member registration";

// User
messages[types.ADD_USER_SUCCESS] = "Save new User";
messages[types.ADD_USER_FAILURE] = "Save new User";
messages[types.UPDATE_USER_SUCCESS] = "User Update";
messages[types.UPDATE_USER_FAILURE] = "User Update";
messages[types.DELETE_USER_SUCCESS] = "User Delete";
messages[types.DELETE_USER_FAILURE] = "User Delete";

// Instructor
messages[types.ADD_INSTRUCTOR_SUCCESS] = "Save new Instructor";
messages[types.ADD_INSTRUCTOR_FAILURE] = "Save new Instructor";
messages[types.UPDATE_INSTRUCTOR_SUCCESS] = "Instructor Update";
messages[types.UPDATE_INSTRUCTOR_FAILURE] = "Instructor Update";
messages[types.DELETE_INSTRUCTOR_SUCCESS] = "Instructor Delete";
messages[types.DELETE_INSTRUCTOR_FAILURE] = "Instructor Delete";

// Titles
messages[types.ADD_TITLE_SUCCESS] = "Save Title";
messages[types.ADD_TITLE_FAILURE] = "Save Title";
messages[types.DELETE_TITLE_SUCCESS] = "Title Delete";
messages[types.DELETE_TITLE_FAILURE] = "Title Delete";

export default messages;