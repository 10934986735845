import * as types from "../types";
import {dispatch, getTodayStringValue} from "./util";
import {eventService} from "../services";
import {saveAs} from "file-saver";


export const addEventAction = (name, title, capacity, credithours, reminderpriordays, startdate, enddate, startshowingdate, endshowingdate, nameincertificate, starttime, endtime, iscancelled, isforalltitles, certificatessent, isforfileno, isfortitles, isforgroup, workshopmodelid, usermodelid, eventLocationModelId) => {
  dispatch(types.FETCHING);
  eventService().addEvent({
    name: name,
    title: title,
    capacity: capacity,
    credithours: credithours,
    reminderpriordays: reminderpriordays,
    startdate: startdate,
    enddate: enddate,
    startshowingdate: startshowingdate,
    endshowingdate: endshowingdate,
    nameincertificate: nameincertificate,
    starttime: starttime,
    endtime: endtime,
    iscancelled: iscancelled,
    isclosed: false,
    isforalltitles: isforalltitles,
    certificatessent: certificatessent,
    isforfileno: isforfileno,
    isfortitle: isfortitles,
    isforgroup: isforgroup,
    userModelId: usermodelid,
    workshopModelId: workshopmodelid,
    eventLocationModelId: eventLocationModelId
  }).then(res => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_EVENT_SUCCESS, {
      data: {
        name,
        title,
        capacity,
        credithours,
        reminderpriordays,
        startdate,
        enddate,
        startshowingdate,
        endshowingdate,
        nameincertificate,
        starttime,
        endtime,
        iscancelled,
        isclosed: false,
        isforalltitles,
        certificatessent,
        isforfileno,
        isfortitles,
        isforgroup,
        workshopmodelid,
        usermodelid,
        eventLocationModelId
      }
    });
    dispatch(types.LOGIN_SUCCESS);

    dispatch(types.TOGGLE_EVENT_FROM);
    dispatch(types.RESET_EVENT);
    // dispatch(types.UPDATE_EVENT);

  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_EVENT_FAILURE);
  });
};
export const getEventAction = (id) => {
  dispatch(types.FETCHING);
  eventService().getEvent({id}).then(res => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_EVENT, res);
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_EVENT, {data: {}});
  });
};

let cancelGetEventsAction = false;
export const cancelGetEventsActionFn = (option = false) => {
  cancelGetEventsAction = option
};

export const getEventsAction = (limit, skip, filter, viewPast, selectYear, columnSort, descending) => {
  dispatch(types.FETCHING);
  eventService().getEvents(limit, skip, filter, viewPast, selectYear && selectYear.value ? selectYear.value : 0, columnSort, descending).then(res => {
    if (res && res.data) {
      if (!cancelGetEventsAction) {
        dispatch(types.GET_EVENT_SUCCESS, res);
        dispatch(types.ADD_EVENT_PAGINATION, {
          data: {
            count: parseInt(res.headers['x-total-count'], 10),
            limit: parseInt(res.headers['x-total-limit'], 10),
            skip: parseInt(res.headers['x-total-skip'], 10),
            filter, columnSort, descending, viewPast, selectYear
          }
        });
      } else {
        cancelGetEventsAction = false;
      }
      dispatch(types.FETCHING_END);
    }
  }).catch((err) => {
    if (!cancelGetEventsAction) {
      dispatch(types.FETCHING_END);
    } else {
      cancelGetEventsAction = false;
    }
    console.log(err)
  });
};

export const updateEventAction = (event, cb) => {
  dispatch(types.FETCHING);
  eventService().updateEvent({
    NAME: event.name,
    TITLE: event.title,
    wP_Id: event.wP_Id,
    CAPACITY: event.capacity,
    CREDITHOURS: event.credithours,
    REMINDERPRIORDAYS: event.reminderpriordays,
    STARTDATE: event.startdate,
    ENDDATE: event.enddate,
    ISCANCELLED: event.iscancelled,
    ISCLOSED: event.isclosed,
    ISFORALLTITLES: event.isforalltitles,
    CERTIFICATESSENT: event.certificatessent,
    STARTSHOWINGDATE: event.startshowingdate,
    ENDSHOWINGDATE: event.endshowingdate,
    NAMEINCERTIFICATE: event.nameincertificate,
    STARTTIME: event.starttime,
    ENDTIME: event.endtime,
    ISFORFILENO: event.isforfileno,
    ISFORGROUP: event.isforgroup,
    ISFORTITLE: event.isfortitle,
    WorkshopModelId: event.workshopModelId,
    UserModelId: event.userModelId,
    eventLocationModelId: event.eventLocationModelId,
    titles: event.titles
  }, event.id).then(res => {
    dispatch(types.FETCHING_END);
    if (cb) {
      cb();
    } else {
      dispatch(types.UPDATE_EVENT_SUCCESS, {});
      dispatch(types.TOGGLE_EVENT_FROM);
      dispatch(types.RESET_EVENT);
    }
    // dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.UPDATE_SITE_SUCCESS], color: 'info'});
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    // dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.UPDATE_SITE_FAILURE], color: 'danger'});
  });
};

export const cloneEventAction = (name, title, capacity, credithours, reminderpriordays, startdate, enddate, startshowingdate, endshowingdate, nameincertificate, starttime, endtime, iscancelled, isforalltitles, certificatessent, isforfileno, isfortitles, isforgroup, workshopmodelid, usermodelid, eventLocationModelId) => {
  dispatch(types.FETCHING);
  eventService().addEvent({
    NAME: name,
    TITLE: title,
    CAPACITY: capacity,
    CREDITHOURS: credithours,
    REMINDERPRIORDAYS: reminderpriordays,
    STARTDATE: startdate,
    ENDDATE: enddate,
    STARTSHOWINGDATE: startshowingdate,
    ENDSHOWINGDATE: endshowingdate,
    NAMEINCERTIFICATE: nameincertificate,
    STARTTIME: starttime,
    ENDTIME: endtime,
    ISCANCELLED: iscancelled,
    ISCLOSED: false,
    ISFORALLTITLES: isforalltitles,
    CERTIFICATESSENT: certificatessent,
    ISFORFILENO: isforfileno,
    ISFORTITLE: isfortitles,
    ISFORGROUP: isforgroup,
    UserModelId: usermodelid,
    WorkshopModelId: workshopmodelid,
    eventLocationModelId: eventLocationModelId
  }).then(res => {
    dispatch(types.CLONE_EVENT_SUCCESS, {});
    dispatch(types.FETCHING_END);

    dispatch(types.TOGGLE_EVENT_FROM);
    dispatch(types.RESET_EVENT);
    // dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.UPDATE_EVENT_SUCCESS], color: 'info'});
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    // dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.UPDATE_SITE_FAILURE], color: 'danger'});
  });
};

export const deleteEventAction = (id) => {
  dispatch(types.FETCHING);
  eventService().deleteEvent(id).then(res => {
    dispatch(types.FETCHING_END);
    getEventsAction(10, 0)
    // dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.DELETE_SITE_SUCCESS], color: 'info'});
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    // dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.DELETE_SITE_FAILURE], color: 'danger'});
  });
};

export const cancelEventAction = (id, paginationEvent) => {
  dispatch(types.FETCHING);
  eventService().cancelEvent(id).then(res => {
    dispatch(types.FETCHING_END);
    const {limit, skip, filter, viewPast, selectYear, columnSort, descending} = paginationEvent;
    getEventsAction(limit, skip, filter, viewPast, selectYear, columnSort, descending)
    // dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.DELETE_SITE_SUCCESS], color: 'info'});
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    // dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.DELETE_SITE_FAILURE], color: 'danger'});
  });
};

export const openEventAction = (id, paginationEvent) => {
  dispatch(types.FETCHING);
  eventService().openEvent(id).then(res => {
    dispatch(types.FETCHING_END);
    const {limit, skip, filter, viewPast, selectYear, columnSort, descending} = paginationEvent;
    getEventsAction(limit, skip, filter, viewPast, selectYear, columnSort, descending)
    // dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.DELETE_SITE_SUCCESS], color: 'info'});
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    // dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.DELETE_SITE_FAILURE], color: 'danger'});
  });
};

// export const getInstructorsAction = () => {
//   dispatch(types.FETCHING);
//   usermanagerService().getInstructors().then(res => {
//     dispatch(types.GET_INSTRUCTOR_SUCCESS, res);
//     dispatch(types.FETCHING_END);
//   }).catch((err) => {
//     dispatch(types.FETCHING_END);
//     console.log(err)
//   });
// };

export const getEventInstructorsAction = (id) => {
  dispatch(types.FETCHING);
  eventService().getEventInstructors(id).then(res => {
    dispatch(types.GET_EVENT_INSTRUCTOR_SUCCESS, res);
    dispatch(types.FETCHING_END);
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    console.log(err)
  });
};

export const addEventInstructorAction = (id, userId, cb) => {
  dispatch(types.FETCHING);
  eventService().addEventInstructor(id, userId).then(res => {
    dispatch(types.FETCHING_END);
    getEventInstructorsAction(id);
    cb();
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: "Add Instructor ",
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    console.log(err)
  });
};

export const deleteEventInstructorAction = (id, eventId) => {
  dispatch(types.FETCHING);
  eventService().deleteEventInstructor(id).then(res => {
    dispatch(types.FETCHING_END);
    getEventInstructorsAction(eventId);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: "Delete Instructor",
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    console.log(err)
  });
};


export const getTitlesAction = () => {
  dispatch(types.FETCHING);
  eventService.getTitles().then(res => {
    dispatch(types.GET_TITLES_SUCCESS, res);
    dispatch(types.FETCHING_END);
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    console.log(err)
  });
};

export const getEventTitlesAction = (id, limit, skip) => {
  dispatch(types.FETCHING);
  eventService().getEventTitles(id, limit, skip).then(res => {
    dispatch(types.GET_EVENT_TITLES_SUCCESS, res);
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_EVENTTITLE_PAGINATION, {
      data: {
        count: parseInt(res.headers['x-total-count'], 10),
        limit: parseInt(res.headers['x-total-limit'], 10),
        skip: parseInt(res.headers['x-total-skip'], 10)
      }
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    console.log(err)
  });
};

export const addEventTitleAction = (eventId, titleId, paginationEventTitle, cb) => {
  dispatch(types.FETCHING);
  eventService().addEventTitle(eventId, titleId).then(res => {
    dispatch(types.FETCHING_END);
    getEventTitlesAction(eventId, paginationEventTitle.limit, paginationEventTitle.skip);
    cb();
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: "Add Title ",
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    console.log(err)
  });
};

export const deleteEventTitleAction = (id, eventId) => {
  dispatch(types.FETCHING);
  eventService().deleteEventTitle(id).then(res => {
    dispatch(types.FETCHING_END);
    getEventTitlesAction(eventId, 5, 0);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: "Delete Title ",
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    console.log(err)
  });
};
export const getEventsExcelAction = (filter) => {
  dispatch(types.FETCHING);
  eventService().getEventsExcel(filter).then(res => {
    dispatch(types.FETCHING_END);
    saveAs(res.data, "Events" + getTodayStringValue() + ".xlsx");
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: "Download export event to excel ",
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
  });
};
export const getReportEventsExcelAction = (from = '', to = '') => {
  dispatch(types.FETCHING);
  eventService().getReportEventsExcel(from, to).then(res => {
    dispatch(types.FETCHING_END);
    saveAs(res.data, "ReportEventsByDate" + getTodayStringValue() + ".xlsx");
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: "Download Report Events By Date to Excel ",
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
  });
};
export const getReportMembersExcelAction = (from = '', to = '') => {
  dispatch(types.FETCHING);
  eventService().getReportMembersExcel(from, to).then(res => {
    dispatch(types.FETCHING_END);
    saveAs(res.data, "ReportMembersAtEvent" + getTodayStringValue() + ".xlsx");
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: "Download Report Member At Events to Excel ",
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
  });
};

export const getReportInstructorsExcelAction = (from = '', to = '') => {
  dispatch(types.FETCHING);
  eventService().getReportInstructorsExcel(from, to).then(res => {
    dispatch(types.FETCHING_END);
    saveAs(res.data, "ReportEventsByInstructor" + getTodayStringValue() + ".xlsx");
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: "Download Report Events By Instructor to Excel ",
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
  });
};

export const addEventGroupAction = (eventId, groupId, paginationEventGroup, cb) => {
  dispatch(types.FETCHING);
  eventService().addEventGroup(eventId, groupId).then(res => {
    dispatch(types.FETCHING_END);
    getEventGroupsAction(eventId, paginationEventGroup.limit, paginationEventGroup.skip);
    cb();
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: "Add Group Event",
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    console.log(err)
  });
};
export const getEventGroupsAction = (id, limit, skip) => {
  dispatch(types.FETCHING);
  eventService().getEventGroups(id, limit, skip).then(res => {
    dispatch(types.GET_EVENT_GROUP_SUCCESS, res);
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_EVENTGROUP_PAGINATION, {
      data: {
        count: parseInt(res.headers['x-total-count'], 10),
        limit: parseInt(res.headers['x-total-limit'], 10),
        skip: parseInt(res.headers['x-total-skip'], 10)
      }
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    console.log(err)
  });
};

export const deleteEventGroupAction = (eventId, groupId) => {
  dispatch(types.FETCHING);
  eventService().deleteEventGroup(eventId, groupId).then(res => {
    dispatch(types.FETCHING_END);
    getEventGroupsAction(eventId, 5, 0);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: "Delete Group Event",
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    console.log(err)
  });
};

export const getEventFileNumbersAction = (id, limit, skip) => {
  dispatch(types.FETCHING);
  eventService().getEventFileNumbers(id, limit, skip).then(res => {
    dispatch(types.GET_EVENT_FILENUMBER_SUCCESS, res);
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_EVENTFILENUMBER_PAGINATION, {
      data: {
        count: parseInt(res.headers['x-total-count'], 10),
        limit: parseInt(res.headers['x-total-limit'], 10),
        skip: parseInt(res.headers['x-total-skip'], 10)
      }
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.GET_EVENT_FILENUMBER_FAILURE);
    console.log(err)
  });
};
export const addEventFileNumberAction = (eventId, memberId, paginationEventFileNumber) => {
  dispatch(types.FETCHING);
  eventService().addEventFileNumber(eventId, memberId).then(res => {
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: "Add File Number",
      color: 'info'
    });
    dispatch(types.FETCHING_END);
    getEventFileNumbersAction(eventId, paginationEventFileNumber.limit, paginationEventFileNumber.skip);
  }).catch((err) => {
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: "Please select a Member",
      color: 'danger'
    });
    dispatch(types.FETCHING_END);
    console.log(err)
  });
};
export const addEventFileNoAction = (eventId, fileno, paginationEventFileNumber) => {
  dispatch(types.FETCHING);
  eventService().addEventFileNo(eventId, fileno).then(res => {
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: "Add File Number",
      color: 'info'
    });
    dispatch(types.FETCHING_END);
    getEventFileNumbersAction(eventId, paginationEventFileNumber.limit, paginationEventFileNumber.skip);
  }).catch((err) => {
    console.log(err.response)
    if (err.response.status === 400) {
      dispatch(types.ADD_ALERT_NOTIFICATION, {
        message: "Invalid file Number",
        color: 'danger'
      });
    }
    dispatch(types.FETCHING_END);
    console.log(err)
  });
};
export const deleteEventFileNumberAction = (eventId, eventFileNumberId) => {
  dispatch(types.FETCHING);
  eventService().deleteEventFileNumber(eventFileNumberId).then(res => {
    dispatch(types.FETCHING_END);
    getEventFileNumbersAction(eventId, 5, 0);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: "Delete File Number",
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: "Delete File Number",
      color: 'danger'
    });
    console.log(err)
  });
};

export const getFileNumberExcelAction = (eventId) => {
  dispatch(types.FETCHING);
  eventService().getFileNumbersExcel(eventId).then(res => {
    dispatch(types.FETCHING_END);
    saveAs(res.data, "FileNumber" + getTodayStringValue() + ".xlsx");
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: "Export File Number",
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: "Export File Number",
      color: 'danger'
    });
  });
};
export const importFileNumbersExcelAction = (eventId, excel) => {
  dispatch(types.FETCHING);
  const formData = new FormData();
  formData.append('fileNumbers', excel)
  eventService().importFileNumbersExcel(eventId, formData)
    .then(res => {
      dispatch(types.FETCHING_END);
      getEventFileNumbersAction(eventId, 5);
      dispatch(types.ADD_ALERT_NOTIFICATION, {
        message: "Import File Number",
        color: 'info'
      });
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_ALERT_NOTIFICATION, {
        message: "Import File Number",
        color: 'danger'
      });
      //  dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.ADD_WORKSHOP_FAILURE], color: 'danger'});
    });
};

export const getEventsMemberViewAction = (limit, skip, filter = '', columnSort = '', descending = true, futureEvents = true) => {
  dispatch(types.FETCHING);
  eventService().getEventsMemberView(limit, skip, filter, columnSort, descending, futureEvents).then(res => {
    dispatch(types.GET_EVENTMEMBERVIEW_SUCCESS, res);
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_EVENTMEMBERVIEW_PAGINATION, {
      data: {
        count: parseInt(res.headers['x-total-count'], 10),
        registered: parseInt(res.headers['x-total-count-registered'], 10),
        limit: parseInt(res.headers['x-total-limit'], 10),
        skip: parseInt(res.headers['x-total-skip'], 10), filter, columnSort, descending, futureEvents
      }
    });

  }).catch((err) => {
    dispatch(types.FETCHING_END);
    console.log(err)
  });
};
export const sendReminderAction = (eventId) => {
  dispatch(types.FETCHING);
  eventService().sendReminder(eventId).then(res => {
    dispatch(types.FETCHING_END);
    getEventReminderAction(eventId, 10, 0)
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: "Send Reminder",
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: "Send Reminder",
      color: 'danger'
    });
    console.log(err)
  });
};

export const getEventReminderAction = (id, limit, skip) => {
  dispatch(types.FETCHING);
  eventService().getEventReminder(id, limit, skip).then(res => {
    dispatch(types.GET_EVENT_REMINDER_SUCCESS, res);
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_EVENTREMINDER_PAGINATION, {
      data: {
        count: parseInt(res.headers['x-total-count'], 10),
        limit: parseInt(res.headers['x-total-limit'], 10),
        skip: parseInt(res.headers['x-total-skip'], 10)
      }
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    console.log(err)
  });
};