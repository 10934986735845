import React, {useEffect, useState} from 'react';
import {Button, CustomInput, FormGroup, InputGroup, InputGroupAddon, Row, Table} from 'reactstrap';
import {ColumnSort, EventsItems, EventsView, MyPagination} from '../index';
import {myDispatch, registerDispatch} from '../../actions/util';
import {connect} from 'react-redux';
import {getEventsAction, getEventsExcelAction} from '../../actions/event';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileExcel} from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import * as types from "../../types";

const EventsList = ({
                      events,
                      getEventsAction,
                      paginationEvent,
                      changeSortAction,
                      setIsActive
                    }) => {
  const filterStyle = {
    border: '1px dashed #e9e9e9',
    borderRadius: '6px',
    padding: '.75rem .75rem 0',
    marginBottom: '5px',
    backgroundColor: '#fafafa'
  };
  const linkStyle = {
    color: '#28a745'
  };

  useEffect(() => {
    getEventsAction(
      paginationEvent.limit,
      paginationEvent.skip,
      paginationEvent.filter,
      paginationEvent.viewPast,
      paginationEvent.selectYear,
      paginationEvent.columnSort,
      paginationEvent.descending);
    // eslint-disable-next-line
  }, [getEventsAction]);

  const [filter, setFilter] = useState(paginationEvent.filter);
  const [viewPast, setViewPast] = useState(paginationEvent.viewPast);
  const [selectYear, setSelectYear] = useState(paginationEvent.selectYear);

  let items = [];
  if (events.length) {
    for (let i in events) {
      items.push(
        <EventsItems
          key={'event-item' + i}
          event={events[i]}
          index={i}
          setIsActive={setIsActive}
          paginationEvent={paginationEvent}
        />
      );
      items.push(<tr key={"event-view" + i}>
        <th colSpan="7" style={{padding: "0 12px"}}><EventsView index={i} event={events[i]}/></th>
      </tr>);
    }
  }

  const customStyles = {
    control: base => ({
      ...base,
      height: 30,
      width: 120

    }),
    dropdownIndicator: base => ({
      ...base,
      padding: 4
    }),
    clearIndicator: base => ({
      ...base,
      padding: 4
    }),
    multiValue: base => ({
      ...base
    }),
    valueContainer: base => ({
      ...base,
      padding: '0px 6px'
    }),
    input: base => ({
      ...base,
      margin: 0,
      padding: 0
    })
  };

  let selectItems = [];
  let year = 2010;
  let year_now = new Date().getFullYear();
  while (year <= year_now) {
    selectItems.push({label: year + ' - ' + (year + 1), value: year});
    year = year + 1;
  }

  return (
    <div>
      <div style={filterStyle}>
        <Row style={{alignItems: 'center'}}>
          <FormGroup className='col-12 col-md-8'>
            <InputGroup>
              <input
                type='text'
                name='filterName'
                value={filter}
                className='form-control'
                placeholder='Type the Event Name, Location, or Member Name'
                onChange={e => {
                  setFilter(e.target.value);
                }}
                onKeyUp={e => {
                  if (e.keyCode === 27) {
                    setFilter('');
                    getEventsAction(
                      paginationEvent.limit,
                      undefined, '', viewPast, selectYear
                    );
                  }
                  if (e.keyCode === 13) {
                    setFilter(e.target.value);
                    getEventsAction(
                      paginationEvent.limit,
                      undefined,
                      e.target.value, viewPast, selectYear
                    );
                  }
                }}
              />
              <Select
                styles={customStyles}
                placeholder='School Year'
                options={selectItems}
                value={selectYear}
                onChange={(opt, e) => {
                  setSelectYear(opt);
                }}
              />

              <InputGroupAddon addonType='append'>
                <Button
                  outline
                  color='info'
                  onClick={() => {
                    getEventsAction(
                      paginationEvent.limit,
                      undefined,
                      filter, viewPast, selectYear
                    );
                  }}
                >
                  Search
                </Button>
                <Button
                  outline
                  color='primary'
                  onClick={() => {
                    setFilter('');
                    setSelectYear('');
                    getEventsAction(paginationEvent.limit, 0, '', viewPast);
                  }}
                >
                  Reset
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
          <FormGroup style={{paddingLeft: '15px'}}>
            <CustomInput
              type='checkbox'
              id='viewPastEvents'
              label='View Past Events'
              inline
              checked={viewPast}
              onChange={() => {
                setViewPast(!viewPast);
                getEventsAction(paginationEvent.limit, 0, paginationEvent.filter, !viewPast, selectYear, paginationEvent.columnSort, paginationEvent.descending);
              }}
            />
          </FormGroup>
        </Row>
      </div>
      <div>
        <div>
          <Button
            hidden={!events.length > 0}
            color='link'
            style={linkStyle}
            onClick={() => {
              getEventsExcelAction(filter);
            }}
          >
            <FontAwesomeIcon icon={faFileExcel}/>
            <span style={{marginLeft: '5px'}}>Export to Excel</span>
          </Button>
          <div
            className='float-right'
            style={{padding: '10px 12px 6px', height: '38px'}}
          >
            {paginationEvent.count > events.length &&
            paginationEvent.skip + 1 + ' to '}
            {paginationEvent.skip + events.length}
            {paginationEvent.count > events.length &&
            ' of ' + paginationEvent.count}
            &nbsp;{events.length === 1 ? 'record' : 'records'}
          </div>
        </div>
        <Table responsive className='col-12'>
          <thead className='card-header'>
          <tr>
            <th>Name<ColumnSort columnName="name" columnSort={paginationEvent.columnSort} descending={paginationEvent.descending} changeSortAction={(object) => {
              changeSortAction({...paginationEvent, ...object});
              getEventsAction(paginationEvent.limit, paginationEvent.skip, paginationEvent.filter, viewPast, selectYear, object.columnSort, object.descending)
            }}/></th>
            <th>Location<ColumnSort columnName="location" columnSort={paginationEvent.columnSort} descending={paginationEvent.descending} changeSortAction={(object) => {
              changeSortAction({...paginationEvent, ...object});
              getEventsAction(paginationEvent.limit, paginationEvent.skip, paginationEvent.filter, viewPast, selectYear, object.columnSort, object.descending)
            }}/></th>
            <th>Start Date<ColumnSort columnName="date" columnSort={paginationEvent.columnSort} descending={paginationEvent.descending} changeSortAction={(object) => {
              changeSortAction({...paginationEvent, ...object});
              getEventsAction(paginationEvent.limit, paginationEvent.skip, paginationEvent.filter, viewPast, selectYear, object.columnSort, object.descending)
            }}/></th>
            <th>Seats Available</th>
            <th>Status</th>
            <th style={{minWidth: "86px"}}/>
          </tr>
          </thead>
          <tbody>{items}</tbody>
        </Table>
        {events.length > 0 && paginationEvent.limit < paginationEvent.count && (
          <MyPagination
            count={paginationEvent.count}
            limit={paginationEvent.limit}
            skip={paginationEvent.skip}
            action={skip => {
              getEventsAction(paginationEvent.limit, skip, filter, viewPast, selectYear, paginationEvent.columnSort, paginationEvent.descending);
            }}
          />
        )}

      </div>
    </div>
  );
};
const mapStateToProps = ({isFetching, getEvents, paginationEvent}) => ({
  isFetching,
  events: getEvents,
  paginationEvent
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    getEventsAction,
    getEventsExcelAction,
    changeSortAction: (data) => {
      dispatch(myDispatch(types.ADD_EVENT_PAGINATION, {data}));
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchProps
)(EventsList);
