import {combineReducers} from 'redux';
import {user} from './user';
import {general} from './general';
import {workshop} from './workshop';
import {event} from './event';
import {location} from './location';
import {title} from './title';
import {group} from './group';
import {member} from './member';
import {instructor} from './instructor';


export const reducers = {
  ...user,
  ...general,
  ...workshop,
  ...event,
  ...location,
  ...title,
  ...group,
  ...member,
  ...instructor

};

const rootReducer = combineReducers(reducers);

export default rootReducer;
