import {client} from '../util/createRestApiClient';

const base = "Registration/";
export default () => ({

  getMembersRegistration: (eventId, limit = 10, skip = 0, showUnregistered = false, columnSort = "", descending = true) => client.request(
    {
      method: 'GET',
      url: base + 'GetRegistrations/' + eventId + '?limit=' + limit + '&skip=' + skip + '&showUnregistered=' + showUnregistered + '&columnSort=' + columnSort + '&descending=' + descending
    }
  ),
  addMemberRegistration: (params) => client.request(
    {
      method: 'POST',
      url: base + 'AddRegistration/',
      data: params
    }
  ),
  moveMember: (params) => client.request(
    {
      method: 'POST',
      url: base + 'MoveMember/',
      data: params
    }
  ),
  unRegisterMember: (id) => client.request(
    {
      method: 'POST',
      url: base + 'Unregister/' + id
    }
  ),
  unRegisterByLogingMember: (eventId) => client.request(
    {
      method: 'POST',
      url: base + 'UnregisterByLoginMember/' + eventId
    }
  ),
  getRegistrationsExcel: (eventId, columnSort = "", descending = false) => client.request(
    {
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetRegistrationsExcel/' + eventId + '?columnSort=' + columnSort + '&descending=' + descending
    }
  ),
  getRegistrationsPDF: (eventId, columnSort = "", descending = false) => client.request(
    {
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetRegistrationsPDF/' + eventId + '?columnSort=' + columnSort + '&descending=' + descending
    }
  ),
  getCertificatePDF: (registrationId) => client.request(
    {
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetCertificatePDF/' + registrationId
    }
  ),
  getCertificatePDFbyUser: (eventId) => client.request(
    {
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetCertificatePDFByUser/' + eventId
    }
  ),
  sendCertificate: (eventId) => client.request(
    {
      method: 'POST',
      url: base + 'SendEmailCertificatePDF/' + eventId //  change method
    }
  ),
  attend: (registrationId, option) => client.request(
    {
      method: 'POST',
      url: base + 'Attend/' + registrationId + '?option=' + option
    }
  )
});
