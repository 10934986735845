import * as types from "../types";

export const title = {
  CRUDTitle: (state = types.CREATE_TITLE, action) => {
    switch (action.type) {
      case types.GET:
        return types.GET;
      case types.CREATE_TITLE:
        return types.CREATE_TITLE;
      case types.UPDATE_TITLE:
        return types.UPDATE_TITLE;
      case types.DELETE_TITLE:
        return types.DELETE_TITLE;
      default:
        return state
    }
  },
  title: (state = {}, action) => {
    switch (action.type) {
      case types.GET_TITLE_SUCCESS:
        return action.data;
      case types.ADD_TITLE:
        return action.data;
      case types.GET_TITLE_FAILURE:
        return {};
      default:
        return state
    }
  },
  getTitle: (state = {}, action) => {
    switch (action.type) {
      case types.GET_TITLE_SUCCESS:
        return action.data;
      case types.GET_TITLE_FAILURE:
        return {};
      case types.RESET_GET_TITLE:
        return {};
      default:
        return state
    }
  },
  titles: (state = [], action) => {
    switch (action.type) {
      case types.ADD_TITLES:
        return action.data;
      case types.GET_TITLES_SUCCESS:
        return action.data;
      case types.GET_TITLES_FAILURE:
        return [];
      case types.REMOVE_ITEM_TITLES:
        let list = [...state];
        if (typeof action.data) {
          list.splice(action.data, 1);
        }
        return list;
      default:
        return state
    }
  },
  paginationTitle: (state = {count: 0, limit: 10, skip: 0, filterName: "", columnSort: "", descending: false}, action) => {
    switch (action.type) {
      case types.ADD_TITLE_PAGINATION:
        return {...state, ...action.data};
      case types.RESET_TITLE_PAGINATION:
        return {};
      default:
        return state
    }
  }
};