import {client} from '../util/createRestApiClient';

const base = "Workshop/";

export default () => ({
  addWorkShop: (params) => client.request(
    {
      method: 'POST',
      url: base + 'AddWorkshop',
      data: params
    }
  ),
  getWorkShop: (params) => client.request(
    {
      method: 'GET',
      url: base + 'getWorkshop?id=' + params.id
    }
  ),
  getWorkShops: (limit = 10, skip = 0, filter = '', columnSort = '', descending = true) => client.request(
    {
      method: 'GET',
      url: base + 'GetWorkshops?limit=' + limit + '&skip=' + skip + '&filter=' + filter + '&columnSort=' + columnSort + '&descending=' + descending
    }
  ),
  updateWorkShop: (params, id) => client.request(
    {
      method: 'PUT',
      url: base + 'UpdateWorkshop/' + id,
      data: params
    }
  ),
  deleteWorkShop: (id) => client.request(
    {
      method: 'DELETE',
      url: base + 'DeleteWorkshop/' + id
    }
  ),
  getWorkShopsExcel: (filter = '') => client.request(
    {
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetWorkshopsExcel?filter=' + filter
    }
  ),
  addRatingWorkshop: (params) => client.request(
    {
      method: 'POST',
      url: base + 'RatingWorkshop',
      data: params
    }
  ),
  getWorkShopsVersion: (workshopId, limit = 10, skip = 0, filter = '') => client.request(
    {
      method: 'GET',
      url: base + 'GetWorkshopsVersions/' + workshopId + '?limit=' + limit + '&skip=' + skip + '&filter=' + filter

      //change method.
    }
  ),
});
