import {client} from '../util/createRestApiClient';

const base = "title/";
export default () => ({
    getTitle: (params) => client.request({
      method: 'GET',
      url: base + 'GetTitle/' + params.id
    }),
    getTitleByTitle: (title) => client.request({
      method: 'GET',
      url: base + 'GetTitle?title=' + title
    }),
    getTitles: (limit = 10, skip = 0, filterName = "", columnSort = "", descending = true) => client.request({
      method: 'GET',
      url: base + 'GetTitles?limit=' + limit + '&skip=' + skip + '&filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    }),
    getAllTitles: (filterName = "", columnSort = "", descending = true) => client.request({
      method: 'GET',
      url: base + 'GetAllTitles?filter=' + filterName + '&columnSort=' + columnSort + '&descending=' + descending
    }),
    addTitle: (params) => client.request({
      method: 'POST',
      url: base + 'AddTitle',
      data: params
    }),
    updateTitle: (params) => client.request({
      method: 'PUT',
      url: base + 'UpdateTitle/' + params.id,
      data: params
    }),
    deleteTitle: (params) => client.request({
      method: 'DELETE',
      url: base + 'DeleteTitle/' + params.id
    })
  }
);
