import React from 'react';
import {UncontrolledAlert} from 'reactstrap';

const AlertNotification = ({color, id, message, dismissAlertAction}) => {
  setTimeout(() => {
    dismissAlertAction(id)
  }, 5000);

  return (
    <UncontrolledAlert color={color || "primary"} style={{fontWeight: "bold"}} onClick={() => dismissAlertAction(id)}>
      <h5 className="alert-heading" style={{margin: 0}}>{color === "danger" ? "Failure" : (color === "warning" ? "Warning" : "Success")}</h5>
      <p style={{margin: 0}}>{message}</p>
    </UncontrolledAlert>
  );
};

export default AlertNotification;
