import React, {useEffect, useState} from 'react';
import {Button, FormGroup, InputGroup, InputGroupAddon, Row, Table} from 'reactstrap';
import {ColumnSort, EventMemberViewItemLast, EventMemberViewLast, MyPagination} from '../index';
import {myDispatch, registerDispatch} from '../../actions/util';
import {connect} from 'react-redux';
import {getEventsMemberViewAction} from '../../actions/event';
import {getEventsIcalFileAllAction} from '../../actions/workshop';
import * as types from "../../types";

const EventMemberViewListLast = ({
                                    events,
                                    isFetching,
                                    getEventsMemberViewAction,
                                    paginationEventMemberView,
                                    getEventsIcalFileAllAction,
                                    changeSortAction
                                  }) => {

  const [filter, setFilter] = useState('');

  useEffect(() => {
    getEventsMemberViewAction(paginationEventMemberView.limit, paginationEventMemberView.skip, filter, paginationEventMemberView.columnSort, true, false);
    // eslint-disable-next-line
  }, [getEventsMemberViewAction]);

  let items = [];

  if (events.length) {
    for (let i in events) {
      items.push(
        <EventMemberViewItemLast
          key={'event-item' + i}
          event={events[i]}
          index={i}
          paginationEventMemberView={paginationEventMemberView}
        />
      );
      items.push(<tr key={"eventmember-view" + i}>
        <th colSpan="7" style={{padding: "0 12px"}}><EventMemberViewLast index={i} event={events[i]}/></th>
      </tr>);
    }
  }
  const filterStyle = {
    border: '1px dashed #e9e9e9',
    borderRadius: '6px',
    padding: '.75rem .75rem 0',
    marginBottom: '5px',
    backgroundColor: '#fafafa'
  };

  return (
    <div className='col-12'>
      <Row>
        <h3 className='col-8'>My Workshops</h3>
      </Row>
      <div>
        <div style={filterStyle}>
          <Row style={{alignItems: 'center'}}>
            <FormGroup className='col-12 col-md-8'>
              <InputGroup>
                <input
                  type='text'
                  name='filterName'
                  value={filter}
                  className='form-control'
                  placeholder='Workshop'
                  onChange={e => {
                    setFilter(e.target.value);
                  }}
                  onKeyUp={e => {
                    if (e.keyCode === 27) {
                      setFilter('');
                      getEventsMemberViewAction(paginationEventMemberView.limit, paginationEventMemberView.skip, '', '', true, false);
                    }
                    if (e.keyCode === 13) {
                      setFilter(e.target.value);
                      getEventsMemberViewAction(paginationEventMemberView.limit, paginationEventMemberView.skip, e.target.value, '', true, false);
                    }
                  }}
                />
                <InputGroupAddon addonType='append'>
                  <Button
                    outline
                    color='primary'
                    onClick={() => {
                      getEventsMemberViewAction(paginationEventMemberView.limit, paginationEventMemberView.skip, filter, '', true, false);
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    outline
                    color='primary'
                    onClick={() => {
                      setFilter('');
                      getEventsMemberViewAction(paginationEventMemberView.limit, paginationEventMemberView.skip, '', '', true, false);
                    }}
                  >
                    Reset
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Row>
        </div>
        <Table responsive className='col-12 mt-2'>
          <thead className='card-header'>
          <tr>
            <th>Workshop<ColumnSort columnName="workshop" columnSort={paginationEventMemberView.columnSort} descending={paginationEventMemberView.descending} changeSortAction={(object) => {
              changeSortAction({...paginationEventMemberView, ...object});
              getEventsMemberViewAction(paginationEventMemberView.limit, paginationEventMemberView.skip, filter, object.columnSort, object.descending, false)
            }}/></th>
            <th>Date<ColumnSort columnName="date" columnSort={paginationEventMemberView.columnSort} descending={paginationEventMemberView.descending} changeSortAction={(object) => {
              changeSortAction({...paginationEventMemberView, ...object});
              getEventsMemberViewAction(paginationEventMemberView.limit, paginationEventMemberView.skip, filter, object.columnSort, object.descending, false)
            }}/></th>
            <th>Rating</th>
            <th/>
          </tr>
          </thead>
          <tbody>
          {events.length ? (
            items
          ) : (
            <tr>
              {!isFetching && <th colSpan='3' className='text-center'>
                No content data
              </th>}
            </tr>
          )}
          </tbody>
        </Table>
        {events.length > 0 && paginationEventMemberView.limit < paginationEventMemberView.count && (
          <MyPagination
            count={paginationEventMemberView.count}
            limit={paginationEventMemberView.limit}
            skip={paginationEventMemberView.skip}
            action={skip => {
              if (!isFetching) {
                getEventsMemberViewAction(paginationEventMemberView.limit, skip, filter, paginationEventMemberView.columnSort, paginationEventMemberView.descending, false);
              }
            }}
          />
        )}

      </div>
    </div>
  );
};
const mapStateToProps = ({isFetching, getEventsMemberView, paginationEventMemberView}) => ({
  isFetching,
  events: getEventsMemberView,
  paginationEventMemberView
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    getEventsMemberViewAction,
    getEventsIcalFileAllAction,
    changeSortAction: (data) => {
      dispatch(myDispatch(types.ADD_EVENTMEMBERVIEW_PAGINATION, {data}));
    }

  };
};
export default connect(
  mapStateToProps,
  mapDispatchProps
)(EventMemberViewListLast);
