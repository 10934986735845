import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faTrash} from '@fortawesome/free-solid-svg-icons';
import {myDispatch, registerDispatch} from '../../actions/util';
import {connect} from 'react-redux';
import * as types from '../../types';
import {deleteUserAction} from '../../actions/user';
import {MyTooltip} from '../index';
import confirm from 'reactstrap-confirm';
import {isAllow} from './../../util/userActions';
import { useHistory, useLocation } from "react-router-dom";

const UserItems = ({
                     user,
                     index,
                     updateUser,
                     deleteUserAction,
                     setIsActive
                   }) => {

  let history = useHistory();
  let location = useLocation();

  let style = {};

  if (!user.iS_ACTIVE) {
    style = {backgroundColor: "#fcfcfc", textDecoration: "line-through"};
  }

  return (
    <tr style={style}>
      <td id={'user' + index} className='cursor-pointer'>
        {user.name}
      </td>
      <td id={'user' + index} className='cursor-pointer'>
        {user.userName}
      </td>
      <td id={'user' + index} className='cursor-pointer'>
        {user.email}
      </td>
      <td id={'user' + index} className='cursor-pointer'>
        {user.mobilePhone}
      </td>
      <td className='text-right'>
        {isAllow('UpdateUser', user.isGlobalUser) && <FontAwesomeIcon
          icon={faEdit}
          style={{color: 'rgba(27,31,35,.3)', cursor: 'pointer'}}
          onClick={() => {
            history.push({...location, hash: "edit-user"});
            //cancelGetUsersActionFn(true);
            updateUser(user);
            setIsActive(false);
          }}
          className=' primary mr-2'
          id={'tooltip-user-edit' + index}
        />}
        {isAllow('UpdateUser', user.isGlobalUser) && <MyTooltip placement='top' target={'tooltip-user-edit' + index}>
          Edit
        </MyTooltip>}

        {isAllow('DeleteUser', user.isGlobalUser) && <FontAwesomeIcon
          className='mr-0'
          style={{color: 'rgba(27,31,35,.3)', cursor: 'pointer'}}
          onClick={async () => {
            const result = await confirm({
              title: "Delete",
              message: 'Are you sure that you want to delete this user?',
              confirmText: 'Yes',
              cancelText: 'No'
            });
            if (result) {
              deleteUserAction(user.eliId);
            }
          }}
          id={'tooltip-user-delete' + index}
          icon={faTrash}
        />}
        {isAllow('DeleteUser', user.isGlobalUser) && <MyTooltip placement='top' target={'tooltip-user-delete' + index}>
          Delete
        </MyTooltip>}
      </td>
    </tr>
  );
};
const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    updateUser: user => {
      dispatch(myDispatch(types.ADD_USER, {data: user}));
      dispatch(myDispatch(types.UPDATE_USER));
    },
    deleteUserAction
  };
};
export default connect(
  undefined,
  mapDispatchProps
)(UserItems);
