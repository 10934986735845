import React, {useEffect, useState} from 'react';
import {Button, FormGroup, Label, Modal, ModalBody, ModalHeader, Row, Table} from 'reactstrap';
import {MemberReminderItems, MyPagination, ReadOnly, SuperUser} from '../index';
import {registerDispatch} from '../../actions/util';
import FormHook from '../../util/FormHook/FormHook';
import {connect} from 'react-redux';
import {sendCustomNotificationAction} from '../../actions/member';
import {getEventReminderAction, sendReminderAction} from '../../actions/event';
import confirm from 'reactstrap-confirm';

const MemberReminderList = ({
                              className,
                              event,
                              eventReminder,
                              getEventReminderAction,
                              sendCustomNotificationAction,
                              sendReminderAction,
                              paginationEventReminder
                            }) => {
  useEffect(() => {

    getEventReminderAction(event.id, 10, 0);
    return () => {
      //   myDispatch(types.RESET_MEMBER_REGISTRATION);
    }
    // eslint-disable-next-line
  }, [getEventReminderAction, event]);


  const [custom, setCustom] = useState(false);
  const [sendingReminder, setSendingReminder] = useState("");


  const customModal = () => {
    setCustom(!custom);
  };

  const marginStyle = {
    marginLeft: '10px'
  };

  let items = [];

  if (eventReminder.length) {
    for (let i in eventReminder) {
      items.push(
        <MemberReminderItems reminder={eventReminder[i]}
                             key={'memberreminder-item' + i}
                             index={i}
        />
      );
    }
  }

  return (
    <div className={className + ' mt-5'} style={marginStyle}>
      <div className='card'>
        <div className='col-12'>
          <Row>
            <h3 className='ml-1'>Notification</h3>
            <div className='col-10'>
              <SuperUser>
                {sendingReminder && <small>({sendingReminder})</small>}
                <Button
                  color='primary' outline className='ml-2 mt-2'
                  hidden={!event.registrationCount > 0 || event.iscancelled}
                  onClick={async () => {
                    const result = await confirm({
                      title: "Send Reminder",
                      message:
                        'Are you sure that you want to send a reminder to the member list?',
                      confirmText: 'Yes',
                      cancelText: 'No'
                    });
                    if (result) {
                      setSendingReminder("Sending Reminder...");
                      sendReminderAction(event.id)
                    }
                  }

                  }>Send Reminder </Button>
                <Button
                  color='primary'
                  hidden={!event.registrationCount > 0 || event.iscancelled}
                  outline
                  className='ml-2 mt-2'
                  onClick={() => {
                    customModal();
                  }}
                >
                  Custom Message
                </Button>
              </SuperUser>
            </div>
          </Row>
        </div>
        <div
          className='float-right'
          style={{padding: '0 12px 6px', textAlign: 'right'}}
        >
          {paginationEventReminder.count > eventReminder.length &&
          paginationEventReminder.skip + 1 + ' to '}
          {paginationEventReminder.skip + eventReminder.length}
          {paginationEventReminder.count > eventReminder.length &&
          ' of ' + paginationEventReminder.count}
          &nbsp;{eventReminder.length === 1 ? 'record' : 'records'}
        </div>
        <ReadOnly>
          <Table className='col-12' responsive>
            <thead className='card-header'>
            <tr>
              <th>Message Type</th>
              <th> Date</th>
              <th>Time</th>
              <th>User</th>
            </tr>
            </thead>
            <tbody>{eventReminder.length ? (
              items
            ) : (
              <tr>
                <th colSpan='6' className='text-center'>
                  No content data...
                </th>
              </tr>
            )}</tbody>
          </Table>
        </ReadOnly>
        {eventReminder.length > 0 && paginationEventReminder.limit < paginationEventReminder.count && (
          <MyPagination
            count={paginationEventReminder.count}
            limit={paginationEventReminder.limit}
            skip={paginationEventReminder.skip}
            action={skip => {
              getEventReminderAction(event.id, paginationEventReminder.limit, skip);
            }}
          />
        )}
      </div>
      <div>
        <Modal isOpen={custom} toggle={customModal}>
          <ModalHeader className='card-header' toggle={customModal}>
            Custom Message
          </ModalHeader>
          <ModalBody>
            <FormHook
              submit={values => {
                sendCustomNotificationAction(event.id, values.head, values.body);
                customModal();
              }}
            >
              <FormGroup>
                <h5>Subject :</h5>
                <div className='ml-2'>
                  <input name='head' required/>
                </div>
              </FormGroup>
              <FormGroup>
                <h5>Body :</h5>
                <div className='ml-2'>
                <textarea name='body' required style={{
                  width: '465px',
                  height: '150px',
                  padding: '10px',
                  borderRadius: '3px'
                }}/>
                </div>
              </FormGroup>
              <Button color='primary'>Send Message</Button>
              <Label>&nbsp;or&nbsp;</Label>
              <span
                style={{cursor: 'pointer', color: '#17a2b8', marginBottom: '5px'}}
                onClick={() => {
                  customModal();
                }}
              >
              Cancel
            </span>
            </FormHook>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};
const mapStateToProps = ({
                           isFetching,
                           event,
                           getEventReminders,
                           paginationEventReminder
                         }) => ({
  isFetching,
  event,
  eventReminder: getEventReminders,
  paginationEventReminder
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    getEventReminderAction,
    sendCustomNotificationAction,
    sendReminderAction
  };
};
export default connect(
  mapStateToProps,
  mapDispatchProps
)(MemberReminderList);
