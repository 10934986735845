import React from 'react'
import {Link} from "react-router-dom";
import logo from '../../resources/images/ELI_Logo.svg';
import './page404.css'

const Page404 = () => (
  <div className="page-404-before">
    <div className="page-404">
      <div className="center">
        <div className="text-center title">404</div>
        <div className="text-center sub-title">Page Not Found</div>
        <Link to="/"><img alt="ElI" className="logo-404" src={logo}/></Link>
      </div>
    </div>
  </div>
);

export default Page404;