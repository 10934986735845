import React, { useEffect, useState }                                                                                                                      from 'react';
import { Alert, Badge, Button, Col, CustomInput, FormGroup, Label, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Row }             from 'reactstrap';
import { Hour, InstructorList, MemberRegistrationList, MemberReminderList, MemberWaitingList, MyDatePicker, MyPagination, MyTooltip, ReadOnly, SuperUser } from '../index';
import DatePicker                                                                                                                                          from 'react-datepicker';
import { getWorkShopAction, getWorkShopsAction }                                                                                                           from '../../actions/workshop';
import { getLocationAction, getLocationsAction }                                                                                                           from '../../actions/location';
import {
  addEventAction,
  addEventFileNoAction,
  addEventFileNumberAction,
  addEventGroupAction,
  addEventTitleAction,
  cancelGetEventsActionFn,
  cloneEventAction,
  deleteEventGroupAction,
  deleteEventTitleAction,
  getEventFileNumbersAction,
  getEventGroupsAction,
  getEventTitlesAction,
  getFileNumberExcelAction,
  importFileNumbersExcelAction,
  updateEventAction
}                                                                                                                                                          from '../../actions/event';
import { connect }                                                                                                                                         from 'react-redux';
import Select                                                                                                                                              from 'react-select';
import * as types                                                                                 from '../../types';
import { dateFromISOToLocal, myDispatch, parseDateFromTime, parseTimeFromDate, registerDispatch } from '../../actions/util';
import { getAllTitlesAction }                                                                     from '../../actions/title';
import { FontAwesomeIcon }                                                                                                                                 from '@fortawesome/react-fontawesome';
import { faTimes }                                                                                                                                         from '@fortawesome/free-solid-svg-icons';
import { getGroupsAction }                                                                                                                                 from '../../actions/group';
import confirm                                                                                                                                             from 'reactstrap-confirm';
import InputHook                                                                                                                                           from "../../util/FormHook/InputHook";
import { FormContext }                                                                                                                                     from "../../util/FormHook/FormHook";
import { useHistory }                                                                                                                                      from "react-router-dom";


export const EventFormContext = React.createContext();

let values = {};

const EventsForm = ({
                      CRUDEvent,
                      members,
                      groups,
                      eventGroups,
                      getGroupsAction,
                      event,
                      titles,
                      isFetching,
                      eventTitles,
                      addEventAction,
                      updateEventAction,
                      getLocationAction,
                      cloneEventAction,
                      workShops,
                      workshop,
                      getWorkShopAction,
                      getWorkShopsAction,
                      locations,
                      location,
                      getLocationsAction,
                      toggleForm,
                      user,
                      addEventGroupAction,
                      addEventFileNumberAction,
                      getEventGroupsAction,
                      eventFileNumbers,
                      getEventFileNumbersAction,
                      importFileNumbersExcelAction,
                      paginationEventFileNumber,
                      paginationEventTitle,
                      paginationEventGroup
                    }) => {

  let history = useHistory();

  const [dateFrom, setDateFrom] = useState(dateFromISOToLocal(event.startdate));
  const [endDateFrom, setEndDateFrom] = useState(dateFromISOToLocal(event.enddate));
  const [showDateFrom, setShowDateFrom] = useState(dateFromISOToLocal(event.startshowingdate));
  const [endShowDateFrom, setEndShowDateFrom] = useState(dateFromISOToLocal(event.endshowingdate));

  const [startTime, setStartTime] = useState(parseTimeFromDate(event.startdate, event.starttime, '08:30 am'));
  const [endTime, setEndTime] = useState(parseTimeFromDate(event.enddate, event.endtime,'09:15 am'));

  useEffect(() => {
    window.scrollTo(0, 0)
    // eslint-disable-next-line
  }, []);

  const [valuesIsValid, setValuesIsValid] = useState({});
  const [workshopSelect, setWorkshopSelect] = useState();
  const [locationSelect, setLocationSelect] = useState();
  const isCancelled = false;
  const [isForAllTitles, setIsForAllTitles] = useState(event.isforalltitles ? event.isforalltitles : true);
  const [isForTitles, setIsForTitles] = useState(event.isfortitle ? event.isfortitle : false);
  const [isForFileNo, setIsForFileNo] = useState(event.isforfileno ? event.isforfileno : false);
  const [isForGroup, setIsForGroup] = useState(event.isforgroup ? event.isforgroup : false);
  const certificatesSent = false;

  const [modalFileNo, setModalFileNo] = useState(false);
  const [member, setMember] = useState();
  const [modalImportFileNo, setModalImportFileNo] = useState(false);
  const [uploadExcel, setUploadExcel] = useState();
  const [textSelect, setTextSelect] = useState();


  const registerFileNoModal = () => {
    setModalFileNo(!modalFileNo);
  };
  const importFileNoModal = () => {
    setModalImportFileNo(!modalImportFileNo);
  };

  const sectionStyle = {
    border: '1px solid #e9e9e9',
    borderRadius: '6px',
    padding: '.75rem .75rem 0',
    marginBottom: '5px'
  };
  const sectionStyleC = {
    border: '1px solid #e9e9e9',
    borderRadius: '6px',
    padding: '.75rem',
    marginBottom: '5px'
  };
  const actionsStyle = {
    margin: '15px 0',
    padding: '10px 0',
    width: '100%',
    borderTop: '1px solid #e9e9e9'
  };
  const customStyles = {
    control: base => ({
      ...base,
      height: 30
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: 4
    }),
    clearIndicator: base => ({
      ...base,
      padding: 4
    }),
    multiValue: base => ({
      ...base
    }),
    valueContainer: base => ({
      ...base,
      padding: '0px 6px'
    }),
    input: base => ({
      ...base,
      margin: 0,
      padding: 0
    })
  };


  useEffect(() => {
    getWorkShopsAction(1000, 0);
    if (event.workshopModelId) {
      getWorkShopAction(event.workshopModelId, w => {
        setWorkshopSelect({label: w.title, value: w.id});
      });
    }
    getLocationsAction(1000, 0);
    if (event.eventLocationModelId) {
      getLocationAction(event.eventLocationModelId, l => {
        setLocationSelect({label: l.name, value: l.id});
      });
    }

    return () => {
      myDispatch(types.RESET_EVENT);
    }

    // eslint-disable-next-line
  }, [
    getWorkShopAction,
    getLocationsAction,
    getWorkShopsAction,
    getLocationAction, event
  ]);

  let selectItems = [];
  if (workShops.length) {
    for (let i in workShops) {
      selectItems.push({label: workShops[i].title, value: workShops[i].id});
    }
  }

  let selectLocations = [];
  if (locations.length) {
    for (let i in locations) {
      selectLocations.push({
        label: locations[i].name,
        value: locations[i].id
      });
    }
  }

  let selectMembers = [];
  if (members.length) {
    for (let i in members) {
      selectMembers.push({label: members[i].lastname, value: members[i]});
    }
  }

  const isCreate = CRUDEvent === types.CREATE_EVENT;
  const isUpdate = CRUDEvent === types.UPDATE_EVENT;
  const isClone = CRUDEvent === types.CLONE_EVENT;

  const cancel = (
    <span
      className='simulate-a'
      style={{cursor: 'pointer', color: '#17a2b8'}}
      onClick={() => {
        history.push({...location, hash: ""});
        toggleForm();
      }}
    >
      Cancel
    </span>
  );

  const styleTmp = {
    fontSize: '11px',
    color: '#f44336',
    fontWeight: 'bold',
    marginBottom: '-12px',
    display: 'block'
  };

  const getError = name => {
    if (valuesIsValid[name] === false) {
      return <span style={styleTmp} className="text-alert">Please, fill this field</span>
    }
    return <span/>;
  };

  useEffect(() => {
    values = {...event, name: isCreate ? (textSelect || "") : (event.name || "")};
    return () => {
      values = {}
    };
    // eslint-disable-next-line
  }, [event, isCreate, textSelect]);

  const changeValues = (name, value) => {
    values[name] = value;
  };

  const isValid = values && values.capacity > 0 && values.credithours >= 0;

  let isAdmin = false, roles = localStorage.getItem("rol");
  if (roles) {
    roles = roles.split(',');
    isAdmin = roles.some((value) => value === "SuperUser")
  }

  const form = (
    <FormContext.Provider value={changeValues}>
      <form
        key={"event-" + (isCreate ? textSelect : (event && event.id ? event.id : ""))}
        className={isCreate ? 'col-md-8' : 'col-md-6'}
        onSubmit={(e) => {
          cancelGetEventsActionFn(false);
          e.preventDefault();
          values = {...event, ...values};
          const tmpStartDate = parseDateFromTime(dateFrom, startTime);
          const tmpEndDate = parseDateFromTime(endDateFrom, endTime);
          if (locationSelect && locationSelect.value && workshopSelect && workshopSelect.value) {
            if (isCreate) {
              addEventAction(
                values.name,
                values.title,
                values.capacity,
                values.credithours,
                values.reminderpriordays,
                tmpStartDate,
                tmpEndDate,
                showDateFrom,
                endShowDateFrom,
                // values.startshowingdate,
                // values.endshowingdate,
                values.nameincertificate,
                startTime,
                endTime,
                isCancelled,
                isForAllTitles,
                certificatesSent,
                isForFileNo,
                isForGroup,
                isForTitles,
                workshopSelect.value,
                user.id,
                locationSelect.value
              );
            } else if (isUpdate) {
              updateEventAction({
                ...values,
                startshowingdate: showDateFrom,
                endshowingdate: endShowDateFrom,
                wP_Id: event.wP_Id,
                isforalltitles: isForAllTitles,
                isforfileno: isForFileNo,
                isfortitle: isForTitles,
                isforgroup: isForGroup,
                startdate: tmpStartDate,
                enddate: tmpEndDate,
                starttime: startTime,
                endtime: endTime,
                workshopModelId: workshopSelect.value,
                userModelId: user.id,
                eventLocationModelId: locationSelect.value
              });
            } else if (isClone) {
              cloneEventAction(
                values.name,
                values.title,
                values.capacity,
                values.credithours,
                values.reminderpriordays,
                tmpStartDate,
                tmpEndDate,
                showDateFrom,
                endShowDateFrom,
                // values.startshowingdate,
                // values.endshowingdate,
                values.nameincertificate,
                startTime,
                endTime,
                isCancelled,
                isForAllTitles,
                certificatesSent,
                isForFileNo,
                isForGroup,
                isForTitles,
                workshopSelect.value,
                user.id,
                locationSelect.value
              );
            }
          }
        }}
      >
        <ReadOnly>
          <Row style={{marginLeft: '0px'}}>
            <h5>{isCreate ? 'New' : isClone ? 'Clone' : 'Update'} Event</h5>
          </Row>
          <div style={{maxWidth: '800px', marginTop: '10px'}}>
            {isUpdate && event.id && <input type='hidden' name='id'/>}
            <div style={sectionStyle}>
              <FormGroup row>
                <Label sm={2}>Workshop</Label>
                <Col sm={10}>
                  <Select required
                          styles={customStyles}
                          options={selectItems}
                          value={workshopSelect}
                          onChange={(opt, e) => {
                            values["name"] = opt.label;
                            values["title"] = opt.label;
                            values["nameincertificate"] = opt.label;
                            setWorkshopSelect(opt);
                            setTextSelect(opt.label);
                            setValuesIsValid({...valuesIsValid, workshopSelect: true, textSelect: true})
                          }}
                  />
                  {getError("workshopSelect")}
                </Col>
              </FormGroup>
            </div>
            <div style={sectionStyle}>
              <FormGroup row>
                <Label sm={2}>Date</Label>
                <Col sm={4}>
                  {/*<input type="date" name="dateFrom"/>*/}
                  <DatePicker
                    disabled={isAdmin === true ? false : event.registrationCount > 0}
                    size='sm'
                    dateFormat='MM/dd/yyyy'
                    selected={dateFrom}
                    onChange={value => {
                      setDateFrom(value);
                      if (value > endDateFrom) {
                        setEndDateFrom(value)
                      }
                      setValuesIsValid({...valuesIsValid, dateFrom: true})
                    }}
                  />
                  {getError("dateFrom")}
                </Col>
                <span style={{padding: '7px 15px'}}>to</span>
                <Col sm={4}>
                  <DatePicker
                    disabled={isAdmin === true ? false : event.registrationCount > 0}
                    dateFormat='MM/dd/yyyy'
                    selected={endDateFrom}
                    minDate={dateFrom}
                    showDisabledMonthNavigation
                    onChange={value => {
                      setEndDateFrom(value);
                      setValuesIsValid({...valuesIsValid, endDateFrom: true})
                    }}
                  />
                  {getError("endDateFrom")}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={2}>Time</Label>
                <Col sm={9}>
                  <EventFormContext.Provider value={setStartTime}>
                    <Hour
                      context={EventFormContext}
                      value={startTime}
                      label='(start)'
                    />
                  </EventFormContext.Provider>
                  <EventFormContext.Provider value={setEndTime}>
                    <Hour
                      context={EventFormContext}
                      value={endTime}
                      label='(end)&nbsp;'
                    />
                  </EventFormContext.Provider>
                  <small style={{color: "gray"}}>Your time zone is: GMT{new Date().toString().replace(/^.+GMT/, "")}</small>
                </Col>
              </FormGroup>
            </div>
            <div style={sectionStyle}>
              <FormGroup row>
                <Label sm={2}>Name</Label>
                <Col sm={9}>
                  <InputHook defaultValue={values["name"]} newValue={textSelect || event.name} item={<textarea name='name' onChange={() => {
                    setValuesIsValid({...valuesIsValid, textSelect: true})
                  }}/>}/>
                  {getError("textSelect")}
                </Col>
                <Col sm={1}>
                  <Badge id='Badge-name' color="light">?</Badge>
                </Col>
              </FormGroup>
              <MyTooltip placement='top' target={'Badge-name'}>
                The name that event manager/instructor uses in event list.
              </MyTooltip>
              <FormGroup row>
                <Label sm={2}>Title</Label>
                <Col sm={9}>
                  <InputHook defaultValue={values["title"]} newValue={textSelect || event.title} item={<textarea name='title' defaultValue={textSelect} onChange={() => {
                    setValuesIsValid({...valuesIsValid, textSelect: true})
                  }}/>}/>
                  {getError("textSelect")}
                </Col>
                <Col sm={1}>
                  <Badge id='Badge-title' color="light">?</Badge>
                </Col>
              </FormGroup>
              <MyTooltip placement='top' target={'Badge-title'}>
                Event title to show in the event list/newsletter. What the member sees.
              </MyTooltip>
            </div>
            <div style={sectionStyle}>
              <FormGroup row>
                <Label sm={2}>Capacity</Label>
                <Col sm={3}>
                  <InputHook defaultValue={values["capacity"]} newValue={event.capacity} styleErrorMessage={{minWidth: "max-content"}}
                             item={<input type='number' validation={(value) => value > 0} errorMessage={"Capacity must be greater than 0"} name='capacity' required onChange={() => {
                               setValuesIsValid({...valuesIsValid, capacity: true})
                             }}/>}/>
                </Col>
                <div className="col-10">{getError("capacity")}</div>
              </FormGroup>
              <FormGroup row>
                <Label sm={2}>Location</Label>
                <Col sm={10} className="select-container">
                  <Select
                    styles={customStyles}
                    options={selectLocations}
                    value={locationSelect}
                    onChange={(opt, e) => {
                      setLocationSelect(opt);
                      setValuesIsValid({...valuesIsValid, locationSelect: true})
                    }}
                  />
                  {getError("locationSelect")}
                </Col>
              </FormGroup>
            </div>
            <div style={sectionStyle}>
              <FormGroup row>
                <Label sm={3}>Name In Certificate</Label>
                <Col sm={9}>
                  <InputHook defaultValue={values["nameincertificate"]} newValue={textSelect || event.nameincertificate} item={<textarea name='nameincertificate' defaultValue={textSelect} onChange={() => {
                    setValuesIsValid({...valuesIsValid, textSelect: true})
                  }}/>}/>
                  {getError("textSelect")}
                </Col>
              </FormGroup>{' '}
              <FormGroup row>
                <Label sm={3}>Credit Hours</Label>
                <Col sm={3}>
                  <InputHook defaultValue={values["credithours"] || 0} newValue={event.credithours} styleErrorMessage={{minWidth: "max-content"}}
                             item={<input type='number' validation={(value) => value >= 0} errorMessage={"Credit hours must be greater or equal than 0"} name='credithours' required onChange={() => {
                               setValuesIsValid({...valuesIsValid, credithours: true})
                             }}/>}/>
                  {getError("credithours")}
                </Col>
              </FormGroup>
            </div>
            <div style={sectionStyle}>

              <FormGroup row>
                <Label sm={2}>Showing</Label>
                <Col sm={4}>
                  <span className="btn-today" onClick={() => {
                    setShowDateFrom(new Date())
                  }}>Today</span>
                  <MyDatePicker
                    name="startshowingdate" dateFormat="MM/dd/yyyy"
                    onChange={value => {
                      setShowDateFrom(value);
                      if (value > endShowDateFrom) {
                        setEndShowDateFrom(value);
                        setValuesIsValid({...valuesIsValid, startshowingdate: true})
                      }
                    }}
                    maxDate={endShowDateFrom}
                    autoComplete="off"
                    selected={showDateFrom}/>
                  {getError("startshowingdate")}
                </Col>
                <span style={{padding: '7px 15px'}}>to</span>
                <Col sm={4}>
                  <span className="btn-today" onClick={() => {
                    setEndShowDateFrom(new Date())
                  }}>Today</span>
                  <MyDatePicker
                    name="endshowingdate"
                    dateFormat="MM/dd/yyyy"
                    showDisabledMonthNavigation
                    minDate={showDateFrom}
                    selected={endShowDateFrom}
                    autoComplete="off"
                    onChange={value => {
                      setEndShowDateFrom(value);
                      setValuesIsValid({...valuesIsValid, endshowingdate: true})
                    }}/>
                  {getError("endshowingdate")}
                </Col>
                <Col sm={1}>
                  <Badge id='Badge-show' color="light">?</Badge>
                </Col>
                <MyTooltip placement='top' target={'Badge-show'}>
                  Range dates - when the event is displayed for members.
                </MyTooltip>
              </FormGroup>
            </div>
          </div>
        </ReadOnly>
        <div style={actionsStyle}>
          <SuperUser>
            <Button disabled={!isValid} color='primary'>
              {isCreate ? 'Save' : isClone ? 'Clone' : 'Update'}
              {isFetching ? '...' : ''}
            </Button>
            <Label>&nbsp;or&nbsp;</Label>
          </SuperUser>
          {cancel}
        </div>
      </form>
    </FormContext.Provider>
  );

  const [customizationSection, setCustomizationSection] = useState(
    event.isforfileno
      ? 'filesNo'
      : event.isforalltitles
        ? 'allMembers'
        : event.isforgroup
          ? 'group'
          : 'titles'
  );

  useState(() => {
    if (event.id) {
      if (event.isfortitle) {
        getAllTitlesAction();
        getEventTitlesAction(event.id, paginationEventTitle.limit, paginationEventTitle.skip);
      }
      if (event.isforgroup) {
        getGroupsAction(10, 0);
        getEventGroupsAction(event.id, paginationEventGroup.limit, paginationEventGroup.skip);
      }
      if (event.isforfileno) {
        getEventFileNumbersAction(event.id, paginationEventFileNumber.limit, paginationEventFileNumber.skip);
      }
    }
  }, [
    getAllTitlesAction,
    getEventTitlesAction,
    getEventGroupsAction,
    getEventFileNumbersAction
  ]);

  const [selectTitle, setSelectTitle] = useState();
  const [selectGroup, setSelectGroup] = useState();

  const isTitleAlready = id => {
    for (let i in eventTitles) {
      if (eventTitles[i].title.id === id) {
        return true;
      }
    }
    return false;
  };

  const isGroupAlready = id => {
    for (let i in eventGroups) {
      if (eventGroups[i].id === id) {
        return true;
      }
    }
    return false;
  };

  const isFileNumberAlready = id => {
    for (let i in eventFileNumbers) {
      if (eventFileNumbers[i].member.id === id) {
        return true;
      }
    }
    return false;
  };

  const customization = (
    <>
      <div>
        <Row style={{margin: '10px 0px 10px'}}>
          <h5>Attendees</h5>
        </Row>
        <div style={sectionStyleC}>
          <Alert color='dark'>
            You can define if this event is open to all members, or specific groups.
          </Alert>
          <FormGroup style={{marginBottom: '5px'}}>
            <CustomInput
              type='radio'
              checked={customizationSection === 'allMembers'}
              name='radioCustomization'
              id='idRadioCustomization1'
              onChange={e => {
                setCustomizationSection('allMembers');
                setIsForFileNo(false);
                setIsForGroup(false);
                setIsForTitles(false);
                setIsForAllTitles(true);
              }}
              label='All Members'
            />
          </FormGroup>
          <FormGroup style={{marginBottom: '5px'}}>
            <CustomInput
              type='radio'
              checked={customizationSection === 'titles'}
              name='radioCustomization'
              id='idRadioCustomization2'
              onChange={e => {
                setCustomizationSection('titles');
                setIsForFileNo(false);
                setIsForGroup(false);
                setIsForTitles(true);
                setIsForAllTitles(false);
                getAllTitlesAction();
                getEventTitlesAction(event.id, paginationEventTitle.limit, paginationEventTitle.skip);
              }}
              label='Select Titles'
            />
            {customizationSection === 'titles' ? (
              <div style={{margin: '10px 0px'}}>
                <FormGroup row style={{marginBottom: '5px'}}>
                  <Col sm={10} style={{marginTop: '5px'}}>
                    <Select
                      styles={customStyles}
                      options={titles
                        .filter(value => !isTitleAlready(value.id))
                        .map(value => ({
                          label: value.description,
                          value: value.id
                        }))}
                      value={selectTitle}
                      onChange={(opt, e) => {
                        setSelectTitle(opt);
                      }}
                    />
                  </Col>
                  <Col sm={2} style={{marginTop: '5px'}}>
                    <SuperUser>
                      <Button
                        style={{width: '100%'}}
                        color='primary'
                        disabled={!selectTitle}
                        outline
                        onClick={() => {
                          addEventTitleAction(event.id, selectTitle.value, paginationEventTitle, () => {
                            // setSelectTitle({ label: '', value: '' });
                            setSelectTitle('')
                          });
                        }}
                      >
                        Add
                      </Button>
                    </SuperUser>
                  </Col>
                </FormGroup>
                <div>
                  {eventTitles.length > 0 && (

                    <ListGroup>
                      <Row>
                        <h5 className='ml-2 mt-2' style={{color: 'gray', width: '45%'}}>Title List</h5>
                        <div
                          style={{color: 'gray', padding: '20px 6px 0 0', height: '38px', width: '50%', textAlign: 'right', fontSize: '12px'}}
                        >
                          {paginationEventTitle.count > eventTitles.length &&
                            paginationEventTitle.skip + 1 + ' to '}
                          {paginationEventTitle.skip + eventTitles.length}
                          {paginationEventTitle.count > eventTitles.length &&
                            ' of ' + paginationEventTitle.count}
                          &nbsp;{(eventTitles.length === 1 && paginationEventTitle.skip === 0) ? 'record' : 'records'}
                        </div>
                      </Row>
                      {eventTitles.map((value, index) => (
                        <ListGroupItem key={'event-title-item-' + index}>
                          {value.title.description}
                          <SuperUser>
                            <FontAwesomeIcon
                              className='float-right'
                              style={{
                                color: 'rgba(27,31,35,.3)',
                                cursor: 'pointer',
                                marginTop: '3px'
                              }}
                              onClick={async () => {
                                const result = await confirm({
                                  title: "Remove",
                                  message:
                                    'Are you sure that you want to remove this title from the list?',
                                  confirmText: 'Yes',
                                  cancelText: 'No'
                                });
                                if (result) {
                                  deleteEventTitleAction(value.id, event.id);
                                }
                              }}
                              icon={faTimes}
                            />
                          </SuperUser>
                        </ListGroupItem>

                      ))}

                    </ListGroup>

                  )}
                  {eventTitles.length > 0 && paginationEventTitle.limit < paginationEventTitle.count && (
                    <MyPagination
                      count={paginationEventTitle.count}
                      limit={paginationEventTitle.limit}
                      skip={paginationEventTitle.skip}
                      action={skip => {
                        getEventTitlesAction(event.id, paginationEventTitle.limit, skip);
                      }}
                    />
                  )}

                </div>
              </div>
            ) : (
              ''
            )}
          </FormGroup>
          <FormGroup style={{marginBottom: '5px'}}>
            <CustomInput
              type='radio'
              checked={customizationSection === 'group'}
              name='radioCustomization'
              id='idRadioCustomization3'
              onChange={e => {
                setCustomizationSection('group');
                setIsForFileNo(false);
                setIsForGroup(true);
                setIsForTitles(false);
                setIsForAllTitles(false);
                getGroupsAction(1000, 0);
                getEventGroupsAction(event.id, paginationEventGroup.limit, paginationEventGroup.skip);
              }}
              label='Select Groups'
            />
            {customizationSection === 'group' ? (
              <div style={{margin: '10px 0px'}}>
                <FormGroup row style={{marginBottom: '5px'}}>
                  <Col sm={10} style={{marginTop: '5px'}}>
                    <Select
                      styles={customStyles}

                      options={groups
                        .filter(value => !isGroupAlready(value.id))
                        .map(value => ({
                          label: value.name,
                          value: value.id
                        }))}
                      value={selectGroup}
                      onChange={(opt, e) => {
                        setSelectGroup(opt);
                      }}
                    />
                  </Col>
                  <Col sm={2} style={{marginTop: '5px'}}>
                    <SuperUser>
                      <Button
                        style={{width: '100%'}}
                        disabled={!selectGroup}
                        color='primary'
                        outline
                        onClick={() => {
                          addEventGroupAction(event.id, selectGroup.value, paginationEventGroup, () => {
                            //setSelectGroup({ label: '', value: '' });
                            setSelectGroup('');
                          });
                        }}
                      >
                        Add
                      </Button>
                    </SuperUser>
                  </Col>
                </FormGroup>
                <div>
                  {eventGroups.length > 0 && (
                    <ListGroup>
                      <Row>
                        <h5 className='ml-2 mt-2' style={{color: 'gray', width: '45%'}}>Group List</h5>
                        <div
                          style={{color: 'gray', padding: '20px 6px 0 0', height: '38px', width: '50%', textAlign: 'right', fontSize: '12px'}}
                        >
                          {paginationEventGroup.count > eventGroups.length &&
                            paginationEventGroup.skip + 1 + ' to '}
                          {paginationEventGroup.skip + eventGroups.length}
                          {paginationEventGroup.count > eventGroups.length &&
                            ' of ' + paginationEventGroup.count}
                          &nbsp;{(eventGroups.length === 1 && paginationEventGroup.skip === 0) ? 'record' : 'records'}
                        </div>
                      </Row>
                      {eventGroups.map((value, index) => (
                        <ListGroupItem key={'event-group-item-' + index}>
                          {value.name}
                          <SuperUser>
                            <FontAwesomeIcon
                              className='float-right'
                              style={{
                                color: 'rgba(27,31,35,.3)',
                                cursor: 'pointer',
                                marginTop: '3px'
                              }}
                              onClick={async () => {
                                const result = await confirm({
                                  title: "Remove",
                                  message:
                                    'Are you sure that you want to remove this group from the list?',
                                  confirmText: 'Yes',
                                  cancelText: 'No'
                                });
                                if (result) {
                                  deleteEventGroupAction(event.id, value.id);
                                }
                              }}
                              icon={faTimes}
                            />
                          </SuperUser>
                        </ListGroupItem>
                      ))}
                    </ListGroup>)}
                  {eventGroups.length > 0 && paginationEventGroup.limit < paginationEventGroup.count && (
                    <MyPagination
                      count={paginationEventGroup.count}
                      limit={paginationEventGroup.limit}
                      skip={paginationEventGroup.skip}
                      action={skip => {
                        getEventGroupsAction(event.id, paginationEventGroup.limit, skip);
                      }}
                    />
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
          </FormGroup>

        </div>

      </div>
      <div>
        <Modal isOpen={modalFileNo} toggle={registerFileNoModal}>
          <ModalHeader className='card-header' toggle={registerFileNoModal}>
            Customize Event by File No.
          </ModalHeader>
          <ModalBody>
            <FormGroup className='col-12'>
              <Row>
                <Label>Search for Member (File No.)</Label>
                <div className='col-12 col-sm-9'>
                  <Select
                    options={members
                      .filter(value => !isFileNumberAlready(value.id))
                      .map(value => ({
                        label: value.lastname,
                        value: value
                      }))}
                    value={member}
                    onChange={(opt, e) => {
                      setMember(opt);
                    }}
                  />
                </div>
              </Row>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <SuperUser>
              <Button
                color='primary'
                disabled={!member}
                onClick={() => {
                  addEventFileNumberAction(event.id, member.value.id);
                  registerFileNoModal();
                  setMember('');
                }}
              >
                Add
              </Button>{' '}
              <Label>&nbsp;or&nbsp;</Label>
            </SuperUser>
            <span
              style={{cursor: 'pointer', color: '#17a2b8', marginBottom: '5px'}}
              onClick={() => {
                registerFileNoModal();
                setMember('')
              }}
            >
              Cancel
            </span>
          </ModalFooter>
        </Modal>
      </div>

      <div>
        <Modal isOpen={modalImportFileNo} toggle={importFileNoModal}>
          <ModalHeader className='card-header' toggle={importFileNoModal}>
            Import to File Number.
          </ModalHeader>
          <ModalBody>
            <FormGroup className='col-12'>
              <Row>
                <input type='file' onChange={(e) => {

                  if (e.target.files[0]) {
                    setUploadExcel(e.target.files[0]);
                  }
                }}/>
              </Row>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <SuperUser>
              <Button
                color='success'
                onClick={() => {
                  importFileNumbersExcelAction(event.id, uploadExcel);
                  importFileNoModal();
                }}
              >
                Import
              </Button>{' '}
            </SuperUser>
            <Button
              color='secondary'
              onClick={() => {
                importFileNoModal();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );

  return (
    <div style={{padding: '20px 0px'}}>
      <Row style={{margin: '0px 0px 10px'}}>
        <span
          style={{cursor: 'pointer', color: '#17a2b8', fontSize: '12px'}}
          onClick={() => {
            history.push({...location, hash: ""});
            toggleForm();
          }}
        >
          Events >&nbsp;
        </span>
        <span style={{color: 'rgb(108, 117, 125)', fontSize: '12px'}}>
          {isCreate ? 'New Event' : isClone ? 'Clone Event' : 'Update Event'}
        </span>
      </Row>
      {isCreate ? (
        <Row>{form}</Row>
      ) : (
        <Row>
          {form}
          <div className='col-md-6 col-12'>
            <ReadOnly>
              <InstructorList/>
              {customization}
            </ReadOnly>
          </div>
          <MemberReminderList className='col-12'/>
          <MemberRegistrationList className='col-12'/>
          <MemberWaitingList className='col-12'/>
        </Row>
      )}
    </div>
  );
};
const mapStateToProps = ({
                           isFetching,
                           titles,
                           getEventGroups,
                           getEventTitles,
                           CRUDEvent,
                           event,
                           getWorkShops,
                           getGroups,
                           getLocations,
                           userRetiree,
                           workshop,
                           location,
                           getMembers,
                           getEventFileNumbers,
                           paginationEventFileNumber,
                           paginationEventTitle,
                           paginationEventGroup
                         }) => ({
  titles,
  isFetching,
  workShops: getWorkShops,
  locations: getLocations,
  CRUDEvent,
  event,
  user: userRetiree,
  workshop,
  location,
  eventTitles: getEventTitles,
  groups: getGroups,
  eventGroups: getEventGroups,
  members: getMembers,
  eventFileNumbers: getEventFileNumbers,
  paginationEventFileNumber,
  paginationEventTitle,
  paginationEventGroup
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    getWorkShopsAction,
    getWorkShopAction,
    addEventAction,
    getLocationsAction,
    getLocationAction,
    getGroupsAction,
    updateEventAction,
    cloneEventAction,
    addEventGroupAction,
    addEventFileNumberAction,
    addEventFileNoAction,
    getEventGroupsAction,
    getEventFileNumbersAction,
    getFileNumberExcelAction,
    importFileNumbersExcelAction,
    toggleForm: () => {
      cancelGetEventsActionFn(false);
      dispatch(myDispatch(types.TOGGLE_EVENT_FROM));
      dispatch(myDispatch(types.RESET_EVENT));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(EventsForm);
