import React from 'react';
import FormHook from '../../util/FormHook/FormHook';
import {Button, Col, FormGroup, Label, Row} from 'reactstrap';
import {addWorkShopAction, cancelGetWorkShopsActionFn, cloneWorkShopAction, updateWorkShopAction} from '../../actions/workshop';
import {connect} from 'react-redux';
import * as types from '../../types';
import {myDispatch, registerDispatch} from '../../actions/util';
import { useHistory, useLocation } from "react-router-dom";

const WorkshopForm = ({
                        CRUDWorkshop,
                        workshop,
                        isFetching,
                        addWorkShopAction,
                        updateWorkShopAction,
                        cloneWorkShopAction,
                        toggleForm
                      }) => {

  let history = useHistory();
  let location = useLocation();

  const sectionStyle = {
    border: '1px solid #e9e9e9',
    borderRadius: '6px',
    padding: '.75rem .75rem 0',
    marginBottom: '5px'
  };
  const actionsStyle = {
    margin: '15px 0',
    padding: '10px 0',
    width: '100%',
    borderTop: '1px solid #e9e9e9'
  };

  return (
    <div style={{padding: '20px 0px'}}>

      <FormHook
        defaultValues={workshop}
        submit={values => {
          cancelGetWorkShopsActionFn(false);
          if (CRUDWorkshop === types.CREATE_WORKSHOP) {
            addWorkShopAction(values.title, values.detail);
          } else if (CRUDWorkshop === types.UPDATE_WORKSHOP) {
            updateWorkShopAction(values);
          } else if (CRUDWorkshop === types.CLONE_WORKSHOP) {
            cloneWorkShopAction(values.title, values.detail, workshop.id);
          }
        }}
      >
        <div>
          <Row style={{margin: '0px 0px 10px'}}>
            <span
              style={{cursor: 'pointer', color: '#17a2b8', fontSize: '12px'}}
              onClick={() => {
                history.push({...location, hash: ""});
                toggleForm();
              }}
            >
              Workshops >&nbsp;
            </span>
            <span style={{color: 'rgb(108, 117, 125)', fontSize: '12px'}}>
              {CRUDWorkshop === types.CREATE_WORKSHOP
                ? 'New Workshop'
                : CRUDWorkshop === types.CLONE_WORKSHOP
                  ? 'Clone Workshop'
                  : 'Update Workshop'}
            </span>
          </Row>
          <Row style={{marginLeft: '0px'}}>
            <h5>
              {CRUDWorkshop === types.CREATE_WORKSHOP
                ? 'New'
                : CRUDWorkshop === types.CLONE_WORKSHOP
                  ? 'Clone'
                  : 'Update'}{' '}
              Workshop
            </h5>
          </Row>
          <div style={{maxWidth: '800px', marginTop: '10px'}}>
            {CRUDWorkshop === types.UPDATE_WORKSHOP && workshop.id && (
              <input type='hidden' name='id'/>
            )}
            <div style={sectionStyle}>
              <FormGroup row>
                <Label sm={2} style={{marginTop: '17px'}}>
                  Title
                </Label>
                <Col sm={10}>
                  <input id='title' name='title' required maxLength={100}/>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={2} style={{marginTop: '17px'}}>
                  Detail
                </Label>
                <Col sm={10}>
                  <textarea name='detail' required maxLength={1000}/>
                </Col>
              </FormGroup>
            </div>
          </div>
          <div style={actionsStyle}>
            <Button color='primary'>
              {CRUDWorkshop === types.CREATE_WORKSHOP
                ? 'Save'
                : CRUDWorkshop === types.CLONE_WORKSHOP
                  ? 'Clone'
                  : 'Update'}
              {isFetching ? '...' : ''}
            </Button>
            <Label>&nbsp;or&nbsp;</Label>
            <span
              style={{cursor: 'pointer', color: '#17a2b8'}}
              onClick={() => {
                history.push({...location, hash: ""});
                toggleForm();
              }}
            >
              Cancel
            </span>
          </div>
        </div>
      </FormHook>
    </div>
  );
};

const mapStateToProps = ({isFetching, CRUDWorkshop, workshop}) => ({
  isFetching,
  CRUDWorkshop,
  workshop
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    addWorkShopAction,
    updateWorkShopAction,
    cloneWorkShopAction,
    toggleForm: () => {
      cancelGetWorkShopsActionFn(false);
      dispatch(myDispatch(types.TOGGLE_WORKSHOP_FROM));
      dispatch(myDispatch(types.RESET_WORKSHOP));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(WorkshopForm);
