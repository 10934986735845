import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faTrash} from '@fortawesome/free-solid-svg-icons';
import {myDispatch, registerDispatch} from '../../actions/util';
import {connect} from 'react-redux';
import * as types from '../../types';
import {deleteLocationAction} from '../../actions/location';
import {MyTooltip, SuperUser} from '../index';
import confirm from 'reactstrap-confirm';
import { useHistory } from "react-router-dom";

const LocationItems = ({
                         location,
                         index,
                         updateLocation,
                         deleteLocationAction,
                         setIsActive
                       }) => {

  let history = useHistory();

  return(
    <tr>
      <td id={'location' + index} className='cursor-pointer'>
        {location.name}
      </td>
      <td id={'location' + index} className='cursor-pointer'>
        {location.address}
      </td>
      <SuperUser>
        <td className='text-right'>
          {/* <Button
        size='sm'
        outline
        color='primary'
        onClick={() => {
          updateLocation(location);
          setIsActive(false);
        }}
        className='mr-2'
        id={'tooltip-location-edit' + index}
      > */}
          <FontAwesomeIcon
            icon={faEdit}
            style={{color: 'rgba(27,31,35,.3)', cursor: 'pointer'}}
            onClick={() => {
              history.push({...location, hash: "edit-location"});
              //cancelGetLocationsActionFn(true);
              updateLocation(location);
              setIsActive(false);
            }}
            className='mr-2'
            id={'tooltip-location-edit' + index}
          />
          {/* </Button> */}
          {''}
          <MyTooltip placement='top' target={'tooltip-location-edit' + index}>
            Edit
          </MyTooltip>
          <MyTooltip placement='top' target={'tooltip-location-delete' + index}>
            Delete
          </MyTooltip>
          {/* <UncontrolledDropdown style={{ display: 'inline' }}>
        <DropdownToggle
          id={'tooltip-location-delete' + index}
          caret
          size='sm'
          outline
          color='danger'
        >
          <FontAwesomeIcon icon={faTrash} />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            className='text-danger cursor-pointer'
            onClick={() => {
              deleteLocationAction(location.id);
            }}
          >
            Confirm
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown> */}
          <FontAwesomeIcon
            className='mr-0'
            style={{color: 'rgba(27,31,35,.3)', cursor: 'pointer'}}
            onClick={async () => {
              const result = await confirm({
                title: "Delete",
                message: 'Are you sure that you want to delete this location?',
                confirmText: 'Yes',
                cancelText: 'No'
              });
              if (result) {
                deleteLocationAction(location.id);
              }
            }}
            id={'tooltip-location-delete' + index}
            icon={faTrash}
          />
        </td>
      </SuperUser>
    </tr>
  );
}

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    updateLocation: location => {
      dispatch(myDispatch(types.ADD_LOCATION, {data: location}));
      dispatch(myDispatch(types.UPDATE_LOCATION));
    },

    deleteLocationAction
  };
};

export default connect(
  undefined,
  mapDispatchProps
)(LocationItems);
