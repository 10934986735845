import React, {useState} from 'react';
import {Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import {EventMemberViewListLast, EventsMemberViewList} from '../index';
import {connect} from 'react-redux';
import {myDispatch, registerDispatch} from '../../actions/util';
import * as types from '../../types';

const EventMemberContainer = ({setEventMemberView}) => {
  const [activeTab, setActiveTab] = useState('workshop');
  const [isLastWorkshopActive, setIsLastWorkshopActive] = useState(true);
  const [isWSActive, setIsWSActive] = useState(true);
  return (
    <div className='mt-3'>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={
              'cursor-pointer ' + (activeTab === 'lastWorkshop' ? 'active' : '')
            }
            onClick={() => {
              if (activeTab !== 'lastWorkshop') {
                setEventMemberView();
                setActiveTab('lastWorkshop');
                setIsLastWorkshopActive(true);
              }

            }}
          >
            My Workshops
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={
              'cursor-pointer ' + (activeTab === 'workshop' ? 'active' : '')
            }
            onClick={() => {
              if (activeTab !== 'workshop') {
                setEventMemberView();
                setActiveTab('workshop');
                setIsWSActive(true);
              }
            }}
          >
            Upcoming Workshops
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId='workshop'>
          {activeTab === "workshop" && <EventsMemberViewList
            isActive={isWSActive}
            setIsActive={setIsWSActive}

          />}
        </TabPane>
        <TabPane tabId='lastWorkshop'>
          {activeTab === "lastWorkshop" && <EventMemberViewListLast
            isActive={isLastWorkshopActive}
            setIsActive={setIsLastWorkshopActive}
          />}
        </TabPane>
      </TabContent>
    </div>
  );
};

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    setEventMemberView: () => {
      dispatch(myDispatch(types.GET_EVENTMEMBERVIEW_FAILURE));
    }
  };
};

export default connect(
  undefined,
  mapDispatchProps
)(EventMemberContainer);

