import React from 'react';
import {Button, Col, Label, Row, UncontrolledCollapse} from 'reactstrap';
import {getDateValue1, registerDispatch} from '../../actions/util';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileArchive} from '@fortawesome/free-solid-svg-icons';
import {connect} from 'react-redux';
import {getCertificationPDFByUserAction} from '../../actions/member'


const EventsMemberViewLast = ({index, event, getCertificationPDFByUserAction}) => {

  let eventlist = []
  eventlist.push(event.id)

  const filterStyle = {
    border: '1px dashed #e9e9e9',
    borderRadius: '6px',
    padding: '.75rem .75rem 0',
    marginBottom: '5px',
    marginTop: '5px',
    backgroundColor: '#fafafa'
  };
  return (
    <UncontrolledCollapse toggler={"#toggler-event-member-" + index}>
      <div style={filterStyle}>
        <Row>
          <h5 className='col-8'>Event Details</h5>
          {event.isAttended &&

          <div className='col-4 text-right'>
            <Button
              color='link'
              style={
                {color: '#28a745'}
              }
              onClick={() => {
                getCertificationPDFByUserAction(event.id);
              }}
            >
              <FontAwesomeIcon icon={faFileArchive}/>
              <span style={{marginLeft: '5px'}}>Certificate download </span>
            </Button>
          </div>}
          <Col xs="6">
            <div>Workshop:<Label className='ml-2' style={{color: 'gray', fontWeight: 'normal'}}>{event.workshop.title}</Label></div>
            <div>Description:<Label className='ml-2' style={{textAlign: 'justify', color: 'gray', fontWeight: 'normal'}}>{event.workshop.detail}</Label></div>
            <div>Credit Hours:<Label className='ml-2' style={{color: 'gray', fontWeight: 'normal'}}>  {event.credithours}</Label></div>
            {/*     <div>Seats Available:<Label className='ml-2' style={{color: 'gray', fontWeight: 'normal'}}> {event.capacity} </Label></div> */}
          </Col>
          <Col xs="6">
            <div>Start Date: <Label className='ml-2' style={{color: 'gray', fontWeight: 'normal'}}> {getDateValue1(event.startdate) + " at " + event.starttime}</Label></div>
            <div>End Date: <Label className='ml-2' style={{color: 'gray', fontWeight: 'normal'}}> {getDateValue1(event.enddate) + " at " + event.endtime}</Label></div>
            <div>Address: <Label className='ml-2' style={{color: 'gray', fontWeight: 'normal'}}> {event.eventLocation.address}</Label></div>
            {event.instructors.length > 0 && <div>Instructor:
              <div>
                {event.instructors.map((e, i) => {
                  return (
                    <div key={'expertise-instructor-' + i} style={{color: 'gray', fontWeight: 'normal'}}> {e.name + ' ' + e.lastName}
                    </div>
                  );
                })}
              </div>
            </div>}
          </Col>
        </Row>
      </div>
    </UncontrolledCollapse>
  );
};
const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    getCertificationPDFByUserAction
  };
};
export default connect(undefined, mapDispatchProps)(EventsMemberViewLast);