import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClone, faEdit, faTrash} from '@fortawesome/free-solid-svg-icons';
import {myDispatch, registerDispatch} from '../../actions/util';
import {connect} from 'react-redux';
import * as types from '../../types';
import {addRatingWorkShopAction, cloneWorkShopAction, deleteWorkShopAction, getWorkShopAction, getWorkShopsVersionAction} from '../../actions/workshop';
import {MyTooltip, SuperUser} from '../index';
import StarRatings from 'react-star-ratings';
import confirm from 'reactstrap-confirm';
import { useHistory, useLocation } from "react-router-dom";

const WorkshopsItems = ({
                          workshop,
                          workshops,
                          user,
                          isRetiree,
                          index,
                          updateWorkshop,
                          deleteWorkShopAction,
                          cloneWorkshop,
                          addRatingWorkShopAction,
                          setIsActive,
                          paginationWorkShop,
                          filter,
                          toggleModal,
                          setNextWorkshopId
                          //toggleWorkshopVersionView
                        }) => {

  let history = useHistory();
  let location = useLocation();

  /* const onClickTd = () => {
       getWorkShopsVersionAction(workshop.id);
      // toggleWorkshopVersionView();
     };*/

  return (
    <tr>
      <td style={{width: '300px'}} id={'toggler-workshop' + workshop.id} className='cursor-pointer'>
        {workshop.title} <br/>
        {!!workshop.parentId && <span
          className="simulate-a"
          onClick={() => {
            getWorkShopAction(workshop.parentId, () => {
              setNextWorkshopId([])
              toggleModal(types.MODAL_WORKSHOP_VERSION)
            })
          }}
        >
              Previous Version
            </span>}
      </td>
      <td id={'toggler-workshop' + workshop.id} style={{textAlign: 'justify'}} className='cursor-pointer'>
        {workshop.detail}
      </td>
      {!isRetiree &&
      <td style={{width: '160px'}}>
        <StarRatings
          rating={workshop.rating}
          starRatedColor='blue'
          changeRating={value => {
            addRatingWorkShopAction(workshop.id, value, paginationWorkShop, filter);
          }}
          starDimension='20px'
          starSpacing='3px'
          numberOfStars={5}
          name='rating'
        />
      </td>
      }
      <SuperUser>
        <td style={{width: '120px'}} className='text-right'>
          {/* <Button
          size='sm'
          outline
          color='primary'
          onClick={() => {
            updateWorkshop(workshop);
            setIsActive(false);
          }}
          className='mr-2'
          id={'tooltip-workshop-edit' + index}
        > */}
          <FontAwesomeIcon
            className='mr-2'
            style={{color: 'rgba(27,31,35,.3)', cursor: 'pointer'}}
            icon={faEdit}
            id={'tooltip-workshop-edit' + index}
            onClick={() => {
              history.push({...location, hash: "edit-workshop"});
              //cancelGetWorkShopsActionFn(true);
              updateWorkshop(workshop);
              setIsActive(false);
            }}
          />
          {/* </Button> */}
          {/* <Button
          size='sm'
          outline
          color='primary'
          onClick={() => {
            cloneWorkshop(workshop);
            setIsActive(false);
          }}
          className='mr-2'
          id={'tooltip-workshop-clone' + index}
        > */}
          <FontAwesomeIcon
            className='mr-2'
            style={{color: 'rgba(27,31,35,.3)', cursor: 'pointer'}}
            onClick={() => {
              history.push({...location, hash: "clone-workshop"});
              cloneWorkshop(workshop);
              setIsActive(false);
            }}
            id={'tooltip-workshop-clone' + index}
            icon={faClone}
          />
          <FontAwesomeIcon
            className='mr-0'
            style={{color: 'rgba(27,31,35,.3)', cursor: 'pointer'}}
            onClick={async () => {
              const result = await confirm({
                title: "Delete",
                message: 'Are you sure that you want to delete this workshop?',
                confirmText: 'Yes',
                cancelText: 'No'
              });
              if (result) {
                deleteWorkShopAction(workshop.id);
              }
            }}
            id={'tooltip-workshop-delete' + index}
            icon={faTrash}
          />
          {/* </Button> */}
          <MyTooltip placement='top' target={'tooltip-workshop-clone' + index}>
            New Version
          </MyTooltip>
          <MyTooltip placement='top' target={'tooltip-workshop-edit' + index}>
            Edit
          </MyTooltip>
          <MyTooltip placement='top' target={'tooltip-workshop-delete' + index}>
            Delete
          </MyTooltip>
          {/* <UncontrolledDropdown style={{ display: 'inline' }}>
          <DropdownToggle
            id={'tooltip-workshop-delete' + index}
            caret
            size='sm'
            outline
            color='danger'
          >
            <FontAwesomeIcon icon={faEraser} />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              className='text-danger cursor-pointer'
              onClick={() => {
                deleteWorkShopAction(workshop.id);
              }}
            >
              Confirm
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown> */}
        </td>
      </SuperUser>
    </tr>
  );
};

const mapStateToProps = ({isFetching, user, isRetiree}) => ({isFetching, user: user, isRetiree});
const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    updateWorkshop: workshop => {
      dispatch(myDispatch(types.ADD_WORKSHOP, {data: workshop}));
      dispatch(myDispatch(types.UPDATE_WORKSHOP));
    },
    cloneWorkshop: workshop => {
      dispatch(myDispatch(types.ADD_WORKSHOP, {data: workshop}));
      dispatch(myDispatch(types.CLONE_WORKSHOP));
    },
    toggleModal: modalName => {
      dispatch(myDispatch(types.TOGGLE_MODAL, {data: modalName}));
    },
    deleteWorkShopAction,
    cloneWorkShopAction,
    addRatingWorkShopAction,
    getWorkShopsVersionAction
  };
};
export default connect(
  mapStateToProps,
  mapDispatchProps
)(WorkshopsItems);
