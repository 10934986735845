import React from 'react';
import FormHook from '../../util/FormHook/FormHook';
import {Button, Col, FormGroup, Label, Row} from 'reactstrap';
import {addGroupAction, cancelGetGroupsActionFn, importMembersExcelAction, updateGroupAction} from '../../actions/group';
import {connect} from 'react-redux';
import * as types from '../../types';
import {myDispatch, registerDispatch} from '../../actions/util';
import {GroupMemberList} from '../index';
import { useHistory, useLocation } from "react-router-dom";

const GroupForm = ({
                     CRUDGroup,
                     group,
                     isFetching,
                     addGroupAction,
                     updateGroupAction,
                     importMembersExcelAction,
                     toggleForm
                   }) => {

  let history = useHistory();
  let location = useLocation();

  const sectionStyle = {
    border: '1px solid #e9e9e9',
    borderRadius: '6px',
    padding: '.75rem .75rem 0',
    marginBottom: '5px'
  };
  const actionsStyle = {
    margin: '15px 0',
    padding: '10px 0',
    width: '100%',
    borderTop: '1px solid #e9e9e9'
  };

  return (
    <div style={{padding: '20px 0px'}}>
      <Row style={{margin: '0px 0px 10px'}}>
        <span
          style={{cursor: 'pointer', color: '#17a2b8', fontSize: '12px'}}
          onClick={() => {
            history.push({...location, hash: ""});
            toggleForm();
          }}
        >
          Groups >&nbsp;
        </span>
        <span style={{color: 'rgb(108, 117, 125)', fontSize: '12px'}}>
          {CRUDGroup === types.CREATE_GROUP ? 'New Group' : 'Update Group'}
        </span>
      </Row>

      <Row>
        <FormHook
          className={CRUDGroup === types.UPDATE_GROUP ? 'col-md-6' : 'col-md-8'}
          defaultValues={group}
          submit={values => {
            cancelGetGroupsActionFn(false);
            if (CRUDGroup === types.CREATE_GROUP) {
              addGroupAction(values.name, values.description);
            } else if (CRUDGroup === types.UPDATE_GROUP) {
              let list = group.members.filter((obj, index, self) =>
                index === self.findIndex((o) => o.id === obj.id)
              );
              let listId = [];
              for (let i in list) {
                listId.push(list[i].id);
              }
              updateGroupAction(values, [...new Set(listId)]);
            }
          }}
        >
          <div>
            <Row style={{marginLeft: '0px'}}>
              <h5>
                {CRUDGroup === types.CREATE_GROUP ? 'New' : 'Update'} Group
              </h5>
            </Row>
            <div style={{maxWidth: '800px', marginTop: '10px'}}>
              {CRUDGroup === types.UPDATE_GROUP && group.id && (
                <input type='hidden' name='id'/>
              )}
              <div style={sectionStyle}>
                <FormGroup row>
                  <Label sm={2} style={{marginTop: '17px'}}>
                    Name
                  </Label>
                  <Col sm={10}>
                    <input name='name' required maxLength={100}/>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={2} style={{marginTop: '17px'}}>
                    Description
                  </Label>
                  <Col sm={10}>
                    <textarea name='description' maxLength={1000}/>
                  </Col>
                </FormGroup>
              </div>
            </div>
            <div style={actionsStyle}>
              <Button color='primary'>
                {CRUDGroup === types.CREATE_GROUP ? 'Save' : 'Update'}
                {isFetching ? '...' : ''}
              </Button>
              <Label>&nbsp;or&nbsp;</Label>
              <span
                style={{cursor: 'pointer', color: '#17a2b8'}}
                onClick={() => {
                  history.push({...location, hash: ""});
                  toggleForm();
                }}
              >
                Cancel
              </span>
            </div>
          </div>
        </FormHook>
        {CRUDGroup === types.UPDATE_GROUP && (
          <div className='col-md-6 col-12'>
            <GroupMemberList/>
          </div>

        )}
      </Row>

    </div>


  );
};
const mapStateToProps = ({isFetching, CRUDGroup, group}) => ({
  isFetching,
  CRUDGroup,
  group
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    addGroupAction,
    updateGroupAction,
    importMembersExcelAction,
    toggleForm: () => {
      cancelGetGroupsActionFn(false);
      dispatch(myDispatch(types.TOGGLE_GROUP_FROM));
      dispatch(myDispatch(types.RESET_GROUP));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(GroupForm);
