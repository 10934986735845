import React, {useEffect, useState} from 'react';
import {Button, Col, FormGroup, Label, ListGroup, ListGroupItem, Row} from 'reactstrap';
import {addInstructorGradeLevelsAction, deleteInstructorGradeLevelsAction, getGradeLevelsAction, getGradeLevelsInstructorAction} from '../../actions/instructor';
import {myDispatch, registerDispatch} from '../../actions/util';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {connect} from 'react-redux';
import Select from 'react-select';
import confirm from 'reactstrap-confirm';
import * as types from "../../types";

const InstructorGradeLevelsList = ({
                                     instructor,
                                     gradeLevels,
                                     className,
                                     getGradeLevelsAction,
                                     gradeLevelsInstructors,
                                     getGradeLevelsInstructorAction,
                                     index
                                   }) => {
  const sectionStyle = {
    border: '1px solid #e9e9e9',
    borderRadius: '6px',
    padding: '.75rem .75rem 0',
    marginBottom: '5px',
    marginTop: '10px'
  };
  const customStyles = {
    control: base => ({
      ...base,
      minHeight: 30
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: 4
    }),
    clearIndicator: base => ({
      ...base,
      padding: 4
    }),
    multiValue: base => ({
      ...base
    }),
    valueContainer: base => ({
      ...base,
      padding: '0px 6px'
    }),
    input: base => ({
      ...base,
      margin: 0,
      padding: 0
    })
  };


  useEffect(() => {
    if (instructor.id) {
      getGradeLevelsAction();
      getGradeLevelsInstructorAction(instructor.id);
    }

    return () => {
      myDispatch(types.RESET_INSTRUCTOR);
    }

    // eslint-disable-next-line
  }, [getGradeLevelsAction, getGradeLevelsInstructorAction, instructor]);

  let selectItems = [];

  const isAlready = instructor => {
    for (let i in gradeLevelsInstructors) {
      if (gradeLevelsInstructors[i] && instructor.id === gradeLevelsInstructors[i].id) {
        return true;
      }
    }
    return false;
  };

  if (gradeLevels.length) {
    for (let i in gradeLevels) {
      if (!isAlready(gradeLevels[i])) {
        selectItems.push({
          label: gradeLevels[i].name,
          value: gradeLevels[i]
        });
      }
    }
  }

  const [instructorName, setInstructorName] = useState();
  return (
    <div>
      <Row style={{margin: '0px 0px 10px'}}>
        <h5>Grade Levels</h5>
      </Row>
      <div style={sectionStyle}>
        <FormGroup row>
          <Label sm={3} style={{marginTop: '5px'}}>
            Grade Levels
          </Label>
          <Col sm={7} style={{marginTop: '5px'}} className="select-container">
            <Select
              styles={customStyles}
              options={selectItems}
              value={instructorName}
              onChange={(opt, e) => {
                setInstructorName(opt);
              }}
            />
          </Col>
          <Col sm={2} style={{marginTop: '5px'}}>
            <Button
              style={{width: '100%'}}
              color='primary'
              outline
              disabled={!instructorName}
              onClick={() => {
                addInstructorGradeLevelsAction(instructor.id, instructorName.value.id);
                setInstructorName('');
              }}
            >
              Add
            </Button>
          </Col>
        </FormGroup>
        <div>
          <ListGroup className='mb-2'>
            {gradeLevelsInstructors.map((e, i) => {
              return (
                <ListGroupItem key={'event-instructor-' + i}>
                  {e.name}
                  <FontAwesomeIcon
                    className='float-right'
                    style={{
                      color: 'rgba(27,31,35,.3)',
                      cursor: 'pointer',
                      marginTop: '3px'
                    }}
                    onClick={async () => {
                      const result = await confirm({
                        title: "Remove",
                        message:
                          'Are you sure that you want to remove this grade levels from the list?',
                        confirmText: 'Yes',
                        cancelText: 'No'
                      });
                      if (result) {
                        deleteInstructorGradeLevelsAction(instructor.id, e.id);
                      }
                    }}
                    icon={faTimes}
                  />
                </ListGroupItem>
              );
            })}
          </ListGroup>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({
                           isFetching,
                           instructor,
                           getGradeLevels,
                           getGradeLevelsInstructor
                         }) => ({
  isFetching,
  instructor,
  gradeLevels: getGradeLevels,
  gradeLevelsInstructors: getGradeLevelsInstructor
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    getGradeLevelsAction,
    getGradeLevelsInstructorAction
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(InstructorGradeLevelsList);
