import React from 'react';
import FormHook from '../../util/FormHook/FormHook';
import {Button, Col, FormGroup, Label, Row} from 'reactstrap';
import {addLocationAction, cancelGetLocationsActionFn, updateLocationAction} from '../../actions/location';
import {connect} from 'react-redux';
import * as types from '../../types';
import {myDispatch, registerDispatch} from '../../actions/util';
import { useHistory } from "react-router-dom";

const LocationForm = ({
                        CRUDLocation,
                        location,
                        isFetching,
                        addLocationAction,
                        updateLocationAction,
                        toggleForm
                      }) => {

  let history = useHistory();

  const sectionStyle = {
    border: '1px solid #e9e9e9',
    borderRadius: '6px',
    padding: '.75rem .75rem 0',
    marginBottom: '5px'
  };
  const actionsStyle = {
    margin: '15px 0',
    padding: '10px 0',
    width: '100%',
    borderTop: '1px solid #e9e9e9'
  };
  return (
    <div style={{padding: '20px 0px'}}>
      <FormHook
        defaultValues={location}
        submit={values => {
          cancelGetLocationsActionFn(false);
          if (CRUDLocation === types.CREATE_LOCATION) {
            addLocationAction(values.name, values.address);
          } else if (CRUDLocation === types.UPDATE_LOCATION) {
            updateLocationAction(values);
          }
        }}
      >
        <div>
          <Row style={{margin: '0px 0px 10px'}}>
            <span
              style={{
                cursor: 'pointer',
                color: '#17a2b8',
                fontSize: '12px'
              }}
              onClick={() => {
                history.push({...location, hash: ""});
                toggleForm();
              }}
            >
              Locations >&nbsp;
            </span>
            <span style={{color: 'rgb(108, 117, 125)', fontSize: '12px'}}>
              {CRUDLocation === types.CREATE_LOCATION
                ? 'New Location'
                : 'Update Location'}
            </span>
          </Row>

          <Row style={{marginLeft: '0px'}}>
            <h5>
              {CRUDLocation === types.CREATE_LOCATION ? 'New' : 'Update'}{' '}
              Location
            </h5>
          </Row>
          <div style={{maxWidth: '800px', marginTop: '10px'}}>
            {CRUDLocation === types.UPDATE_LOCATION && location.id && (
              <input type='hidden' name='id'/>
            )}
            <div style={sectionStyle}>
              <FormGroup row>
                <Label sm={2} style={{marginTop: '17px'}}>
                  Name
                </Label>
                <Col sm={10}>
                  <input name='name' required maxLength={100}/>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={2} style={{marginTop: '17px'}}>
                  Address
                </Label>
                <Col sm={10}>
                  <textarea name='address' required maxLength={1000}/>
                </Col>
              </FormGroup>
            </div>
          </div>
          <div style={actionsStyle}>
            <Button color='primary'>
              {CRUDLocation === types.CREATE_LOCATION ? 'Save' : 'Update'}
              {isFetching ? '...' : ''}
            </Button>
            <Label>&nbsp;or&nbsp;</Label>
            <span
              style={{cursor: 'pointer', color: '#17a2b8'}}
              onClick={() => {
                history.push({...location, hash: ""});
                toggleForm();
              }}
            >
              Cancel
            </span>
          </div>
        </div>
      </FormHook>
    </div>
  );
};
const mapStateToProps = ({isFetching, CRUDLocation, location}) => ({
  isFetching,
  CRUDLocation,
  location
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    addLocationAction,
    updateLocationAction,
    toggleForm: () => {
      cancelGetLocationsActionFn(false);
      dispatch(myDispatch(types.TOGGLE_LOCATION_FROM));
      dispatch(myDispatch(types.RESET_LOCATION));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(LocationForm);
