import React from 'react';
import { BrowserRouter as Router, NavLink, Route, Switch, useHistory } from 'react-router-dom';
import routes, { paths } from './routes';
import { Container, Nav, NavItem, Spinner } from 'reactstrap';
import logo from './resources/images/ELI_Logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'input-moment/dist/input-moment.css';
import 'react-datepicker/dist/react-datepicker.css';
import './App.css';
import * as types from './types';
import { myDispatch, registerDispatch } from './actions/util';
import { connect } from 'react-redux';
import { AlertNotification, EventMemberContainer, LoginForm, LoginRetireeForm } from './components/index';
import { removeToken } from "./actions/user";
import { useIdleTimer } from 'react-idle-timer';

function App({
               isLogin,
               user,
               userRetiree,
               isRetiree,
               alertNotification,
               dismissAlertAction,
               isFetching,
               logoutFnAction
             }) {

  let history = useHistory();

  const logoutAction = () => {
    removeToken();
    localStorage.removeItem('retiree');
    logoutFnAction();
    history.push("/");
    window.location.reload()
  };

  useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: () => {
      if (localStorage.getItem("retiree") && process.env.NODE_ENV === "production") {
        logoutAction()
      }
    },
    debounce: 500
  });

  return (
    <Router>
      <div>
        {isFetching && <div className="spinner-container text-center width-100"><Spinner color="primary"/></div>}

        <div className='App mt-2'>
          <Container>
            <div className='float-right'>
              {isLogin && user && (
                <span>
                  Logged as&nbsp;
                  <b>{isRetiree ? userRetiree.userName : user.rol}</b>
                  &nbsp;|&nbsp;
                  <span
                    className='simulate-a'
                    onClick={() => {
                      logoutAction()
                    }}
                  >
                    Logout
                  </span>
                </span>
              )}
            </div>
            <header className='App-header'>
              <img src={logo} className='App-logo' alt='logo' onClick={() => {
                window.location.pathname = "/"
              }}/>
            </header>
          </Container>
          {isLogin &&
          <Nav pills className='App-nav justify-content-center'>
            {isRetiree && <NavItem>
              <NavLink
                to={paths.RegistrationContainer}
                activeClassName='active'
                className='nav-link'
              >
                Registration
              </NavLink>
            </NavItem>}
            {isRetiree && <NavItem>
              <NavLink
                to={paths.UserContainer}
                activeClassName='active'
                className='nav-link'
              >
                Users
              </NavLink>
            </NavItem>}
            {isRetiree && <NavItem>
              <NavLink
                to={paths.ReportView}
                activeClassName='active'
                className='nav-link'
              >
                Report
              </NavLink>
            </NavItem>}
          </Nav>
          }
          {isLogin ? (
            <Container className='custom-container'>
              <Switch>
                {routes.map((route, index) => (
                  isRetiree && route.auth === "retiree" ? <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      render={props =>
                        React.createElement(route.component, {...props})
                      }
                    /> :
                    <Route
                      key={index}
                      path={'/'}
                      exact={true}
                      // render={props => React.createElement(EventsMemberViewList, {...props})}
                      render={props => React.createElement(EventMemberContainer, {...props})}

                    />
                ))}
              </Switch>
            </Container>
          ) : (
            <>
              <Route
                path={'/'}
                exact={true}
                render={props => React.createElement(LoginForm, {...props})}
              />
              <Route
                path={paths.ELIRegistration}
                exact={true}
                render={props => React.createElement(LoginForm, {...props})}
              />
              <Route
                path={paths.LoginRetireeForm}
                exact={true}
                render={props =>
                  React.createElement(LoginRetireeForm, {...props})
                }
              />
            </>
          )}

        </div>

        <div className='alert-notification'>
          {alertNotification.map(alert => (
            <AlertNotification
              color={alert.color}
              key={alert.id}
              id={alert.id}
              message={alert.message}
              dismissAlertAction={dismissAlertAction}
            />
          ))}
        </div>
      </div>

    </Router>
  );
}

const mapStateToProps = ({
                           isLogin,
                           user,
                           isRetiree,
                           userRetiree,
                           alertNotification,
                           isFetching
                         }) => ({
  isLogin,
  user,
  isRetiree,
  alertNotification,
  userRetiree,
  isFetching
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    dismissAlertAction: id => {
      dispatch(myDispatch(types.DISMISS_ALERT_NOTIFICATION, {id}));
    },
    logoutFnAction: () => {
      dispatch(myDispatch(types.LOGIN_FAILURE));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(App);
