import React from 'react';
import {registerDispatch} from '../../actions/util';
import {connect} from 'react-redux';
import {Row} from 'reactstrap';
import moment from "moment";


const MemberReminder = ({
                          event,
                          index
                        }) => {
  const sectionStyle = {
    border: '1px solid #e9e9e9',
    borderRadius: '6px',
    padding: '.75rem .75rem 0',
    marginBottom: '5px',
    width: '100%'
  };


  return (
    <div className='col-12 mt-2 ml-3'>
      <Row>
        <div style={sectionStyle}>
          <h3 className='ml-2' style={{width: '100%'}}>Reminder List</h3>
          {event.eventRemindersSent.map((value, index) => (
            <div key={index + 1} className='col-12 ml-2' style={{padding: '5px'}}> Event reminder sent on <b className='ml-2' style={{color: 'gray'}}>  {moment(value.reminderSent).format('LLL')}  </b></div>
          ))}

        </div>
      </Row>
    </div>
  );
};
const mapStateToProps = ({
                           isFetching,
                           event
                         }) => ({
  isFetching,
  event

});
const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(MemberReminder);
