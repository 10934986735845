import * as types from "../types";

export const user = {
  user: (state = {userName: localStorage.getItem('user'), password: '', rol: localStorage.getItem('rol')}, action) => {
    switch (action.type) {
      case types.GET_USER_SUCCESS:
        return action.data;
      case types.ADD_USER:
        return action.data;
      case types.GET_USER_FAILURE:
        return {};
      default:
        return state
    }
  },
  userRetiree: (state = {userName: localStorage.getItem('userRetiree'), id: localStorage.getItem('userRetireeId'), password: '', rol: localStorage.getItem('rol')}, action) => {
    switch (action.type) {
      case types.GET_RETIREE_USER_SUCCESS:
        return action.data;
      case types.ADD_RETIREE_USER:
        return action.data;
      case types.GET_RETIREE_USER_FAILURE:
        return {};
      default:
        return state
    }
  },
  CRUDUser: (state = types.CREATE_USER, action) => {
    switch (action.type) {
      case types.GET:
        return types.GET;
      case types.CREATE_USER:
        return types.CREATE_USER;
      case types.UPDATE_USER:
        return types.UPDATE_USER;
      case types.DELETE_USER:
        return types.DELETE_USER;
      default:
        return state
    }
  },
  showUserForm: (state = false, action) => {
    switch (action.type) {
      case types.GET:
        return false;
      case types.CREATE_USER:
        return true;
      case types.UPDATE_USER:
        return true;
      case types.GET_USER_SUCCESS:
        return false;
      case types.TOGGLE_USER_FROM:
        return !state;
      default:
        return state
    }
  },

  getUsers: (state = [], action) => {
    switch (action.type) {
      case types.GET_USERS_SUCCESS:
        return action.data;
      case types.GET_USERS_FAILURE:
        return [];
      default:
        return state
    }
  },

  getRoles: (state = [], action) => {
    switch (action.type) {
      case types.GET_ROL_SUCCESS:
        return action.data;
      case types.GET_ROL_FAILURE:
        return [];
      default:
        return state
    }
  },

  getRolesUser: (state = [], action) => {
    switch (action.type) {
      case types.GET_ROL_USER_SUCCESS:
        return action.data;
      case types.GET_ROL_USER_FAILURE:
        return [];
      default:
        return state
    }
  },
  paginationUser: (state = {count: 0, limit: 10, skip: 0, filterName: ""}, action) => {
    switch (action.type) {
      case types.ADD_USER_PAGINATION:
        return {...state, ...action.data};
      case types.RESET_USER_PAGINATION:
        return {};
      default:
        return state
    }
  }
};