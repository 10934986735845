import {client} from '../util/createRestApiClient';

const base = "Instructor/";

export default () => ({
  addInstructor: (params) => client.request(
    {
      method: 'POST',
      url: base + 'AddInstructor',
      data: params
    }
  ),
  getInstructor: (params) => client.request(
    {
      method: 'GET',
      url: base + 'getInstructor?id=' + params.id
    }
  ),
  getInstructors: (limit = 10, skip = 0, filter = '', expertiseId = 0, gradeLevelId = 0, columnSort = '', descending = true) => client.request(
    {
      method: 'GET',
      url: base + 'GetInstructors?limit=' + limit + '&skip=' + skip + '&filterName=' + filter + '&expertiseId=' + expertiseId + '&gradeLevelId=' + gradeLevelId + '&columnSort=' + columnSort + '&descending=' + descending
    }
  ),
  getExpertises: (limit = 10, skip = 0, filter = '', columnSort = '', descending = true) => client.request(
    {
      method: 'GET',
      url: base + 'GetExpertises?limit=' + limit + '&skip=' + skip + '&filterName=' + filter + '&columnSort=' + columnSort + '&descending=' + descending
    }
  ),
  getGradeLevels: (limit = 10, skip = 0, filter = '', columnSort = '', descending = true) => client.request(
    {
      method: 'GET',
      url: base + 'GetGradeLevels?limit=' + limit + '&skip=' + skip + '&filterName=' + filter + '&columnSort=' + columnSort + '&descending=' + descending
    }
  ),
  getExpertisesInstructor: (id) => client.request({
    method: 'GET',
    url: base + 'GetExpertises?instructorId=' + id
  }),
  getGradeLevelsInstructor: (id) => client.request({
    method: 'GET',
    url: base + 'GetGradeLevels?instructorId=' + id
  }),
  updateInstructor: (params, id) => client.request(
    {
      method: 'PUT',
      url: base + 'UpdateInstructor/' + id,
      data: params
    }
  ),
  deleteInstructor: (id) => client.request(
    {
      method: 'DELETE',
      url: base + 'DeleteInstructor/' + id
    }
  ),
  deleteInstructorExpertise: (intructorId, expertiseId) => client.request(
    {
      method: 'DELETE',
      url: base + 'DeleteInstructorExpertise/' + intructorId + "?expertiseId=" + expertiseId
    }
  ), addInstructorExpertise: (id = 0, expertiseId = 0) => client.request(
    {
      method: 'POST',
      url: base + 'AddInstructorExpertise/' + id + "?expertiseId=" + expertiseId
    }
  ),
  deleteInstructorGradeLevels: (intructorId, gradeLevelsId) => client.request(
    {
      method: 'DELETE',
      url: base + 'DeleteInstructorGradeLevels/' + intructorId + "?gradeLevelsId=" + gradeLevelsId
    }
  ), addInstructorGradeLevels: (id = 0, gradeLevelsId = 0) => client.request(
    {
      method: 'POST',
      url: base + 'AddInstructorGradeLevels/' + id + "?gradeLevelsId=" + gradeLevelsId
    }
  ),
  getInstructorsExcel: () => client.request(
    {
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetInstructorsExcel'
    }
  ),
  getInstructorCurriculum: (instructorId) => client.request(
    {
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetInstructorCurriculum/' + instructorId // cambiar metodo
    }
  ),
  uploadInstructorCurriculum: (InstructorId, formData) => client.request(
    {
      method: 'POST',
      url: base + 'AddMembersByFileNumberFromExcel/' + InstructorId, // cambiar metodo
      data: formData

    }
  ),
  getAvatar: (instructorId) => client.request(
    {
      method: 'GET',
      url: base + 'GetAvatar/' + instructorId

    }
  )

});
