import {client} from '../util/createRestApiClient';

const base = "ELIUserManager/";
export default () => ({
  addUser: (params) => client.request(
    {
      method: 'POST',
      url: base + 'AddUser',
      data: params
    }
  ),
  getUser: (params) => client.request(
    {
      method: 'GET',
      url: base + 'getUser/' + params.id
    }
  ),
  getUsers: (limit = 10, skip = 0, filter = '',columnSort='',descending=true) => client.request(
    {
      method: 'GET',
      url: base + 'GetUsers?limit=' + limit + '&skip=' + skip + '&filter=' + filter + '&columnSort=' + columnSort + '&descending=' + descending
    }
  ),
  updateUser: (params, id) => client.request(
    {
      method: 'PUT',
      url: base + 'UpdateUser/' + id,
      data: params
    }
  ),
  deleteUser: (id) => client.request(
    {
      method: 'DELETE',
      url: base + 'DeleteUser/' + id
    }
  ),
  getRolesUser: (id) => client.request(
    {
      method: 'GET',
      url: base + 'GetRoles/' + id
    }
  ),

  getRoles: () => client.request(
    {
      method: 'GET',
      url: base + 'GetRoles/'
    }
  ),

  deleteRoleUser: (userid, rol) => client.request(
    {
      method: 'DELETE',
      url: base + 'RemoveRol/' + userid + "?rol=" + rol
    }
  ),

  addRol: (id, rol) => client.request(
    {
      method: 'POST',
      url: base + 'AddRol/' + id + "?rol=" + rol
    }
  ),
  getInstructors: () => client.request(
    {
      method: 'GET',
      url: base + 'GetInstructors/'
    }
  ),
  getUsersExcel: (filter = '') => client.request(
    {
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetUsersExcel?filter=' + filter
    }
  )

});
