import * as types from "../types";

export const workshop = {
  CRUDWorkshop: (state = types.CREATE_WORKSHOP, action) => {
    switch (action.type) {
      case types.GET:
        return types.GET;
      case types.CREATE_WORKSHOP:
        return types.CREATE_WORKSHOP;
      case types.UPDATE_WORKSHOP:
        return types.UPDATE_WORKSHOP;
      case types.DELETE_WORKSHOP:
        return types.DELETE_WORKSHOP;
      case types.CLONE_WORKSHOP:
        return types.CLONE_WORKSHOP;
      default:
        return state
    }
  },
  showWorkshopForm: (state = false, action) => {
    switch (action.type) {
      case types.GET:
        return false;
      case types.CREATE_WORKSHOP:
        return true;
      case types.UPDATE_WORKSHOP:
        return true;
      case types.CLONE_WORKSHOP:
        return true;
      case types.GET_WORKSHOP_SUCCESS:
        return false;
      case types.TOGGLE_WORKSHOP_FROM:
        return !state;
      default:
        return state
    }
  },
  getWorkShops: (state = [], action) => {
    switch (action.type) {
      case types.GET_WORKSHOP_SUCCESS:
        return action.data;
      case types.GET_WORKSHOP_FAILURE:
        return [];
      default:
        return state
    }
  },
  getWorkShopsVersion: (state = [], action) => {
    switch (action.type) {
      case types.GET_WORKSHOPVERSION_SUCCESS:
        return action.data;
      case types.GET_WORKSHOPVERSION_FAILURE:
        return [];
      default:
        return state
    }
  },

  workshop: (state = {}, action) => {
    switch (action.type) {
      case types.ADD_WORKSHOP:
        return action.data;
      case types.RESET_WORKSHOP:
        return {};
      default:
        return state
    }
  },

  rating: (state = {}, action) => {
    switch (action.type) {
      case types.ADD_RATING:
        return action.data;
      case types.RESET_RATING:
        return {};
      default:
        return state
    }
  },
  paginationWorkShopVersion: (state = {
    count: 0, limit: 10, skip: 0, filterName: ""
  }, action) => {
    switch (action.type) {
      case types.ADD_WORKSHOPVERSION_PAGINATION:
        return {
          ...state,
          ...action.data
        };
      case types.RESET_WORKSHOPVERSION_PAGINATION:
        return {};
      default:
        return state
    }
  },
  paginationWorkShop: (state = {
    count: 0, limit: 10, skip: 0, filterName: ""
  }, action) => {
    switch (action.type) {
      case types.ADD_WORKSHOP_PAGINATION:
        return {
          ...state,
          ...action.data
        };
      case types.RESET_WORKSHOP_PAGINATION:
        return {};
      default:
        return state
    }
  }
};
