
const SuperUser = ({children}) => {
  let isSuperUser = false;

  let rol = localStorage.getItem("rol");

  if (rol && typeof rol === "string" && /SuperUser/.test(rol)) {
    isSuperUser = true
  }

  if (isSuperUser) {
    return children
  }

  return null
};

export default SuperUser