import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faTrash} from '@fortawesome/free-solid-svg-icons';
import {myDispatch, registerDispatch} from '../../actions/util';
import {connect} from 'react-redux';
import * as types from '../../types';
import {deleteInstructorAction} from '../../actions/instructor';
import {MyTooltip, SuperUser} from '../index';
import confirm from 'reactstrap-confirm';
import {instructorService} from "../../services";
import { useHistory, useLocation } from "react-router-dom";

const InstructorItems = ({
                           instructor,
                           index,
                           updateInstructor,
                           deleteInstructorAction,
                           setIsActive,
                           setAvatar,
                           isOpen,
                           setIsOpen

                         }) => {

  let history = useHistory();
  let location = useLocation();

  return (
    <tr>
      <td id={'toggler-instructor-view-' + index} className='cursor-pointer' onClick={() => {
        if (isOpen !== instructor.id) {
          instructorService().getAvatar(instructor.id).then(res => {
            setAvatar("data:image/png;base64, " + res.data);
          }).catch((err) => {
          });
        }
        setIsOpen(isOpen === instructor.id ? "" : instructor.id)
      }}>
        {instructor.name}
      </td>
      <td id={'toggler-instructor-view-' + index} className='cursor-pointer' onClick={() => {
        if (isOpen !== instructor.id) {
          instructorService().getAvatar(instructor.id).then(res => {
            setAvatar("data:image/png;base64, " + res.data);
          }).catch((err) => {
          });
        }
        setIsOpen(isOpen === instructor.id ? "" : instructor.id)
      }}>
        {instructor.lastName}
      </td>
      <SuperUser>
        <td className='text-right'>
          <FontAwesomeIcon
            icon={faEdit}
            style={{color: 'rgba(27,31,35,.3)', cursor: 'pointer'}}
            onClick={() => {
              history.push({...location, hash: "edit-instructor"});
              //cancelGetInstructorsActionFn(true);
              updateInstructor(instructor);
              setIsActive(false);
            }}
            className='mr-2'
            id={'tooltip-instructor-edit' + index}
          />

          {''}
          <MyTooltip placement='top' target={'tooltip-instructor-edit' + index}>
            Edit
          </MyTooltip>
          <MyTooltip placement='top' target={'tooltip-instructor-delete' + index}>
            Delete
          </MyTooltip>
          <MyTooltip placement='top' target={'toggler-instructor-view-' + index}>
            See Detail
          </MyTooltip>
          <FontAwesomeIcon
            className='mr-0'
            style={{color: 'rgba(27,31,35,.3)', cursor: 'pointer'}}
            onClick={async () => {
              const result = await confirm({
                title: "Delete",
                message: 'Are you sure that you want to delete this instructor?',
                confirmText: 'Yes',
                cancelText: 'No'
              });
              if (result) {
                deleteInstructorAction(instructor.id);
              }
            }}
            id={'tooltip-instructor-delete' + index}
            icon={faTrash}
          />
        </td>
      </SuperUser>
    </tr>
  );
};
const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    updateInstructor: instructor => {
      dispatch(myDispatch(types.ADD_INSTRUCTOR, {data: instructor}));
      dispatch(myDispatch(types.UPDATE_INSTRUCTOR));
    },

    deleteInstructorAction
  };
};

export default connect(
  undefined,
  mapDispatchProps
)(InstructorItems);
