import React from 'react';
import {UncontrolledTooltip} from 'reactstrap';

const MyTooltip = (props) => {

  let tmp = {...props, delay: {show: 250, hide: 250}};

  return (
    <UncontrolledTooltip {...tmp}>
      {props.children}
    </UncontrolledTooltip>
  );
};

export default MyTooltip;
