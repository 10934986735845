import * as types from "../types";
import {getUniqueKey} from "../util/FormHook/FormHook";
import {isLogin} from "../actions/user";

export const general = {
  isFetching: (state = false, action) => {
    switch (action.type) {
      case types.FETCHING:
        return true;
      case types.FETCHING_END:
        return false;
      default:
        return state
    }
  },
  isModal: (state = "", action) => {
    switch (action.type) {
      case types.TOGGLE_MODAL:
        return action.data || "";
      case types.CLOSE_MODAL:
        return "";
      default:
        return state
    }
  },
  isLogin: (state = isLogin(), action) => {
    switch (action.type) {
      case types.LOGIN_SUCCESS:
        return true;
      case types.LOGIN_FAILURE:
        return false;
      default:
        return state
    }
  },
  isRetiree: (state = !!(localStorage && localStorage.getItem('retiree')), action) => {
    switch (action.type) {
      case types.RETIREE_LOGIN:
        return true;
      case types.RESET_RETIREE_LOGIN:
        localStorage.removeItem('retiree');
        return false;
      default:
        return state
    }
  },
  alertNotification: (state = [], action) => {
    switch (action.type) {
      case types.ADD_ALERT_NOTIFICATION:
        return [...state, {id: getUniqueKey(), message: action.message, color: action.color}];
      case types.DISMISS_ALERT_NOTIFICATION:
        return state.filter(t => t.id !== action.id);
      case types.RESET_ALERT_NOTIFICATION:
        return [];
      default:
        return state
    }
  },
};