import React, {useState} from 'react';
import {Button, CustomInput, FormGroup, InputGroup, InputGroupAddon, Row, Table} from 'reactstrap';
import {ColumnSort, MyPagination, SearchMemberGroupItems} from '../index';
import {myDispatch, registerDispatch} from '../../actions/util';
import {connect} from 'react-redux';
import {getMembersAction} from '../../actions/member';
import * as types from "../../types";

const SearchMemberGroupList = ({
                                 isFetching,
                                 members,
                                 membersGroup,
                                 paginationSearchMember,
                                 changeSortAction,
                                 memberList,
                                 setMemberList,
                                 setIsActive
                               }) => {
  const filterStyle = {
    border: '1px dashed #e9e9e9',
    borderRadius: '6px',
    padding: '.25rem .25rem 0',
    // marginBottom: '5px',
    backgroundColor: '#fafafa'
  };

  // useEffect(() => {
  // getGroupsAction(10, 0);
  // eslint-disable-next-line
  // }, [getMembersAction]);

  const [filter, setfilter] = useState('');
  const [selectAll, setSelectAll] = useState(false);


  let items = [];

  if (members.length) {
    for (let i in members) {
      items.push(
        <SearchMemberGroupItems
          key={'group-item' + i}
          member={members[i]}
          index={i}
          memberList={memberList}
          setMemberList={setMemberList}
        />
      );
    }
  }

  return (
    <div style={{width: '100%'}}>
      <div style={filterStyle}>
        <Row style={{width: '100%', alignItems: 'center'}}>
          <FormGroup style={{height: '26px'}} className='col-12 col-md-10'>
            <InputGroup>
              <input
                type='text'
                value={filter}
                name='filterName'
                className='form-control col-12'
                placeholder='Search by...'
                onChange={e => {
                  setfilter(e.target.value);
                }}
                onKeyUp={e => {
                  if (e.keyCode === 27) {
                    setfilter('');
                    getMembersAction();
                  }
                  if (e.keyCode === 13) {
                    setfilter(e.target.value);
                    getMembersAction(
                      undefined,
                      undefined,
                      e.target.value
                    );
                  }
                }}
              />
              <InputGroupAddon addonType='append'>
                <Button
                  outline
                  color='primary'
                  onClick={() => {
                    getMembersAction(
                      undefined,
                      undefined,
                      filter
                    );
                  }}
                >
                  Search
                </Button>
                <Button
                  outline
                  color='primary'
                  onClick={() => {
                    setfilter('');
                    getMembersAction();
                  }}
                >
                  Reset
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        </Row>
      </div>
      <div>
        <div>
          <div
            className='float-right'
            style={{padding: '0px 3px 0px', height: '20px'}}
          >
            {paginationSearchMember.count > members.length &&
            paginationSearchMember.skip + 1 + ' to '}
            {paginationSearchMember.skip + members.length}
            {paginationSearchMember.count > members.length &&
            ' of ' + paginationSearchMember.count}
            &nbsp;{members.length === 1 ? 'record' : 'records'}
          </div>
        </div>
        <Table responsive className='col-12'>
          <thead>
          <tr className='card-header'>
            <th><FormGroup style={{height: '6px', paddingLeft: '15px'}}>
              <CustomInput
                type='checkbox'
                id={'viewPastEvents'}
                label={selectAll ? 'UnSelect all' : 'Select all'}
                inline
                onClick={() => {
                  setSelectAll(!selectAll);
                  if (!selectAll) {
                    setMemberList([...members])
                  } else {
                    setMemberList([]);
                  }
                  //getMembersAction(5, 0, filter);
                }}
              />
            </FormGroup></th>
            <th>First Name<ColumnSort columnName="Frstname" columnSort={paginationSearchMember.columnSort} descending={paginationSearchMember.descending} changeSortAction={(object) => {
              changeSortAction({...paginationSearchMember, ...object});
              getMembersAction(paginationSearchMember.limit, paginationSearchMember.skip, paginationSearchMember.filter, object.columnSort, object.descending)
            }}/></th>
            <th>Last Name<ColumnSort columnName="Lastname" columnSort={paginationSearchMember.columnSort} descending={paginationSearchMember.descending} changeSortAction={(object) => {
              changeSortAction({...paginationSearchMember, ...object});
              getMembersAction(paginationSearchMember.limit, paginationSearchMember.skip, paginationSearchMember.filter, object.columnSort, object.descending)
            }}/></th>
            <th className='text-right'>File No. <ColumnSort columnName="Fileno" columnSort={paginationSearchMember.columnSort} descending={paginationSearchMember.descending} changeSortAction={(object) => {
              changeSortAction({...paginationSearchMember, ...object});
              getMembersAction(paginationSearchMember.limit, paginationSearchMember.skip, paginationSearchMember.filter, object.columnSort, object.descending)
            }}/></th>
            <th>Title<ColumnSort columnName="Title" columnSort={paginationSearchMember.columnSort} descending={paginationSearchMember.descending} changeSortAction={(object) => {
              changeSortAction({...paginationSearchMember, ...object});
              getMembersAction(paginationSearchMember.limit, paginationSearchMember.skip, paginationSearchMember.filter, object.columnSort, object.descending)
            }}/></th>
            <th>Site Code <ColumnSort columnName="Sitecode" columnSort={paginationSearchMember.columnSort} descending={paginationSearchMember.descending} changeSortAction={(object) => {
              changeSortAction({...paginationSearchMember, ...object});
              getMembersAction(paginationSearchMember.limit, paginationSearchMember.skip, paginationSearchMember.filter, object.columnSort, object.descending)
            }}/></th>
            <th/>
          </tr>
          </thead>
          <tbody>
          {members.length ? (
            items
          ) : (
            <tr>
              <th colSpan='6' className='text-center'>
                No content data
              </th>
            </tr>
          )}
          </tbody>
        </Table>
        {members.length > 0 && paginationSearchMember.limit < paginationSearchMember.count && (
          <MyPagination
            count={paginationSearchMember.count}
            limit={paginationSearchMember.limit}
            skip={paginationSearchMember.skip}
            action={skip => {
              getMembersAction(paginationSearchMember.limit, skip, filter, paginationSearchMember.columnSort, paginationSearchMember.descending);
            }}
          />
        )}

      </div>
    </div>
  );
};

const mapStateToProps = ({isFetching, getMembers, paginationSearchMember}) => ({
  isFetching,
  members: getMembers,
  paginationSearchMember
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    getMembersAction,
    changeSortAction: (data) => {
      dispatch(myDispatch(types.ADD_MEMBERSEARCH_PAGINATION, {data}));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(SearchMemberGroupList);