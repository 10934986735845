import * as types from "../types";
import {dispatch, getTodayStringValue} from "./util";
import {eventService, memberService, registrationService, waitingService} from "../services";
import {getEventReminderAction, getEventsMemberViewAction} from "../actions/event"
import {saveAs} from "file-saver";
import messages from "../util/messages";

export const getMembersAction = (limit, skip, filter, columnSort, descending) => {
  dispatch(types.FETCHING);
  memberService().getMembers(limit, skip, filter, columnSort, descending).then(res => {
    dispatch(types.GET_MEMBER_SUCCESS, res);
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_MEMBERSEARCH_PAGINATION, {
      data: {
        count: parseInt(res.headers['x-total-count'], 10),
        limit: parseInt(res.headers['x-total-limit'], 10),
        skip: parseInt(res.headers['x-total-skip'], 10),
        filter,
        columnSort,
        descending
      }
    });
    dispatch(types.ADD_GROUP_PAGINATION, {data: {count: parseInt(res.headers['x-total-count'], 10), limit: parseInt(res.headers['x-total-limit'], 10), skip: parseInt(res.headers['x-total-skip'], 10), filter}});
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    console.log(err)
  });
};

export const addMemberRegistrationAction = (eventId, memberId, priority, WalkInMember) => {
  dispatch(types.FETCHING);
  registrationService().addMemberRegistration({
    eventModelId: eventId,
    memberModelId: memberId,
    priority,
    WalkInMember
  }).then(res => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_MEMBER_REGISTRATION_SUCCESS, {
      data: {
        eventId,
        memberId,
        priority
      }
    });
    dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.ADD_MEMBER_REGISTRATION_SUCCESS], color: 'info'});
    dispatch(types.TOGGLE_MEMBER_REGISTRATION_FROM);
    dispatch(types.RESET_MEMBER_REGISTRATION);
    getMembersRegistrationAction(eventId);
    getMembersWaitingAction(eventId);
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_MEMBER_REGISTRATION_FAILURE);
    if (err && err.response && err.response.status === 400) {
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: err && err.response && err.response.data && err.response.data.message ? err.response.data.message : messages[types.ADD_MEMBER_REGISTRATION_FAILURE], color: 'danger'});
    } else {
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.ADD_MEMBER_REGISTRATION_FAILURE], color: 'danger'});
    }
  });
};
export const addMemberByMemberLoginRegistrationAction = (eventId, memberId, priority, WalkInMember, paginationEventMemberView) => {
  dispatch(types.FETCHING);
  registrationService().addMemberRegistration({
    eventModelId: eventId,
    memberModelId: memberId,
    priority,
    WalkInMember
  }).then(res => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_MEMBER_REGISTRATION_SUCCESS, {
      data: {
        eventId,
        memberId,
        priority
      }
    });
    dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.ADD_MEMBER_REGISTRATION_SUCCESS], color: 'info'});
    dispatch(types.TOGGLE_MEMBER_REGISTRATION_FROM);
    dispatch(types.RESET_MEMBER_REGISTRATION);
    getEventsMemberViewAction(paginationEventMemberView.limit, paginationEventMemberView.skip, paginationEventMemberView.filter, paginationEventMemberView.columnSort, paginationEventMemberView.descending)
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_MEMBER_REGISTRATION_FAILURE);
    dispatch(types.ADD_ALERT_NOTIFICATION, {message: err && err.response && err.response.data && err.response.data.message ? err.response.data.message : messages[types.ADD_MEMBER_REGISTRATION_FAILURE], color: 'danger'});

  });
};
export const moveMemberAction = (eventId, memberId, priority, WalkInMember) => {
  dispatch(types.FETCHING);
  registrationService().moveMember({
    eventModelId: eventId,
    memberModelId: memberId,
    priority,
    WalkInMember
  }).then(res => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_MEMBER_REGISTRATION_SUCCESS, {
      data: {
        eventId,
        memberId,
        priority
      }
    });
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'Move member',
      color: 'info'
    });
    dispatch(types.TOGGLE_MEMBER_REGISTRATION_FROM);
    dispatch(types.RESET_MEMBER_REGISTRATION);
    getMembersRegistrationAction(eventId);
    getMembersWaitingAction(eventId);
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_MEMBER_REGISTRATION_FAILURE);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'Move member',
      color: 'danger'
    });
  });
};

export const getMembersRegistrationAction = (eventId, limit, skip, showUnregistered = !!sessionStorage.getItem("showUnregistered"), columnSort = "", descending = false) => {
  dispatch(types.FETCHING);
  registrationService().getMembersRegistration(eventId, limit, skip, showUnregistered, columnSort, descending).then(res => {
    dispatch(types.GET_MEMBER_REGISTRATION_SUCCESS, res);
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_MEMBERREGISTRATION_PAGINATION, {
      data: {
        count: parseInt(res.headers['x-total-count'], 10),
        limit: parseInt(res.headers['x-total-limit'], 10),
        skip: parseInt(res.headers['x-total-skip'], 10),
        columnSort,
        descending
      }
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    console.log(err)
  });
};

export const unRegisterMemberAction = (eventId, registrationId, showUnregistered) => {
  dispatch(types.FETCHING);
  registrationService().unRegisterMember(registrationId).then(res => {
    dispatch(types.FETCHING_END);
    getMembersRegistrationAction(eventId, undefined, undefined, showUnregistered);
    getMembersWaitingAction(eventId);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'Unregister member',
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'Unregister member',
      color: 'danger'
    });
  });
};
export const unRegisterByLoginMemberAction = (eventId, paginationEventMemberView) => {
  dispatch(types.FETCHING);
  registrationService().unRegisterByLogingMember(eventId).then(res => {
    dispatch(types.FETCHING_END);
    getEventsMemberViewAction(paginationEventMemberView.limit, paginationEventMemberView.skip, paginationEventMemberView.filter, paginationEventMemberView.columnSort, paginationEventMemberView.descending);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'Unregister',
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'Unregister',
      color: 'danger'
    });
  });
};
export const unWaitingByLoginMemberAction = (eventId, paginationEventMemberView) => {
  dispatch(types.FETCHING);
  waitingService().unRegisterByLogingMember(eventId).then(res => {
    dispatch(types.FETCHING_END);
    getEventsMemberViewAction(paginationEventMemberView.limit, paginationEventMemberView.skip, paginationEventMemberView.filter, paginationEventMemberView.columnSort, paginationEventMemberView.descending);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'Cancel waiting',
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'Cancel waiting',
      color: 'danger'
    });
  });
};
export const getMembersWaitingAction = (eventId, limit, skip, showWaiting = !sessionStorage.getItem("showNoWaiting"), columnSort = "", descending = true) => {
  dispatch(types.FETCHING);
  waitingService().getWaitingMembers(eventId, limit, skip, showWaiting, columnSort, descending).then(res => {
    dispatch(types.GET_MEMBER_WAITING_SUCCESS, res);
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_MEMBERWAITING_PAGINATION, {
      data: {
        count: parseInt(res.headers['x-total-count'], 10),
        limit: parseInt(res.headers['x-total-limit'], 10),
        skip: parseInt(res.headers['x-total-skip'], 10),
        columnSort,
        descending
      }
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    console.log(err)
  });
};

export const getRegistrationsExcelAction = (eventId, columnSort = "", descending = false) => {
  dispatch(types.FETCHING);
  registrationService().getRegistrationsExcel(eventId, columnSort, descending).then(res => {
    dispatch(types.FETCHING_END);
    saveAs(res.data, "MemberRegistration" + getTodayStringValue() + ".xlsx");
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'File downloaded member registration',
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'File download member registration',
      color: 'danger'
    });
  });
};

export const getRegistrationsPDFAction = (eventId, columnSort = "", descending = false) => {
  dispatch(types.FETCHING);
  registrationService().getRegistrationsPDF(eventId, columnSort, descending).then(res => {
    dispatch(types.FETCHING_END);
    saveAs(res.data, "MemberRegistration" + getTodayStringValue() + ".pdf");
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'File downloaded member registration',
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'File download member registration',
      color: 'danger'
    });
  });
};

export const getWaitingsExcelAction = (eventId) => {
  dispatch(types.FETCHING);
  waitingService().getWaitingsExcel(eventId).then(res => {
    dispatch(types.FETCHING_END);
    saveAs(res.data, "MemberWaiting" + getTodayStringValue() + ".xlsx");
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'File downloaded member waiting list.',
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'File download member waiting list.',
      color: 'danger'
    });
  });
};
export const sendCustomNotificationAction = (eventId, subject, body) => {
  dispatch(types.FETCHING);
  eventService().sendCustomNotification(eventId, subject, body).then(res => {
    dispatch(types.FETCHING_END);
    getEventReminderAction(eventId, 10, 0);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'Send Notification',
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'Send Notification',
      color: 'danger'
    });
    console.log(err)
  });
};

export const sendCertificateAction = (eventId) => {
  dispatch(types.FETCHING);
  registrationService().sendCertificate(eventId).then(res => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'Send Certificate',
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'Send Certificate',
      color: 'danger'
    });

    console.log(err)
  });
};

export const attendAction = (registrationId, option, eventId, paginationMemberRegistration, showUnregistered) => {
  dispatch(types.FETCHING);
  registrationService().attend(registrationId, option).then(res => {
    getMembersRegistrationAction(eventId, paginationMemberRegistration.limit, paginationMemberRegistration.skip, showUnregistered, paginationMemberRegistration.columnSort, paginationMemberRegistration.descending);
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'Attend',
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'Attend',
      color: 'danger'
    });
    console.log(err)
  });
};
export const getCertificationPDFAction = (registrationId) => {
  dispatch(types.FETCHING);
  registrationService().getCertificatePDF(registrationId).then(res => {
    dispatch(types.FETCHING_END);
    saveAs(res.data, "Certificate" + getTodayStringValue() + ".pdf");
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'File downloaded certificate',
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'File download certificate',
      color: 'danger'
    });
  });
};

export const getCertificationPDFByUserAction = (EventId) => {
  dispatch(types.FETCHING);
  registrationService().getCertificatePDFbyUser(EventId).then(res => {
    dispatch(types.FETCHING_END);
    saveAs(res.data, "Certificate" + getTodayStringValue() + ".pdf");
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'File downloaded certificate',
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'File download certificate',
      color: 'danger'
    });
  });
};
