import React from 'react';
import {FormGroup, Label} from 'reactstrap';
import {getDateValue1} from '../../actions/util'
import {ReadOnly} from "../index";

const MemberWaitingItems = ({member, index}) => {
  let nameButton = 'Waiting';
  let textColor = '#6c757d';

  if (member.status !== 3) {
    textColor = '#17a2b8';
  }
  if (member.status === 1) {
    nameButton = 'Moved to Registration';
  } else if (member.status === 2) {
    nameButton = 'Cancelled by User';
  } else if (member.status === 3) {
    nameButton = 'Waiting';
  } else if (member.status === 4) {
    nameButton = 'Cancelled by System';
  } else if (member.status === 5) {
    nameButton = 'Conflict';
  }

  const textStyle = {
    backgroundColor: textColor,
    color: 'white',
    fontWeight: 500,
    padding: '0 5px 2px 5px'
  };
  return (
    <tr>
      <td id={"member" + index} className="cursor-pointer">
        <ReadOnly>
          <span>{member.member.title}</span> {" "}
          <span>{member.member.lastname + ', ' + member.member.frstname}</span> {" "}
          <span>{member.member.status}</span> {" "}
          <span>{member.name}</span> {" "}
        </ReadOnly>
      </td>
      <td id={"member" + index} className="cursor-pointer">
        <ReadOnly>
          {getDateValue1(member.entrydate)}
        </ReadOnly>
      </td>
      <td id={"member" + index} className="cursor-pointer">
        <ReadOnly>
          <FormGroup check>
            <Label check>
              <input disabled="True" checked={member.isadministrative} type="checkbox"/>
            </Label>
          </FormGroup>
        </ReadOnly>
      </td>

      <td>
        <ReadOnly>
          <span id={'tooltip-event-open' + index} style={textStyle}>
            {nameButton}
          </span>
        </ReadOnly>
      </td>
    </tr>

  );
};

export default MemberWaitingItems;
