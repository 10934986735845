import {titleService} from "../services/index";

import * as types from "../types";
import {dispatch} from "./util";
import messages from "../util/messages";

export const getTitleAction = (id) => {
  dispatch(types.FETCHING);
  titleService().getTitle({id})
    .then(res => {
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_TITLE, res);

    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_TITLE, {data: {}});
    });
};

export const getTitleByTitleAction = (title) => {
  dispatch(types.FETCHING);
  titleService().getTitleByTitle(title)
    .then(res => {
      dispatch(types.FETCHING_END);
      dispatch(types.GET_TITLE_SUCCESS, res);
      if (res.data && res.data.id) {
        dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.GET_TITLE_SUCCESS], color: 'info'});
      } else {
        dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.GET_TITLE_FAILURE], color: 'danger'});
      }
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.GET_TITLE_FAILURE], color: 'danger'});
      dispatch(types.GET_TITLE_FAILURE);
    });
};

export const getTitlesAction = (limit, skip, filterName, columnSort, descending) => {
  dispatch(types.FETCHING);
  titleService().getTitles(limit, skip, filterName, columnSort, descending)
    .then(res => {
      dispatch(types.GET_TITLES_SUCCESS, res);
      dispatch(types.ADD_TITLE_PAGINATION, {
        data: {
          count: parseInt(res.headers['x-total-count'], 10),
          limit: parseInt(res.headers['x-total-limit'], 10),
          skip: parseInt(res.headers['x-total-skip'], 10),
          filterName,
          columnSort,
          descending
        }
      });
      dispatch(types.FETCHING_END);
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      console.log(err)
    });
};

export const getAllTitlesAction = (filterName, columnSort, descending) => {
  dispatch(types.FETCHING);
  titleService().getAllTitles(filterName, columnSort, descending)
    .then(res => {
      dispatch(types.GET_TITLES_SUCCESS, res);
      dispatch(types.FETCHING_END);
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      console.log(err)
    });
};

export const addTitleAction = (title) => {
  dispatch(types.FETCHING);
  titleService().addTitle(title)
    .then(res => {
      dispatch(types.ADD_TITLE_SUCCESS);
      getTitlesAction();
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.ADD_TITLE_SUCCESS], color: 'info'});
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.ADD_TITLE_FAILURE], color: 'danger'});
    });
};

export const updateTitleAction = (title) => {
  dispatch(types.FETCHING);
  titleService().updateTitle(title)
    .then(res => {
      dispatch(types.UPDATE_TITLE_SUCCESS);
      getTitlesAction();
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.UPDATE_TITLE_SUCCESS], color: 'info'});
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.UPDATE_TITLE_FAILURE], color: 'danger'});
    });
};

export const deleteTitleAction = (id) => {
  dispatch(types.FETCHING);
  titleService().deleteTitle({id})
    .then(res => {
      getTitlesAction();
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.DELETE_TITLE_SUCCESS], color: 'info'});
    })
    .catch((err) => {
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.DELETE_TITLE_FAILURE], color: 'danger'});
    });
};
