import * as types from "../types";
import {dispatch, getTodayStringValue} from "./util";
import {eventService, workShopService} from "../services";
import messages from "../util/messages";
import {saveAs} from "file-saver";
import {getEventsMemberViewAction} from '../actions/event';


export const addWorkShopAction = (title, detail, parentId) => {
  dispatch(types.FETCHING);
  workShopService().addWorkShop({TITLE: title, DETAIL: detail, parentId: parentId}).then(res => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_WORKSHOP_SUCCESS, {
      data: {
        title,
        detail,
        parentId
      }
    });
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: messages[types.ADD_WORKSHOP_SUCCESS],
      color: 'info'
    });

    dispatch(types.TOGGLE_WORKSHOP_FROM);
    dispatch(types.RESET_WORKSHOP);


  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_WORKSHOP_FAILURE);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: messages[types.ADD_WORKSHOP_FAILURE],
      color: 'danger'
    });
  });
};
export const getWorkShopAction = (id, cb) => {
  dispatch(types.FETCHING);
  workShopService().getWorkShop({id}).then(res => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_WORKSHOP, res);
    cb(res.data);
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_WORKSHOP, {data: {}});
  });
};

let cancelGetWorkShopsAction = false;
export const cancelGetWorkShopsActionFn = (option = false) => {cancelGetWorkShopsAction = option};

export const getWorkShopsAction = (limit, skip, filter, columnSort, descending) => {
  dispatch(types.FETCHING);
  workShopService().getWorkShops(limit, skip, filter, columnSort, descending).then(res => {
    if (!cancelGetWorkShopsAction) {
      dispatch(types.GET_WORKSHOP_SUCCESS, res);
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_WORKSHOP_PAGINATION, {
        data: {
          count: parseInt(res.headers['x-total-count'], 10),
          limit: parseInt(res.headers['x-total-limit'], 10),
          skip: parseInt(res.headers['x-total-skip'], 10),
          filter, columnSort, descending
        }
      });
    } else {
      cancelGetWorkShopsAction = false
    }
  }).catch((err) => {
    if (!cancelGetWorkShopsAction) {
      dispatch(types.FETCHING_END);
    } else {
      cancelGetWorkShopsAction = false
    }
    console.log(err)
  });
};

export const updateWorkShopAction = (workshop) => {
  dispatch(types.FETCHING);
  workShopService().updateWorkShop({
    title: workshop.title,
    detail: workshop.detail
  }, workshop.id).then(res => {
    dispatch(types.UPDATE_WORKSHOP_SUCCESS, {});
    dispatch(types.FETCHING_END);

    dispatch(types.TOGGLE_WORKSHOP_FROM);
    dispatch(types.RESET_WORKSHOP);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: messages[types.UPDATE_WORKSHOP_SUCCESS],
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: messages[types.UPDATE_WORKSHOP_FAILURE],
      color: 'danger'
    });
  });
};

export const cloneWorkShopAction = (title, detail, parentId) => {
  dispatch(types.FETCHING);
  workShopService().addWorkShop({TITLE: title, DETAIL: detail, parentId}).then(res => {
    dispatch(types.CLONE_WORKSHOP_SUCCESS, {});
    dispatch(types.FETCHING_END);

    dispatch(types.TOGGLE_WORKSHOP_FROM);
    dispatch(types.RESET_WORKSHOP);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: messages[types.CLONE_WORKSHOP_SUCCESS],
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: messages[types.CLONE_WORKSHOP_FAILURE],
      color: 'danger'
    });
  });
};

export const deleteWorkShopAction = (id) => {
  dispatch(types.FETCHING);
  workShopService().deleteWorkShop(id).then(res => {
    dispatch(types.FETCHING_END);
    getWorkShopsAction(10, 0)
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: messages[types.DELETE_WORKSHOP_SUCCESS],
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: messages[types.DELETE_WORKSHOP_FAILURE],
      color: 'danger'
    });
  });
};

export const getWorkShopsExcelAction = (filter) => {
  dispatch(types.FETCHING);
  workShopService().getWorkShopsExcel(filter).then(res => {
    dispatch(types.FETCHING_END);
    saveAs(res.data, "Workshops" + getTodayStringValue() + ".xlsx");
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'File downloaded workshop list',
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'File download workshop list',
      color: 'danger'
    });
  });
};
export const getEventsIcalFileAction = (events) => {

  dispatch(types.FETCHING);
  eventService().getEventsIcalFile(events).then(res => {
    dispatch(types.FETCHING_END);
    saveAs(res.data, "ICalendar" + getTodayStringValue() + ".ics");
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'File downloaded',
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'File download',
      color: 'danger'
    });
    dispatch(types.FETCHING_END);
  });
};
export const getEventsIcalFileAllAction = () => {

  dispatch(types.FETCHING);
  eventService().getEventsIcalFileAll().then(res => {
    dispatch(types.FETCHING_END);
    saveAs(res.data, "ICalendar" + getTodayStringValue() + ".ics");
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'File downloaded ',
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'File download',
      color: 'danger'
    });
  });
};
export const addRatingWorkShopAction = (workshopId, rating, paginationWorkShop, filter) => {
  dispatch(types.FETCHING);
  workShopService().addRatingWorkshop({WorkshopId: workshopId, Rating: rating}).then(res => {
    dispatch(types.FETCHING_END);

    dispatch(types.ADD_RATING_SUCCESS, {
      data: {
        workshopId,
        rating
      }
    });

    dispatch(types.RESET_RATING);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'Add rating',
      color: 'info'
    });
    getWorkShopsAction(paginationWorkShop.limit, paginationWorkShop.skip, filter);
    getEventsMemberViewAction(paginationWorkShop.limit, paginationWorkShop.skip, filter, '', true, false)

  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_RATING_FAILURE);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'Add rating',
      color: 'info'
    });
  });
};
export const getWorkShopsVersionAction = (workshopId, limit, skip) => {
  dispatch(types.FETCHING);
  workShopService().getWorkShopsVersion(workshopId, limit, skip).then(res => {
    dispatch(types.GET_WORKSHOPVERSION_SUCCESS, res);
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_WORKSHOPVERSION_PAGINATION, {
      data: {
        count: parseInt(res.headers['x-total-count'], 10),
        limit: parseInt(res.headers['x-total-limit'], 10),
        skip: parseInt(res.headers['x-total-skip'], 10)
      }
    });

  }).catch((err) => {
    dispatch(types.FETCHING_END);
    console.log(err)
  });
};
