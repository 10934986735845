import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBan, faClone, faEdit, faFolderOpen} from '@fortawesome/free-solid-svg-icons';
import {MyTooltip, SuperUser} from '../index';
import {getDateValue1, myDispatch, registerDispatch} from '../../actions/util';
import {connect} from 'react-redux';
import * as types from '../../types';
import {cancelEventAction, cloneEventAction, openEventAction} from '../../actions/event';
import confirm from 'reactstrap-confirm';
import { useHistory, useLocation } from "react-router-dom";

const EventsItems = ({
                       event,
                       updateEvent,
                       cloneEvent,
                       paginationEvent,
                       cancelEventAction,
                       openEventAction,
                       index,
                       setIsActive
                     }) => {

  let history = useHistory();
  let location = useLocation();

  let isFull = false;
  if (event.registrationCount >= event.capacity) {
    isFull = true;
  }

  let cancelTooltip = 'Cancel';
  let nameButton = 'Open';
  let textColor = '#6c757d';

  if (event.iscancelled) {
    textColor = '#dc3545';
    nameButton = 'Cancelled';
    cancelTooltip = 'Re-Open Event';
  } else if (event.isclosed) {
    textColor = '#00b0c2';
    nameButton = 'Closed';
    cancelTooltip = 'Cancel';
  } else {
    if (isFull) {
      textColor = '#6c757d';
      nameButton = 'Full';
    } else {
      textColor = '#17a2b8';
    }
  }

  const textStyle = {
    backgroundColor: textColor,
    color: 'white',
    fontWeight: 500,
    padding: '0 5px 2px 5px'
  };
  return (
    <tr>
      <td id={'toggler-event-view-' + index} className='cursor-pointer'>
        {event.name}
      </td>
      <td id={'toggler-event-view-' + index} className='cursor-pointer'>
        {event.eventLocation ? event.eventLocation.name : ''}
      </td>
      <td id={'toggler-event-view-' + index} className='cursor-pointer' style={{minWidth: '112px'}}>
        {getDateValue1(event.startdate)}
      </td>
      <td id={'toggler-event-view-' + index} className='cursor-pointer'>
        {event.registrationCount + '/' + event.capacity}
      </td>
      <td>
        <span id={'tooltip-event-open' + index} style={textStyle}>
          {nameButton}
        </span>
      </td>
      <td className='text-right'>
        <FontAwesomeIcon
          icon={faEdit}
          style={{color: 'rgba(27,31,35,.3)', cursor: 'pointer'}}
          onClick={() => {
            history.push({...location, hash: "edit-event"});
            //cancelGetEventsActionFn(true);
            updateEvent(event);
            setIsActive(false);
          }}
          className='mr-2'
          id={'tooltip-event-edit' + index}
        />
        <MyTooltip placement='top' target={'tooltip-event-edit' + index}>
          Edit
        </MyTooltip>
        <SuperUser>
          <FontAwesomeIcon
            icon={faClone}
            style={{color: 'rgba(27,31,35,.3)', cursor: 'pointer'}}
            onClick={() => {
              history.push({...location, hash: "clone-event"});
              //cancelGetEventsActionFn(true);
              cloneEvent(event);
              setIsActive(false);
            }}
            className='mr-2'
            id={'tooltip-event-clone' + index}
          />

          <MyTooltip placement='top' target={'tooltip-event-clone' + index}>
            Clone
          </MyTooltip>
          <MyTooltip placement='top' target={'tooltip-event-open' + index}>
            {nameButton}
          </MyTooltip>
          <MyTooltip placement='top' target={'tooltip-event-delete' + index}>
            {cancelTooltip}
          </MyTooltip>
          <MyTooltip placement="right" target={"toggler-event-view-" + index}>
            Detail View
          </MyTooltip>
          <FontAwesomeIcon
            className='mr-0'
            style={{color: 'rgba(27,31,35,.3)', cursor: 'pointer'}}
            onClick={async () => {
              const result = await confirm({
                message: `Are you sure that you want to ${
                  event.iscancelled ? 'open' : 'cancel'
                  } this event?`,
                confirmText: 'Yes',
                cancelText: 'No'
              });
              if (result) {
                event.iscancelled
                  ? openEventAction(event.id, paginationEvent)
                  : cancelEventAction(event.id, paginationEvent);
              }
            }}
            id={'tooltip-event-delete' + index}
            icon={event.iscancelled ? faFolderOpen : faBan}
          />
        </SuperUser>
      </td>
    </tr>
  );
};
const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    updateEvent: event => {
      dispatch(myDispatch(types.ADD_EVENT, {data: event}));
      dispatch(myDispatch(types.UPDATE_EVENT));
    },
    cloneEvent: event => {
      dispatch(myDispatch(types.ADD_EVENT, {data: event}));
      dispatch(myDispatch(types.CLONE_EVENT));
    },
    cancelEventAction,
    cloneEventAction,
    openEventAction
  };
};
export default connect(
  undefined,
  mapDispatchProps
)(EventsItems);
