import {client} from '../util/createRestApiClient';

const base = "Group/";
export default () => ({
    addGroup: (params) => client.request({
      method: 'POST',
      url: base + 'AddGroup',
      data: params
    }),
    getGroup: (params) => client.request({
      method: 'GET',
      url: base + 'GetGroup?id=' + params.id
    }),
    getGroups: (limit = 10, skip = 0, filter = '',columnSort='',descending=true) => client.request({
      method: 'GET',
      url: base + 'GetGroups?limit=' + limit + '&skip=' + skip + '&filter=' + filter + '&columnSort=' + columnSort + '&descending=' + descending
    }),
    updateGroup: (params, id) => client.request({
      method: 'PUT',
      url: base + 'UpdateGroup/' + id,
      data: params
    }),
    deleteGroup: (id) => client.request({
      method: 'DELETE',
      url: base + 'DeleteGroup/' + id
    }),
    deleteMemberGroup: (groupId, member) => client.request({
      method: 'DELETE',
      url: base + 'RemoveMemberGroup/' + groupId + "?member=" + member
    }),
    addMemberGroup: (id, group) => client.request({
      method: 'POST',
      url: base + 'AddMemberGroup/' + id + "?group=" + group
    }),
    getMembersGroup: (id) => client.request({
      method: 'GET',
      url: base + 'GetMembersGroup/' + id
    }),
    importMembersExcel: (groupId, formData) => client.request(
      {
        method: 'POST',
        url: base + 'AddMembersByFileNumberFromExcel/' + groupId, // cambiar metodo
        data: formData

      }
    )
  }
);
