import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faTrash} from '@fortawesome/free-solid-svg-icons';
import {myDispatch, registerDispatch} from '../../actions/util';
import {connect} from 'react-redux';
import * as types from '../../types';
import {deleteGroupAction} from '../../actions/group';
import {MyTooltip, SuperUser} from '../index';
import confirm from 'reactstrap-confirm';
import { useHistory, useLocation } from "react-router-dom";

const GroupItems = ({
                      group,
                      index,
                      updateGroup,
                      deleteGroupAction,
                      setIsActive
                    }) => {

  let history = useHistory();
  let location = useLocation();

  return(
    <tr>
      <td id={'group' + index} className='cursor-pointer'>
        {group.name}
      </td>
      <td id={'group' + index} className='cursor-pointer'>
        {group.description}
      </td>
      <SuperUser>
        <td className='text-right'>
          {/* <Button
        size='sm'
        outline
        color='primary'
        onClick={() => {
          updateGroup(group);
          setIsActive(false);
        }}
        className='mr-2'
        id={'tooltip-group-edit' + index}
      > */}
          <FontAwesomeIcon
            icon={faEdit}
            style={{color: 'rgba(27,31,35,.3)', cursor: 'pointer'}}
            onClick={() => {
              history.push({...location, hash: "edit-group"});
              //cancelGetGroupsActionFn(true);
              updateGroup(group);
              setIsActive(false);
            }}
            className='mr-2'
            id={'tooltip-group-edit' + index}
          />
          {/* </Button> */}
          {''}
          <MyTooltip placement='top' target={'tooltip-group-edit' + index}>
            Edit
          </MyTooltip>

          <MyTooltip placement='top' target={'tooltip-group-delete' + index}>
            Delete
          </MyTooltip>
          {/* <UncontrolledDropdown style={{ display: 'inline' }}>
        <DropdownToggle
          id={'tooltip-group-delete' + index}
          caret
          size='sm'
          outline
          color='danger'
        >
          <FontAwesomeIcon icon={faTrash} />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            className='text-danger cursor-pointer'
            onClick={() => {
              deleteGroupAction(group.id);
            }}
          >
            Confirm
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown> */}
          <FontAwesomeIcon
            className='mr-0'
            style={{color: 'rgba(27,31,35,.3)', cursor: 'pointer'}}
            onClick={async () => {
              const result = await confirm({
                title: "Delete",
                message: 'Are you sure that you want to delete this group?',
                confirmText: 'Yes',
                cancelText: 'No'
              });
              if (result) {
                deleteGroupAction(group.id);
              }
            }}
            id={'tooltip-group-delete' + index}
            icon={faTrash}
          />
        </td>
      </SuperUser>
    </tr>
  );
}

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    updateGroup: group => {
      dispatch(myDispatch(types.ADD_GROUP, {data: group}));
      dispatch(myDispatch(types.UPDATE_GROUP));
    },

    deleteGroupAction
  };
};

export default connect(
  undefined,
  mapDispatchProps
)(GroupItems);
