import React, {useContext, useEffect, useState} from 'react';
import {FormContext, getProps} from "./FormHook";
import {connect} from "react-redux";
import MyDatePicker from "../../components/utils/MyDatePicker";
import {getDateValue} from "../../actions/util";

const regex = {
  htmlID: /^[a-zA-Z][\w:.-]*$/g,
  bracket: /\[(.*)\]/i,
  decimal: /^-?\d+\.?\d*$/,
  email: /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i,
  escape: /[-[\]/{}()*+?.\\^$|]/g,
  flags: /^\/(.*)\/(.*)?/,
  integer: /^-?\d+$/,
  naturalNumber: /^\d+$/,
  number: /^-?\d*(\.\d+)?$/,
  url: /(https?:\/\/(?:www\.|(?!www))[^\s.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/i,
  alphanumeric: /^[\w\d.\-+,:/\\¡!¿?]+$/i,
  word: /^[a-z,:.¡!¿?]+$/i,
  socialSecurityNumber: /^\d\d\d-\d\d-\d\d\d\d$/i,
  phoneNumber: /^\(\d\d\d\) \d\d\d-\d\d\d\d$/i
};

let objBlur = [];

const InputHook = ({item, formName, defaultValue, errorMessages, fireBlur, styleErrorMessage, newValue}) => {

  let object = {};
  const isHidden = item.type === 'input' && item.props && item.props.type === "hidden";
  const isCheckbox = item.type === 'input' && item.props && item.props.type === "checkbox";
  // const isFile = item.type === 'input' && item.props && item.props.type === "file";
  // const isRange = item.type === 'input' && item.props && item.props.type === "range";
  // const isColor = item.type === 'input' && item.props && item.props.type === "color";
  const isRadio = item.type === 'input' && item.props && item.props.type === "radio";
  // const isPassword = item.type === 'input' && item.props && item.props.type === "password";
  const isDate = item.type === 'input' && item.props && item.props.type === "date";

  const name = item.props.name;

  const getFormat = (value) => {

    if (value && item.props.format === "ssn" && value.length > 8) {
      const tmp = value.replace(/[^\d]/gi, '');

      if (tmp.length > 8) {
        value = tmp.substr(0, 3) + "-" + tmp.substr(3, 2) + "-" + tmp.substr(5, 4);
      }
    }
    if (value && item.props.format === "phone" && value.length > 9) {
      const tmp = value.replace(/\s/gi, '')
        .replace(/\s/gi, '')
        .replace(/\(/gi, '')
        .replace(/\)/gi, '')
        .replace(/-/gi, '');

      if (tmp.length > 9) {
        value = "(" + tmp.substr(0, 3) + ") " + tmp.substr(3, 3) + "-" + tmp.substr(6, 4);
      }
    }
    if (value && item.props.format === "phone8" && value.length > 7) {
      const tmp = value.replace(/-/gi, '');

      if (tmp.length > 7) {
        value = tmp.substr(0, 3) + "-" + tmp.substr(3, 4);
      }
    }
    return value;
  };

  const outPutFormat = (value) => {
    if (item.props.outPutFormat) {
      if (item.props.outPutFormat === "yyyyddmm") {
        value = getDateValue(value);
      } else if (item.props.outPutFormat === "UTC") {
        value = value && typeof value === "object" ? value.toUTCString() : value;
      } else if (item.props.outPutFormat === "phone13Length") {
        value = value ? value.replace(/\s/gi, '') : value;
      }
    }
    return value;
  };

  const [value, setValue] = useState(isCheckbox ? !!defaultValue : getFormat(defaultValue));

  const [error, setError] = useState('');
  const [blur, setBlur] = useState(!!objBlur[formName + '-blur-' + name]);
  const [readOnly, setReadOnly] = useState(true);

  const changeValues = useContext(FormContext);

  useEffect(() => {
    if (newValue) {
      setValue(newValue);
    }
    // eslint-disable-next-line
  }, [newValue]);

  const myFireBlur = !!(fireBlur && fireBlur[formName]);

  useEffect(() => {
    changeValues(name, outPutFormat(value), validationFn(value));
    // eslint-disable-next-line
  }, [changeValues, item]);

  if (isRadio) {
    object['checked'] = value === item.props.value;
  } else if (isCheckbox) {
    object['checked'] = typeof value === 'boolean' ? value : item.props.checked;
  } else if (isDate) {
    object['selected'] = value;
  } else {
    object['value'] = value;
  }
  /*if (isValid && validationInitialFn(tag) === false) {
    isValid = false;
  }*/


  const getText = (message) => message && errorMessages && errorMessages[message] ? errorMessages[message] : message;

  const isValidFn = (tag, value) => regex[tag].test(value);

  const validationFn = (value) => {
    const getErrorMessage = (msg) => item.props.errorMessage ? item.props.errorMessage : msg;
    let validation;
    if (item.props.required && (value === "" || value === undefined)) {
      validation = {required: true, errorMessage: getErrorMessage('This is required')};
    } else if (typeof item.props.validation === 'function' || typeof item.props.validation === 'boolean') {
      validation = {...validation, validation: item.props.validation, errorMessage: getErrorMessage('This field is wrong')};
    } else if (item.props.pattern) {
      validation = {...validation, pattern: item.props.pattern, errorMessage: getErrorMessage('This field is wrong')};
    } else {
      if (item.props.type === 'number' && (!isValidFn('number', value) || !isValidFn('naturalNumber', value) || !isValidFn('decimal', value) || !isValidFn('integer', value))) {
        validation = {...validation, regex: 'number', errorMessage: getErrorMessage('This field most be number')};
        if (item.props.naturalNumber) {
          validation = {...validation, regex: 'naturalNumber', errorMessage: getErrorMessage('This field most be natural number')};
        } else if (item.props.decimal) {
          validation = {...validation, regex: 'decimal', errorMessage: getErrorMessage('This field most be decimal number')};
        } else if (item.props.integer) {
          validation = {...validation, regex: 'integer', errorMessage: getErrorMessage('This field most be integer number')};
        }
      } else if (item.props.type === 'email' && !isValidFn('email', value)) {
        validation = {...validation, regex: 'email', errorMessage: getErrorMessage('Must be a valid email address')};
      } else if (item.props.type === 'url' && !isValidFn('url', value)) {
        validation = {...validation, regex: 'url', errorMessage: getErrorMessage('This field most be url')};
      } else {
        if (item.props.alphanumeric && !isValidFn('alphanumeric', value)) {
          validation = {...validation, regex: 'alphanumeric', errorMessage: getErrorMessage('This field most be alphanumeric')};
        } else if (item.props.word && !isValidFn('word', value)) {
          validation = {...validation, regex: 'word', errorMessage: getErrorMessage('This field most be words')};
        } else if (item.props.decimal && !isValidFn('decimal', value)) {
          validation = {...validation, regex: 'decimal', errorMessage: getErrorMessage('This field most be decimal number')};
        } else if (item.props.integer && !isValidFn('integer', value)) {
          validation = {...validation, regex: 'integer', errorMessage: getErrorMessage('This field most be integer number')};
        } else if (item.props.naturalNumber && !isValidFn('naturalNumber', value)) {
          validation = {...validation, regex: 'naturalNumber', errorMessage: getErrorMessage('This field most be natural number')};
        } else if (item.props.number && !isValidFn('number', value)) {
          validation = {...validation, regex: 'number', errorMessage: getErrorMessage('This field most be number')};
        } else if (item.props.email && !isValidFn('email', value)) {
          validation = {...validation, regex: 'email', errorMessage: getErrorMessage('Must be a valid email address')};
        } else if (item.props.url && !isValidFn('url', value)) {
          validation = {...validation, regex: 'url', errorMessage: getErrorMessage('This field most be url')};
        } else if (item.props.socialSecurityNumber && !isValidFn('socialSecurityNumber', value)) {
          validation = {...validation, regex: 'socialSecurityNumber', errorMessage: getErrorMessage('This field most be a social security number')};
        } else if (item.props.format === "phone" && !isValidFn('phoneNumber', value)) {
          validation = {...validation, regex: 'phoneNumber', errorMessage: getErrorMessage('This field most be a valid phone number')};
        }
      }
    }

    let errorTmp = '';

    if (validation) {
      const genericWrongText = getText('This field is wrong');
      if ((value === "" || value === undefined) && validation.required === true) {
        errorTmp = getText(validation.errorMessage);
      } else if (value) {
        if (typeof validation.validation !== 'undefined') {
          if (typeof validation.validation === 'function') {
            errorTmp = validation.validation(value) ? '' : (validation.errorMessage ? getText(validation.errorMessage) : genericWrongText);
          } else if (typeof validation.validation === 'boolean') {
            errorTmp = validation.validation ? '' : (validation.errorMessage ? getText(validation.errorMessage) : genericWrongText);
          }
        } else if (validation.pattern) {
          errorTmp = validation.pattern.test(value) ? '' : (validation.errorMessage ? getText(validation.errorMessage) : genericWrongText);
        } else if (validation.regex) {
          errorTmp = isValidFn(validation.regex, value) ? '' : (validation.errorMessage ? getText(validation.errorMessage) : genericWrongText);
        }
      }
    }
    setError(errorTmp);
    return !errorTmp;
  };

  const getNameAndValue = (e) => {
    let value = '';
    if (!item.props || !item.props.type) {
      value = e.target.value;
    } else if (item.props.type === "checkbox") {
      value = e.target.checked;
    } else if (item.props.type === "file") {
      value = e.target.files;
    } else if (isDate) {
      value = e;
    } else {
      value = e.target.value;
    }
    return {value};
  };

  const onChange = (e) => {
    let {value} = getNameAndValue(e);

    if ((typeof item.props.maxLength === 'number' && item.props.maxLength < value.toString().length) || (item.props.format === "phone" && value && ((value.match(/\s/gi) && value.match(/\s/gi).length > 1) || value.match(/[^(\d|()|\s|\-)]/gi)))) {
      return
    }

    value = getFormat(value);

    const isValid = validationFn(value);
    setValue(value);

    value = outPutFormat(value);

    changeValues(name, value, isValid);
    if (item.props.onChange) {
      item.props.onChange(e, name, value);
    }

  };

  object['onChange'] = onChange;

  object["onBlur"] = (e) => {
    const {value} = getNameAndValue(e);
    validationFn(value);
    setBlur(true);
    objBlur[formName + '-blur-' + name] = true;
    if (item.props.onBlur) {
      item.props.onBlur(e);
    }
  };

  if (item.props['autoComplete'] === "off") {
    object["readOnly"] = readOnly;
  }

  object["onFocus"] = () => {setReadOnly(false)};

  let tmpClass = " form-control";
  let notValidOption = item.props.notValidOption;
  if (!notValidOption) {
    if (error && blur) {
      tmpClass += " is-invalid"
    } else if (blur) {
      tmpClass += " is-valid"
    }
  }
  object['className'] = item.props['className'] ? item.props['className'] + tmpClass : tmpClass;


  let styleMaxLength = {float: 'right', fontSize: '12px'};
  if (item.props.styleMaxLength) {
    styleMaxLength = {...styleMaxLength, ...item.props.styleMaxLength};
  }
  if (item.props.maxLengthEspace) {
    styleMaxLength = {...styleMaxLength, ...{opacity: 0}};
  }

  const styleTmp = {
    fontSize: '11px',
    color: '#f44336',
    fontWeight: 'bold',
    marginBottom: '-12px',
    display: 'block', ...styleErrorMessage
  };

  const noToday = item.props.noToday;

  return (<React.Fragment>
    {(typeof item.props.maxLength === 'number' || item.props.maxLengthEspace) && <span style={styleMaxLength}>[{value ? value.toString().length : 0}/{item.props.maxLength}]</span>}
    {!noToday && isDate && <span className="btn-today" style={item.props.btnTodayStyle} onClick={() => {
      onChange(new Date())
    }}>Today</span>}
    {isDate ? <MyDatePicker {...item.props} {...object}/> : React.createElement(item.type, {...(item.props ? getProps(item.props) : {}), ...object})}
    {item.props.hint && <span style={{fontSize: '10px', position: 'absolute', bottom: '-8px', right: '15px', fontWeight: 'bold'}}>Hint: {item.props.hint}</span>}
    {!notValidOption && !(item.props && (item.props.noErrorMessage || isHidden)) && <span style={styleTmp} className="text-alert">{error && (blur || myFireBlur) ? error : <span>&nbsp;</span>}</span>}
  </React.Fragment>);

};

const mapStateToProps = store => {
  return {
    fireBlur: store.fireBlur
  };
};

export default connect(mapStateToProps)(InputHook);