import React, {useEffect, useState} from 'react';
import {Button, FormGroup, Label, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileExcel, faTimes} from '@fortawesome/free-solid-svg-icons';
import {getMembersAction} from '../../actions/member';
import {importMembersExcelAction} from '../../actions/group';
import {myDispatch, registerDispatch} from '../../actions/util';
import {connect} from 'react-redux';
import * as types from '../../types';
import {SearchMemberGroupList} from '../index';

const GroupMemberList = ({
                           group,
                           members,
                           className,
                           getMembersAction,
                           addMemberGroupAction,
                           removeMemberGroupAction,
                           importMembersExcelAction,
                           index
                         }) => {

  let selectItems = [];
  let membersGroup = group.members || [];

  const isAlready = member => {
    for (let i in membersGroup) {
      if (member.lastname === membersGroup[i].lastname) {
        return true;
      }
    }
    return false;
  };

  if (members.length) {
    for (let i in members) {
      if (!isAlready(members[i])) {
        selectItems.push({label: members[i].lastname, value: members[i]});
      }
    }
  }
  const linkStyle = {
    color: '#28a745',
    fontSize: '14px'
  };

  const [modalImportFileNo, setModalImportFileNo] = useState();
  const [isMemberRemoved, setIsMemberRemoved] = useState(false);
  const [modalSearchMember, setModalSearchMember] = useState();
  const [uploadExcel, setUploadExcel] = useState();
  const [memberList, setMemberList] = useState([]);

  useEffect(() => {
    if (modalSearchMember) {
      getMembersAction(5, 0);
    }
    // eslint-disable-next-line
  }, [modalSearchMember]);

  const importFileNoModal = () => {
    setModalImportFileNo(!modalImportFileNo);
  };
  const searchMemberModal = () => {
    setModalSearchMember(!modalSearchMember);
  };
  return (
    <div>
      <Row style={{margin: '0px 0px 10px'}}>
        <h5>Member List</h5>
        <Button
          style={{marginLeft: '300px', width: '20%'}}
          color='primary'
          outline
          onClick={() => {
            searchMemberModal();
          }}
        >
          Search
        </Button>
      </Row>

      <div>
        <Button color='link' style={linkStyle} onClick={() => {
          importFileNoModal();
        }}>
          <FontAwesomeIcon icon={faFileExcel}/>
          <span style={{marginLeft: '5px'}}>
                        Import from Excel
                      </span>
        </Button>
        {isMemberRemoved && <div className="text-primary" style={{marginLeft: "12px"}}>Please click on the Update button to save the changes.</div>}
        <ListGroup>
          {membersGroup
            .filter((obj, index, self) =>
            index === self.findIndex((o) => o.id === obj.id)
          ).map((e, i) => {
            return (
              <ListGroupItem key={'roles-item-' + i}>
                {e.frstname + ', ' + e.lastname + ' (' + e.fileno + ')'}
                <FontAwesomeIcon
                  className='float-right'
                  style={{
                    color: 'rgba(27,31,35,.3)',
                    cursor: 'pointer',
                    marginTop: '3px'
                  }}
                  onClick={async () => {
                    removeMemberGroupAction(i);
                    setIsMemberRemoved(true)
                  }}
                  icon={faTimes}
                />
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </div>

      <div>
        <Modal isOpen={modalImportFileNo} toggle={importFileNoModal}>
          <ModalHeader className='card-header' toggle={importFileNoModal}>
            Import Member List
          </ModalHeader>
          <ModalBody>
            <FormGroup className='col-12'>
              <Row>
                <input type='file' accept=".xlsx" onChange={(e) => {

                  if (e.target.files[0]) {
                    setUploadExcel(e.target.files[0]);
                  }
                }}/>
              </Row>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color='primary'
              disabled={!uploadExcel}
              onClick={() => {
                importMembersExcelAction(group.id, uploadExcel);
                importFileNoModal();
              }}
            >
              Import
            </Button>{' '}

            <Label>&nbsp;or&nbsp;</Label>
            <span
              style={{cursor: 'pointer', color: '#17a2b8'}}
              onClick={() => {
                importFileNoModal();
              }}
            >
                Cancel
              </span>
          </ModalFooter>
        </Modal>
      </div>
      <div>
        <Modal style={{width: 'max-content', margin: '5px,5px', minWidth: '80%'}} isOpen={modalSearchMember} toggle={searchMemberModal}>
          <ModalHeader className='card-header' toggle={searchMemberModal}>
            Member List
          </ModalHeader>
          <ModalBody>
            <FormGroup className='col-12'>
              <Row>
                <SearchMemberGroupList
                  memberList={memberList}
                  setMemberList={setMemberList}
                  membersGroup={membersGroup}/>
              </Row>
            </FormGroup>
            <div style={{textAlign: 'center', marginTop: "30px", marginRight: "15px"}}>
              <Button
                color='primary'
                disabled={!memberList.length}
                onClick={() => {
                  //  importMembersExcelAction(group.id,uploadExcel);
                  if (memberList.length) {
                    //  console.log(memberList)
                    for (let i in memberList) {
                      addMemberGroupAction(memberList[i])
                    }
                    setIsMemberRemoved(true)
                  }
                  searchMemberModal();
                }}
              >
                Add
              </Button>{' '}
              <Label>&nbsp;or&nbsp;</Label>
              <span
                style={{cursor: 'pointer', color: '#17a2b8'}}
                onClick={() => {
                  searchMemberModal();
                }}
              >
                Cancel
              </span>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};
const mapStateToProps = ({isFetching, getMembers, group}) => ({
  isFetching,
  members: getMembers,
  group: group
});

const mapDispatchProps = dispatch => {

  registerDispatch(dispatch);

  return {
    removeMemberGroupAction: index => {
      dispatch(myDispatch(types.REMOVE_MEMBER_GROUP, {data: index}));
    },
    addMemberGroupAction: member => {
      dispatch(myDispatch(types.ADD_MEMBER_GROUP, {data: member}));
    },
    getMembersAction,
    importMembersExcelAction
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(GroupMemberList);
