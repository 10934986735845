import {client} from '../util/createRestApiClient';

const base = "EventLocation/";
export default () => ({
  addLocation: (params) => client.request(
    {
      method: 'POST',
      url: base + 'AddEventLocation',
      data: params
    }
  ),
  getLocation: (params) => client.request(
    {
      method: 'GET',
      url: base + 'GetEventLocation?id=' + params.id
    }
  ),
  getLocations: (limit = 10, skip = 0, filter = '', columnSort = '', descending = true) => client.request(
    {
      method: 'GET',
      url: base + 'GetEventLocations?limit=' + limit + '&skip=' + skip + '&filter=' + filter + '&columnSort=' + columnSort + '&descending=' + descending
    }
  ),
  updateLocation: (params, id) => client.request(
    {
      method: 'PUT',
      url: base + 'UpdateEventLocation/' + id,
      data: params
    }
  ),
  deleteLocation: (id) => client.request(
    {
      method: 'DELETE',
      url: base + 'DeleteEventLocation/' + id
    }
  ),
  getLocationsExcel: (filter = '') => client.request(
    {
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetEventLocationsExcel?filter=' + filter
    }
  )
});
