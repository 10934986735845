import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import InputHook from "./InputHook";
import * as types from "../FormReactRedux/types";
import {getDate} from "../../actions/util";

let tmpIndex = 1;
let currentValues;
let lastID;

// export const getUniqueKey = () => Math.floor(Math.random() * 1000) + '' + Math.floor(Math.random() * 1000);
export const getUniqueKey = () => tmpIndex++;

export const getValueFromFormReactRedux = (formName, formReactRedux, value, type, defaultValue) => formName && formReactRedux[formName] && typeof formReactRedux[formName][value] !== 'undefined'
&& (
  (type === 'array' && Array.isArray(formReactRedux[formName][value]))
  || (type === 'object' && typeof formReactRedux[formName][value] === type && !Array.isArray(formReactRedux[formName][value]))
  || (typeof formReactRedux[formName][value] === type)
) ? formReactRedux[formName][value] : defaultValue;


const FormHook = (props) => {

  const {children, formName, defaultValues, noPreventDefault, submit, errorMessages, styleErrorMessage, setBlurAction} = props;

  const [item, setItem] = useState([]);

  const isDefaultValues = defaultValues && typeof defaultValues === "object";

  if ((!currentValues && isDefaultValues) || (defaultValues && lastID !== defaultValues.id)) {
    currentValues = {...defaultValues};
    lastID = currentValues.id;
  }

  let defaultValuesTmp = currentValues && currentValues ? currentValues : isDefaultValues ? defaultValues : {},
    values = {},
    isValid = true,
    valuesIsValid = {};

  useEffect(() => {
    return () => {
      currentValues = null;
    }
    // eslint-disable-next-line
  }, []);


  useEffect(() => {

    setItem(childrenMap(Array.isArray(children) ? children : [children]));

    return () => {
      setItem([])
    };

    // eslint-disable-next-line
  }, [children]);



  const changeValues = (name, value, nameValueIsValid) => {

    values[name] = value;
    if (!currentValues) {
      currentValues = {};
    }
    if (typeof value === "string" && value.length === 29 && / GMT$/.test(value)) {
      currentValues[name] = new Date(value);
    } else {
      currentValues[name] = value;
    }
    valuesIsValid[name] = nameValueIsValid;

    let tmpIsValid = true;
    for (let i in valuesIsValid) {
      if (!tmpIsValid) {
        break;
      }
      tmpIsValid = valuesIsValid[i];
    }
    isValid = tmpIsValid;
  };

  const childrenMap = (children) => {
    return children.map((item) => {
      if (React.isValidElement(item)) {

        const isInput = item.type === 'input';
        const isSubmit = item.type === 'input' && item.props && item.props.type === "submit";
        const isSelect = item.type === 'select';
        const isTextArea = item.type === 'textarea';
        const isCheckbox = item.type === 'input' && item.props && item.props.type === "checkbox";
        const isDate = item.type === 'input' && item.props && item.props.type === "date";

        if ((isInput && !isSubmit) || isSelect || isTextArea) {

          let tag = '';
          if (item.props && item.props.name) {
            tag = item.props.name;
          } else {
            tag = 'name-' + getUniqueKey();
          }

          let defaultValue;
          if (typeof defaultValuesTmp[tag] === 'undefined' && typeof item.props.defaultValue !== 'undefined') {
            defaultValue = item.props.defaultValue;
          } else if (typeof defaultValuesTmp[tag] !== 'undefined') {
            defaultValue = isDate ? getDate(defaultValuesTmp[tag]) : defaultValuesTmp[tag];
          } else if (isCheckbox && item.props.value) {
            defaultValue = item.props.value;
          } else if (isDate) {
            defaultValue = item.props.value ? getDate(item.props.value) : undefined;
          } else {
            defaultValue = '';
          }

          values[tag] = defaultValue;

          if (defaultValue && typeof defaultValue === 'string' && !/^-?\d*(\.\d+)?$/.test(defaultValue)) {
            defaultValue = defaultValue.trim();
          }

          item = <InputHook key={"my-input" + getUniqueKey()} styleErrorMessage={styleErrorMessage} formName={formName} defaultValue={defaultValue} item={item} errorMessages={errorMessages}/>;
        }
        if (item.props && item.props.children) {
          return React.cloneElement(item, {key: formName + '-' + getUniqueKey(), children: childrenMap(Array.isArray(item.props.children) ? item.props.children : [item.props.children])});
        }
      }
      return item;
    });
  };

  return (
    <FormContext.Provider value={changeValues}>

      <form
        {...getProps(props)}
        onSubmit={(e) => {
          if (!noPreventDefault) {
            e.preventDefault();
            if (isValid) {
              // console.log('values', values)
              if (submit) {
                let tmp = {...defaultValues, ...values};
                for (let i in tmp) {
                  if (tmp[i] && typeof tmp[i] === 'string' && !/^-?\d*(\.\d+)?$/.test(tmp[i])) {
                    tmp[i] = tmp[i].trim();
                  }
                }
                submit(tmp, e);
              }
            } else {
              /*for (let i in values) {
                blurs[i] = true;
                // validationFn(i);
              }*/
              setBlurAction(formName);
              // changeValues();
            }
          }
        }}>
        {item}
      </form>

    </FormContext.Provider>
  )
};


export const getProps = (props) => {
  let tmp = {};
  for (let i in props) {
    if (!(/errorMessage|validFn|required|setBlurAction|fireBlur|naturalNumber|noErrorMessage|defaultValue|formName|formReactRedux|styleErrorMessage|styleMaxLength|formReactReduxOnChange|defaultValues|submit|noPreventDefault|formReactReduxReset|formReactReduxUndo|formReactReduxRedo|alphanumeric|word|number|integer|decimal|email|url|validation|socialSecurityNumber|notValidOption|format|btnTodayStyle|dateFormat|outPutFormat|hint|maxLengthEspace|alternativeState/i.test(i))) {
      tmp[i] = props[i]
    }
  }
  return tmp;
};

export const FormContext = React.createContext(() => {
});

const mapDispatchProps = dispatch => {
  return {
    setBlurAction: (formName) => {
      dispatch({type: types.FIRE_BLUR, data: formName})
    }
  }
};

export default connect(undefined, mapDispatchProps)(FormHook);