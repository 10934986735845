import React from 'react';
import {getDateValue1, registerDispatch} from '../../actions/util';
import {connect} from 'react-redux';
import StarRatings from 'react-star-ratings';


const WorkshopsVersionItems = ({
                                 workshop,
                                 isRetiree,
                                 version,
                                 index
                               }) => {
  return (
    <tr>
      <td style={{width: '30px'}}>
        {version}
      </td>
      <td style={{width: '30px'}}>
        {getDateValue1(workshop.createDate)}
      </td>
      <td style={{width: '450px'}} id={'workshop' + index} className='cursor-pointer'>
        Title: <p style={{color: 'gray', fontWeight: 'normal'}}> {workshop.title}</p> <br/>
        Detail:<p style={{textAlign: 'justify', color: 'gray', fontWeight: 'normal'}}>  {workshop.detail}</p>
      </td>

      {!isRetiree &&
      <td style={{width: '160px'}}>
        <StarRatings
          rating={workshop.rating}
          starRatedColor='blue'
          // changeRating={value => {
          // addRatingWorkShopAction(workshop.id, value,paginationWorkShop,filter);
          // }}
          starDimension='20px'
          starSpacing='3px'
          numberOfStars={5}
          name='rating'
        />
      </td>
      }

    </tr>
  );
};

const mapStateToProps = ({isRetiree,}) => ({isRetiree});
const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchProps
)(WorkshopsVersionItems);
