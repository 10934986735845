import React from 'react';
import {Table} from 'reactstrap';
import {MyPagination, WorkshopVersionItems} from '../index';
import {getWorkShopsVersionAction} from '../../actions/workshop';
import {registerDispatch} from '../../actions/util';
import {connect} from 'react-redux';


const WorkshopVersionList = ({isFetching, workshop, workshops, getWorkShopsVersionAction, paginationWorkShopVersion}) => {


  let items = [];

  if (workshops.length) {
    let version = 1
    for (let i in workshops) {
      version = (version + parseInt(i, 10))
      items.push(
        <WorkshopVersionItems
          key={'workshops-item' + i}
          workshop={workshops[i]}
          version={version}
          index={i}
        />
      );
    }
  }


  return (
    <div>
      <h5>Version List</h5>
      <Table className='col-12' responsive>
        <tbody>
        {workshops.length ? (
          items
        ) : (
          <tr>
            <th className='text-center' colSpan='6'>
              No content data.
            </th>
          </tr>
        )}
        </tbody>
      </Table>
      {workshops.length > 0 &&
      paginationWorkShopVersion.limit < paginationWorkShopVersion.count && (
        <MyPagination
          count={paginationWorkShopVersion.count}
          limit={paginationWorkShopVersion.limit}
          skip={paginationWorkShopVersion.skip}
          action={skip => {
            getWorkShopsVersionAction(workshop.id, paginationWorkShopVersion.limit, skip);
          }}
        />
      )}

    </div>
  );
};

const mapStateToProps = ({isFetching, paginationWorkShopVersion}) => ({
  isFetching,
  paginationWorkShopVersion,
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {getWorkShopsVersionAction};
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(WorkshopVersionList);
