import React from 'react';
import {Button} from 'reactstrap';
import {getDateValue1, registerDispatch} from '../../actions/util';
import {connect} from 'react-redux';
import confirm from 'reactstrap-confirm';
import {MyTooltip} from "../index";
import {addMemberByMemberLoginRegistrationAction, unRegisterByLoginMemberAction, unWaitingByLoginMemberAction} from '../../actions/member';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import StarRatings from 'react-star-ratings';
import {addRatingWorkShopAction} from '../../actions/workshop';

const EventsMemberViewItems = ({index, event, addMemberByMemberLoginRegistrationAction, paginationEventMemberView, unRegisterByLoginMemberAction, unWaitingByLoginMemberAction}) => {

  let isRegistered = event.isRegister;
  let isWaiting = event.isWaiting;
  let isfull = false;
  if (event.registrationCount >= event.capacity) {
    isfull = true
  }

  return (
    <tr>
      <td id={'toggler-event-member-' + index} className='cursor-pointer'>
        {event.name + " at " + (event.eventLocation ? event.eventLocation.name : '')} <br/> {event.workshop.title}
      </td>
      <td id={'toggler-event-member-' + index} className='cursor-pointer'>
        {getDateValue1(event.startdate) + " to " + getDateValue1(event.enddate)} <br/>
        <div style={{color: 'gray', fontSize: '11px'}}> {event.starttime + " to " + event.endtime}</div>
      </td>
      <td style={{width: '160px'}}>
        <StarRatings
          rating={event && event.workshop && event.workshop.ratings && event.workshop.ratings[0] && event.workshop.ratings[0].rating ? event.workshop.ratings[0].rating : 0}
          starRatedColor='blue'
          //  changeRating={value => {
          //    addRatingWorkShopAction(event.workshop.id, value,paginationEventMemberView);
          // }}
          starDimension='20px'
          starSpacing='3px'
          numberOfStars={5}
          name='rating'
        />
      </td>
      <td style={{width: '200px', textAlign: 'center'}}>
        {(isRegistered) ? (<Button outline size='sm' color='primary' className="mr-2"
                                   onClick={async () => {
                                     const result = await confirm({
                                       title: "Unregister",
                                       message:
                                         'Are you sure unregister member from this Event?',
                                       confirmText: 'Yes',
                                       cancelText: 'No'
                                     });
                                     if (result) {
                                       unRegisterByLoginMemberAction(event.id, paginationEventMemberView);
                                     }
                                   }}> <FontAwesomeIcon
          className='float-left mr-1'
          style={{
            color: 'primary',
            cursor: 'pointer',
            marginTop: '3px'
          }}

          icon={faTimes}
        /> Unregister</Button>) : (!isRegistered && !isWaiting && !isfull) ?
          (<Button outline size='sm' color='primary' className="mr-2"
                   onClick={async () => {
                     const result = await confirm({
                       title: "Register",
                       message:
                         'Are you sure register from this Event?',
                       confirmText: 'Yes',
                       cancelText: 'No'
                     });
                     if (result) {
                       addMemberByMemberLoginRegistrationAction(event.id, 0, false, false, paginationEventMemberView);
                     }
                   }}>
            Register</Button>) : (isWaiting) ? (<Button outline size='sm' color='primary' className="mr-2"
                                                        onClick={async () => {
                                                          const result = await confirm({
                                                            title: "Unwaiting",
                                                            message:
                                                              'Are you sure unwaiting member from this Event?',
                                                            confirmText: 'Yes',
                                                            cancelText: 'No'
                                                          });
                                                          if (result) {
                                                            unWaitingByLoginMemberAction(event.id, paginationEventMemberView);
                                                          }
                                                        }}> <FontAwesomeIcon
            className='float-left mr-1'
            style={{
              color: 'primary',
              cursor: 'pointer',
              marginTop: '3px'
            }}

            icon={faTimes}
          /> Cancel Waiting List</Button>) : (
            <div>
              <span style={{color: 'red'}}>Workshop is full</span>
              <Button outline size='sm' color='primary' className="mr-2"
                      onClick={async () => {
                        const result = await confirm({
                          title: "Waiting",
                          message:
                            'Are you sure waiting from this Event?',
                          confirmText: 'Yes',
                          cancelText: 'No'
                        });
                        if (result) {
                          addMemberByMemberLoginRegistrationAction(event.id, 0, false, false, paginationEventMemberView);
                        }
                      }}>
                Waiting List
              </Button>
            </div>)

        }

      </td>
      <MyTooltip placement="right" target={"toggler-event-member-" + index}>
        Detail View
      </MyTooltip>
    </tr>
  );
};

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    addMemberByMemberLoginRegistrationAction,
    unRegisterByLoginMemberAction,
    unWaitingByLoginMemberAction,
    addRatingWorkShopAction
  };
};
export default connect(undefined, mapDispatchProps)(EventsMemberViewItems);
