import * as types from "../types";

export const group = {
  CRUDGroup: (state = types.CREATE_GROUP, action) => {
    switch (action.type) {
      case types.GET:
        return types.GET;
      case types.CREATE_GROUP:
        return types.CREATE_GROUP;
      case types.UPDATE_GROUP:
        return types.UPDATE_GROUP;
      case types.DELETE_GROUP:
        return types.DELETE_GROUP;
      default:
        return state
    }
  },
  showGroupForm: (state = false, action) => {
    switch (action.type) {
      case types.GET:
        return false;
      case types.CREATE_GROUP:
        return true;
      case types.UPDATE_GROUP:
        return true;
      case types.TOGGLE_GROUP_FROM:
        return !state;
      default:
        return state
    }
  },

  getGroups: (state = [], action) => {
    switch (action.type) {
      case types.GET_GROUP_SUCCESS:
        return action.data;
      case types.GET_GROUP_FAILURE:
        return [];
      default:
        return state
    }
  },
  getMembers: (state = [], action) => {
    switch (action.type) {
      case types.GET_MEMBER_SUCCESS:
        return action.data;
      case types.GET_MEMBER_FAILURE:
        return [];
      default:
        return state
    }
  },
  getMembersGroup: (state = [], action) => {
    switch (action.type) {
      case types.GET_MEMBER_GROUP_SUCCESS:
        return action.data;
      case types.GET_MEMBER_GROUP_FAILURE:
        return [];
      default:
        return state
    }
  },

  group: (state = {}, action) => {
    switch (action.type) {
      case types.ADD_GROUP:
        return action.data;
      case types.RESET_GROUP:
        return {};
      case types.REMOVE_MEMBER_GROUP:
        let members = [...state.members];
        if (typeof action.data) {
          members.splice(action.data, 1);
        }
        return {...state, members};
      case types.ADD_MEMBER_GROUP:
        let list = [...state.members];
        if (typeof action.data) {
          list.push(action.data);
        }
        return {...state, members: list};
      default:
        return state
    }
  },
  getMemberGroup: (state = [], action) => {
    switch (action.type) {
      case types.GET_MEMBER_GROUP_SUCCESS:
        return action.data;
      case types.GET_MEMBER_GROUP_FAILURE:
        return [];
      default:
        return state
    }
  },
  paginationGroup: (state = {count: 0, limit: 10, skip: 0, filterName: ""}, action) => {
    switch (action.type) {
      case types.ADD_GROUP_PAGINATION:
        return {...state, ...action.data};
      case types.RESET_GROUP_PAGINATION:
        return {};
      default:
        return state
    }
  }
};