import * as types from '../types';

export const event = {
  CRUDEvent: (state = types.CREATE_EVENT, action) => {
    switch (action.type) {
      case types.GET:
        return types.GET;
      case types.CREATE_EVENT:
        return types.CREATE_EVENT;
      case types.UPDATE_EVENT:
        return types.UPDATE_EVENT;
      case types.DELETE_EVENT:
        return types.DELETE_EVENT;
      case types.CLONE_EVENT:
        return types.CLONE_EVENT;
      default:
        return state;
    }
  },
  showEventForm: (state = false, action) => {
    switch (action.type) {
      case types.GET:
        return false;
      case types.CREATE_EVENT:
        return true;
      case types.UPDATE_EVENT:
        return true;
      case types.CLONE_EVENT:
        return true;
     case types.GET_EVENT_SUCCESS:
        return false;
      case types.TOGGLE_EVENT_FROM:
        return !state;
      default:
        return state;
    }
  },
  getEvents: (state = [], action) => {
    switch (action.type) {
      case types.GET_EVENT_SUCCESS:
        return action.data;
      case types.GET_EVENT_FAILURE:
        return [];
      default:
        return state;
    }
  },
  event: (state = {}, action) => {
    switch (action.type) {
      case types.ADD_EVENT:
        return action.data;
      case types.RESET_EVENT:
        return {};
      default:
        return state;
    }
  },
  paginationEvent: (
    state = {count: 0, limit: 50, skip: 0, filterName: '', filter: '', columnSort: 'date', descending: false, viewPast: false, selectYear: ""},
    action
  ) => {
    switch (action.type) {
      case types.ADD_EVENT_PAGINATION:
        return {...state, ...action.data};
      case types.RESET_EVENT_PAGINATION:
        return {};
      default:
        return state;
    }
  },
  getInstructors: (state = [], action) => {
    switch (action.type) {
      case types.GET_INSTRUCTOR_SUCCESS:
        return action.data;
      case types.GET_INSTRUCTOR_FAILURE:
        return [];
      case types.RESET_INSTRUCTOR:
        return [];
      default:
        return state;
    }
  },
  getEventInstructors: (state = [], action) => {
    switch (action.type) {
      case types.GET_EVENT_INSTRUCTOR_SUCCESS:
        return action.data;
      case types.GET_EVENT_INSTRUCTOR_FAILURE:
        return [];
      default:
        return state;
    }
  },
  getTitles: (state = [], action) => {
    switch (action.type) {
      case types.GET_TITLES_SUCCESS:
        return action.data;
      case types.GET_TITLES_FAILURE:
        return [];
      default:
        return state;
    }
  },

  getEventTitles: (state = [], action) => {
    switch (action.type) {
      case types.GET_EVENT_TITLES_SUCCESS:
        return action.data;
      case types.GET_EVENT_TITLES_FAILURE:
        return [];
      default:
        return state;
    }
  },
  getEventGroups: (state = [], action) => {
    switch (action.type) {
      case types.GET_EVENT_GROUP_SUCCESS:
        return action.data;
      case types.GET_EVENT_GROUP_FAILURE:
        return [];
      default:
        return state;
    }
  },
  getEventFileNumbers: (state = [], action) => {
    switch (action.type) {
      case types.GET_EVENT_FILENUMBER_SUCCESS:
        return action.data;
      case types.GET_EVENT_FILENUMBER_FAILURE:
        return [];
      default:
        return state
    }
  },
  getEventsMemberView: (state = [], action) => {
    switch (action.type) {
      case types.GET_EVENTMEMBERVIEW_SUCCESS:
        return action.data;
      case types.GET_EVENTMEMBERVIEW_FAILURE:
        return [];
      default:
        return state;
    }
  },
  paginationEventMemberView: (
    state = {count: 0, registered: 0, limit: 50, skip: 0, filter: '', columnSort: 'date', descending: false, futureEvents: ''},
    action
  ) => {
    switch (action.type) {
      case types.ADD_EVENTMEMBERVIEW_PAGINATION:
        return {...state, ...action.data};
      case types.RESET_EVENTMEMBERVIEW_PAGINATION:
        return {};
      default:
        return state;
    }
  },
  paginationEventFileNumber: (
    state = {count: 0, limit: 10, skip: 0},
    action
  ) => {
    switch (action.type) {
      case types.ADD_EVENTFILENUMBER_PAGINATION:
        return {...state, ...action.data};
      case types.RESET_EVENTFILENUMBER_PAGINATION:
        return {};
      default:
        return state;
    }
  },
  paginationEventGroup: (
    state = {count: 0, limit: 10, skip: 0},
    action
  ) => {
    switch (action.type) {
      case types.ADD_EVENTGROUP_PAGINATION:
        return {...state, ...action.data};
      case types.RESET_EVENTGROUP_PAGINATION:
        return {};
      default:
        return state;
    }
  },
  paginationEventTitle: (
    state = {count: 0, limit: 10, skip: 0},
    action
  ) => {
    switch (action.type) {
      case types.ADD_EVENTTITLE_PAGINATION:
        return {...state, ...action.data};
      case types.RESET_EVENTTITLE_PAGINATION:
        return {};
      default:
        return state;
    }
  },
   getEventReminders: (state = [], action) => {
    switch (action.type) {
      case types.GET_EVENT_REMINDER_SUCCESS:
        return action.data;
      case types.GET_EVENT_REMINDER_FAILURE:
        return [];
      default:
        return state;
    }
  },
};
