import React, {useState} from 'react';
import {Button} from 'reactstrap';
import {UserForm, UserList} from '../index';
import {connect} from 'react-redux';
import {myDispatch, registerDispatch} from '../../actions/util';
import * as types from '../../types';
import {isAllow} from './../../util/userActions';
import { useHistory, useLocation } from "react-router-dom";

const UserContainer = ({showUserForm, setToggleForm}) => {

  let history = useHistory();
  let location = useLocation();

  const [isActive, setIsActive] = useState(true);
  const containerStyle = {
    padding: '20px 0'
  };
  return (
    <div>
      {!(showUserForm && (location.hash === "#edit-user" || location.hash === "#add-user")) || isActive ? (
        <div style={containerStyle}>
          {isAllow('AddUser') && <Button
            color='primary'
            size='sm'
            className='float-right'
            onClick={() => {
              history.push({...location, hash: "add-user"});
              setToggleForm();
              setIsActive(false);
            }}
          >
            + New User
          </Button>
          }
          <h2>User List</h2>
          <UserList setIsActive={setIsActive}/>
        </div>
      ) : (
        <UserForm/>
      )}
    </div>
  );
};
const mapStateToProps = ({showUserForm}) => ({
  showUserForm
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    setToggleForm: () => {
      dispatch(myDispatch(types.TOGGLE_USER_FROM));
      dispatch(myDispatch(types.RESET_USER));
      dispatch(myDispatch(types.CREATE_USER));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(UserContainer);
