import React from 'react';
import {myDispatch, registerDispatch} from '../../actions/util';
import {connect} from 'react-redux';
import * as types from '../../types';
import {CustomInput, FormGroup} from 'reactstrap';

const SearchMemberGroupItems = ({
                                  member,
                                  index,
                                  memberList,
                                  setMemberList
                                }) => {

  let isAlreadyIn = false;
  for (let i in memberList) {
    if (memberList[i] && member.id === memberList[i].id) {
      isAlreadyIn = true;
      break;
    }
  }

  const handleOnClick = () => {
    if (!isAlreadyIn) {
      setMemberList([...memberList, member])
    } else {
      let tmp = [];
      for (let i in memberList) {
        if (memberList[i] && member.id !== memberList[i].id) {
          tmp.push(memberList[i])
        }
      }
      setMemberList(tmp)
    }
    //  getEventsAction(10, 0, '', !viewPast);
  };

  return (
    <tr>
      <td id={'member' + index}>
        <FormGroup style={{height: '0px', paddingLeft: '15px'}}>
          <CustomInput
            type='checkbox'
            id={'viewPastEvents' + index}
            inline
            checked={isAlreadyIn}
            onClick={handleOnClick}
          />
        </FormGroup>
      </td>
      <td id={'member' + index}>
        {member.frstname}
      </td>
      <td id={'member' + index}>
        {member.lastname}
      </td>
      <td className='text-right'>
        {member.fileno}
      </td>
      <td>
        {member.title}
      </td>
      <td>
        {member.sitecode}
      </td>
    </tr>
  );
};
const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    updateGroup: group => {
      dispatch(myDispatch(types.ADD_GROUP, {data: group}));
      dispatch(myDispatch(types.UPDATE_GROUP));
    },

    // deleteGroupAction
  };
};

export default connect(
  undefined,
  mapDispatchProps
)(SearchMemberGroupItems);
