import React, {useContext} from 'react';
import {Col, Input, Row} from 'reactstrap';

const Hour = ({label, value, context}) => {
  const onChange = useContext(context);

  let hourValue = value.substr(0, 2);
  let minutes = value.substr(3, 2);
  let session = value.substr(6, 2);

  const hoursArray = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12'
  ];

  if (hourValue.trim().length !== 2) {
    hourValue = hoursArray[0];
  }

  const minutesArray = ['00', '15', '30', '45'];

  if (minutes.trim().length !== 2) {
    minutes = minutesArray[0];
  }

  if (session.trim().length !== 2) {
    session = "am";
  }

  const buildTime = (hour, min, session) => {
    onChange(hour + ':' + min + ' ' + session);
  };

  return (
    <Row noGutters style={{marginBottom: '5px'}}>
      <Col>
        <Input
          type='select'
          name='hour'
          value={hourValue}
          onChange={e => {
            const value = e.target.value;
            buildTime(value, minutes, session);
          }}
        >
          {hoursArray.map((hour, index) => (
            <option key={'hour-option-' + index}>{hour}</option>
          ))}
        </Input>
      </Col>
      <Col style={{marginLeft: '5px'}}>
        <Input
          type='select'
          name='month'
          value={minutes}
          onChange={e => {
            buildTime(hourValue, e.target.value, session);
          }}
        >
          {minutesArray.map((value, index) => (
            <option key={'minutes-option-' + index}>{value}</option>
          ))}
        </Input>
      </Col>
      <Col style={{marginLeft: '5px'}}>
        <Input
          type='select'
          name='session'
          value={session}
          onChange={e => {
            buildTime(hourValue, minutes, e.target.value);
          }}
        >
          <option>am</option>
          <option>pm</option>
        </Input>
      </Col>
      {label && <span style={{padding: '5px 15px'}}>{label}</span>}
    </Row>
  );
};

export default Hour;
