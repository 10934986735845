import React, {useEffect, useState} from 'react';
import {Button, Col, FormGroup, Label, ListGroup, ListGroupItem, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {addRolAction, deleteRoleUserAction, getRolesAction, getRolesUserAction} from '../../actions/user';
import {registerDispatch} from '../../actions/util';
import {connect} from 'react-redux';
import Select from 'react-select';
import confirm from 'reactstrap-confirm';

const UserRolesList = ({
                         user,
                         roles,
                         rolesuser,
                         className,
                         getRolesAction,
                         addRolAction,
                         deleteRoleUserAction,
                         getRolesUserAction,
                         index
                       }) => {
  const sectionStyle = {
    border: '1px solid #e9e9e9',
    borderRadius: '6px',
    padding: '.75rem .75rem 0',
    marginBottom: '5px',
    marginTop: '10px'
  };
  const customStyles = {
    control: base => ({
      ...base,
      minHeight: 30
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: 4
    }),
    clearIndicator: base => ({
      ...base,
      padding: 4
    }),
    multiValue: base => ({
      ...base
    }),
    valueContainer: base => ({
      ...base,
      padding: '0px 6px'
    }),
    input: base => ({
      ...base,
      margin: 0,
      padding: 0
    })
  };
  useEffect(() => {
    getRolesAction();
    // eslint-disable-next-line
  }, [getRolesAction]);

  useEffect(() => {
    if (user.eliId) {
      getRolesUserAction(user.eliId);
    }
    // eslint-disable-next-line
  }, [getRolesUserAction, user]);

  let selectItems = [];

  const isAlready = rol => {
    for (let i in rolesuser) {
      if (rol.name === rolesuser[i]) {
        return true;
      }
    }
    return false;
  };

  if (roles.length) {
    for (let i in roles) {
      if (roles[i].name === 'GobalUser' || roles[i].name === 'SuperUser' || roles[i].name === 'RegularUser')
        if (!isAlready(roles[i])) {
          selectItems.push({label: roles[i].name, value: roles[i].name});
        }
    }
  }

  // if (rolesuser.length) {
  //   for (let i in rolesuser) {
  //     selectroles.push(
  //       <ListGroupItem key={'roles-item-' + i}>
  //         {rolesuser[i]}
  //         <UncontrolledDropdown style={{ display: 'inline' }}>
  //           <DropdownToggle
  //             id={'tooltip-rol-delete' + index}
  //             caret
  //             color='danger'
  //             className='ml-2 float-right'
  //           >
  //             <FontAwesomeIcon icon={faEraser} />
  //           </DropdownToggle>
  //           <DropdownMenu>
  //             <DropdownItem
  //               className='text-danger cursor-pointer'
  //               onClick={() => {
  //                 deleteRoleUserAction(user.eliId, rolesuser[i]);
  //               }}
  //             >
  //               Confirm
  //             </DropdownItem>
  //           </DropdownMenu>
  //         </UncontrolledDropdown>
  //       </ListGroupItem>
  //     );
  //   }
  // }

  const [rolName, setRolName] = useState();
  return (
    <div>
      <Row style={{margin: '0px 0px 10px'}}>
        <h5>Roles List</h5>
      </Row>
      <div style={sectionStyle}>
        <FormGroup row>
          <Label sm={2}>Roles</Label>
          <Col sm={8} style={{marginTop: '5px'}}>
            <Select
              styles={customStyles}
              options={selectItems}
              value={rolName}
              onChange={(opt, e) => {
                setRolName(opt);
              }}
            />
          </Col>
          <Col sm={2} style={{marginTop: '5px'}}>
            <Button
              style={{width: '100%'}}
              color='primary'
              disabled={!rolName}
              outline
              onClick={() => {
                addRolAction(user.eliId, rolName.value, () => {
                  //setRolName({ label: '', value: '' });
                  setRolName('');
                });
              }}
            >
              Add
            </Button>
          </Col>
        </FormGroup>
      </div>
      <div>
        <ListGroup>
          {rolesuser.map((e, i) => {
            return (
              <ListGroupItem key={'roles-item-' + i}>
                {e}
                <FontAwesomeIcon
                  className='float-right'
                  style={{
                    color: 'rgba(27,31,35,.3)',
                    cursor: 'pointer',
                    marginTop: '3px'
                  }}
                  onClick={async () => {
                    const result = await confirm({
                      title: "Remove",
                      message:
                        'Are you sure that you want to remove this role from the list?',
                      confirmText: 'Yes',
                      cancelText: 'No'
                    });
                    if (result) {
                      deleteRoleUserAction(user.eliId, e);
                    }
                  }}
                  icon={faTimes}
                />
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </div>
    </div>
  );
};
const mapStateToProps = ({isFetching, getRoles, getRolesUser, user}) => ({
  isFetching,
  roles: getRoles,
  rolesuser: getRolesUser,
  user: user
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    getRolesAction,
    getRolesUserAction,
    addRolAction,
    deleteRoleUserAction
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(UserRolesList);
