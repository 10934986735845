import * as types from "../types";

export const instructor = {
  CRUDInstructor: (state = types.CREATE_INSTRUCTOR, action) => {
    switch (action.type) {
      case types.GET:
        return types.GET;
      case types.CREATE_INSTRUCTOR:
        return types.CREATE_INSTRUCTOR;
      case types.UPDATE_INSTRUCTOR:
        return types.UPDATE_INSTRUCTOR;
      case types.DELETE_INSTRUCTOR:
        return types.DELETE_INSTRUCTOR;
      default:
        return state
    }
  },
  formInstructorId: (state = 1, action) => {
    switch (action.type) {
        case types.CREATE_INSTRUCTOR:
        return state + 1;     
      default:
        return state
    }
  },
  showInstructorForm: (state = false, action) => {
    switch (action.type) {
      case types.GET:
        return false;
      case types.CREATE_INSTRUCTOR:
        return true;
      case types.UPDATE_INSTRUCTOR:
        return true;
      case types.GET_INSTRUCTOR_SUCCESS:
        return false;
      case types.TOGGLE_INSTRUCTOR_FROM:
        return !state;
      default:
        return state
    }
  },
  getInstructors: (state = [], action) => {
    switch (action.type) {
      case types.GET_INSTRUCTOR_SUCCESS:
        return action.data;
      case types.GET_INSTRUCTOR_FAILURE:
        return [];
      default:
        return state
    }
  },

  getExpertises: (state = [], action) => {
    switch (action.type) {
      case types.GET_EXPERTISE_SUCCESS:
        return action.data;
      case types.GET_EXPERTISE_FAILURE:
        return [];
      default:
        return state
    }
  },

  getExpertisesInstructor: (state = [], action) => {
    switch (action.type) {
      case types.GET_EXPERTISE_INSTRUCTOR_SUCCESS:
        return action.data;
      case types.GET_EXPERTISE_INSTRUCTOR_FAILURE:
        return [];
      default:
        return state
    }
  },

  getGradeLevels: (state = [], action) => {
    switch (action.type) {
      case types.GET_GRADELEVEL_SUCCESS:
        return action.data;
      case types.GET_GRADELEVEL_FAILURE:
        return [];
      default:
        return state
    }
  },
  getGradeLevelsInstructor: (state = [], action) => {
    switch (action.type) {
      case types.GET_GRADELEVELS_INSTRUCTOR_SUCCESS:
        return action.data;
      case types.GET_GRADELEVELS_INSTRUCTOR_FAILURE:
        return [];
      default:
        return state
    }
  },

  instructor: (state = {}, action) => {
    switch (action.type) {
      case types.ADD_INSTRUCTOR:
        return action.data;
      case types.RESET_INSTRUCTOR:
        return {};
      default:
        return state
    }
  },

  paginationInstructor: (state = {
    count: 0, limit: 10, skip: 0, filterName: ""
  }, action) => {
    switch (action.type) {
      case types.ADD_INSTRUCTOR_PAGINATION:
        return {
          ...state,
          ...action.data
        };
      case types.RESET_INSTRUCTOR_PAGINATION:
        return {};
      default:
        return state
    }
  }
};
