import React from 'react';
import {Modal, ModalBody, ModalHeader, Row} from 'reactstrap';
import {getDateValue1, myDispatch, registerDispatch} from '../../actions/util';
import {connect} from 'react-redux';
import * as types from '../../types';
import StarRatings from 'react-star-ratings';
import {getWorkShopAction} from '../../actions/workshop';

const WorkshopsModal = ({workshop, isModal, toggleModal, nextWorkshopId, setNextWorkshopId}) => {

  const customModal = (data) => {
    toggleModal(data)
  };
  return (

    <Modal isOpen={isModal === types.MODAL_WORKSHOP_VERSION} toggle={customModal}>
      <ModalHeader className='card-header' toggle={customModal}>
        Detail Version
      </ModalHeader>
      <ModalBody>
        <Row style={{padding: '10px'}}>
          <div className='col-12'><b>Title:</b> <span style={{color: 'gray', fontWeight: 'normal'}}> {workshop.title}</span></div>
          <div className='col-12 mt-2'><b>Create Date:</b> <span style={{color: 'gray', fontWeight: 'normal'}}> {getDateValue1(workshop.createDate)}</span></div>
          <div className='col-12 mt-2'>
            <b style={{verticalAlign: "middle"}}>Rating:</b> {" "}
            <StarRatings
              rating={workshop.rating}
              starRatedColor='blue'
              starDimension='20px'
              starSpacing='3px'
              numberOfStars={5}
              name='rating'
              //style={{marginLeft: '4px',marginButton: '4px'}}
            />
          </div>
          <div className='col-12 mt-2'><b>Detail:</b><span style={{textAlign: 'justify', color: 'gray', fontWeight: 'normal'}}>  {workshop.detail}</span></div>

          <Row className="col-12 mt-3">
            <div className="col-6">
              {!!workshop.parentId && <span
                className="simulate-a mt-4"
                onClick={() => {
                  getWorkShopAction(workshop.parentId, () => {
                    toggleModal(types.MODAL_WORKSHOP_VERSION)
                    setNextWorkshopId([workshop.id, ...nextWorkshopId])
                  })
                }}
              >
              Previous Version
            </span>}
            </div>
            <div className="col-6 text-right">
              {nextWorkshopId[0] && <span
                className="simulate-a mt-4"
                onClick={() => {
                  if (nextWorkshopId[0]) {
                    getWorkShopAction(nextWorkshopId[0], () => {
                      setNextWorkshopId(nextWorkshopId.filter(w => w !== nextWorkshopId[0]))
                    })
                  }
                }}
              >
              Next Version
            </span>}
            </div>
          </Row>
        </Row>

      </ModalBody>
    </Modal>
  );
};
const mapStateToProps = ({isRetiree, workshop, isModal}) => ({
  isRetiree,
  workshop,
  isModal
});
const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    toggleModal: modalName => {
      dispatch(myDispatch(types.TOGGLE_MODAL, {data: modalName}));
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchProps
)(WorkshopsModal);