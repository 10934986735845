import * as types from "../types";

export const location = {
  CRUDLocation: (state = types.CREATE_LOCATION, action) => {
    switch (action.type) {
      case types.GET:
        return types.GET;
      case types.CREATE_LOCATION:
        return types.CREATE_LOCATION;
      case types.UPDATE_LOCATION:
        return types.UPDATE_LOCATION;
      case types.DELETE_LOCATION:
        return types.DELETE_LOCATION;
      default:
        return state
    }
  },
  showLocationForm: (state = false, action) => {
    switch (action.type) {
      case types.GET:
        return false;
      case types.CREATE_LOCATION:
        return true;
      case types.UPDATE_LOCATION:
        return true;
      case types.GET_LOCATION_SUCCESS:
        return false;
      case types.TOGGLE_LOCATION_FROM:
        return !state;
      default:
        return state
    }
  },

  getLocations: (state = [], action) => {
    switch (action.type) {
      case types.GET_LOCATION_SUCCESS:
        return action.data;
      case types.GET_LOCATION_FAILURE:
        return [];
      default:
        return state
    }
  },
  location: (state = {}, action) => {
    switch (action.type) {
      case types.ADD_LOCATION:
        return action.data;
      case types.RESET_LOCATION:
        return {};
      default:
        return state
    }
  },
  paginationLocation: (state = {count: 0, limit: 10, skip: 0, filterName: ""}, action) => {
    switch (action.type) {
      case types.ADD_LOCATION_PAGINATION:
        return {...state, ...action.data};
      case types.RESET_LOCATION_PAGINATION:
        return {};
      default:
        return state
    }
  }
};