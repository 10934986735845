import React from 'react';
import {Button} from 'reactstrap';
import {GroupForm, GroupList, SuperUser} from '../index';
import {connect} from 'react-redux';
import {myDispatch, registerDispatch} from '../../actions/util';
import * as types from '../../types';
import { useHistory, useLocation } from "react-router-dom";

const GroupContainer = ({
                          showGroupForm,
                          setToggleForm,
                          isActive,
                          setIsActive
                        }) => {

  let history = useHistory();
  let location = useLocation();

  const containerStyle = {
    padding: '20px 0'
  };
  return (
    <div>
      {!(showGroupForm && (location.hash === "#add-group" || location.hash === "#edit-group")) || isActive ? (
        <div style={containerStyle}>
          <SuperUser>
            <Button
              color='primary'
              size='sm'
              style={{marginTop: '4px'}}
              className='float-right'
              onClick={() => {
                history.push({...location, hash: "add-group"});
                setToggleForm();
                setIsActive(false);
              }}
            >
              + New Group
            </Button>
          </SuperUser>
          <h2>Groups</h2>
          <GroupList setIsActive={setIsActive}/>
        </div>
      ) : (
        <GroupForm/>
      )}
    </div>
  );
};

const mapStateToProps = ({showGroupForm}) => ({
  showGroupForm
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    setToggleForm: () => {
      dispatch(myDispatch(types.TOGGLE_GROUP_FROM));
      dispatch(myDispatch(types.RESET_GROUP));
      dispatch(myDispatch(types.CREATE_GROUP));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(GroupContainer);
