import React from 'react';
import {Button} from 'reactstrap';
import {InstructorForm, InstructorLists, SuperUser} from '../index';
import {connect} from 'react-redux';
import {myDispatch, registerDispatch} from '../../actions/util';
import * as types from '../../types';
import { useHistory, useLocation } from "react-router-dom";

const InstructorContainer = ({
                               showInstructorForm,
                               setToggleForm,
                               isActive,
                               setIsActive
                             }) => {

  let history = useHistory();
  let location = useLocation();

  const containerStyle = {
    padding: '20px 0'
  };

  return (
    <div>
      {!(showInstructorForm && (location.hash === "#edit-instructor" || location.hash === "#add-instructor")) || isActive ? (
        <div style={containerStyle}>
          <SuperUser>
            <Button
              color='primary'
              size='sm'
              style={{marginTop: '4px'}}
              className='float-right'
              onClick={() => {
                history.push({...location, hash: "add-instructor"});
                setToggleForm();
                setIsActive(false);
              }}
            >
              + New Instructor
            </Button>
          </SuperUser>
          <h2>Instructors</h2>
          <InstructorLists setIsActive={setIsActive}/>
        </div>
      ) : (
        <InstructorForm/>
      )}
    </div>
  );
};

const mapStateToProps = ({showInstructorForm}) => ({
  showInstructorForm
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    setToggleForm: () => {
      dispatch(myDispatch(types.TOGGLE_INSTRUCTOR_FROM));
      dispatch(myDispatch(types.RESET_INSTRUCTOR));
      dispatch(myDispatch(types.CREATE_INSTRUCTOR));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(InstructorContainer);
