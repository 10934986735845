export const FORM_REACT_REDUX_RESET = "FORM_REACT_REDUX_RESET";
export const FORM_REACT_REDUX_CHANGE = "FORM_REACT_REDUX_CHANGE";
export const FORM_REACT_REDUX_CHANGE_VALUE = "FORM_REACT_REDUX_CHANGE_VALUE";
export const FORM_REACT_REDUX_UNDO = "FORM_REACT_REDUX_UNDO";
export const FORM_REACT_REDUX_REDO = "FORM_REACT_REDUX_REDO";
export const SHOW_DATA = "SHOW_DATA";
export const HIDE_DATA = "HIDE_DATA";
export const TOGGLE_DATA = "TOGGLE_DATA";
export const FIRE_BLUR = "FIRE_BLUR";
export const RESET_BLUR = "RESET_BLUR";
