import React, {useState} from 'react';
import FormHook from '../../util/FormHook/FormHook';
import {Button, CustomInput, FormGroup, Label} from 'reactstrap';
import {loginUserAction} from '../../actions/user';
import {connect} from 'react-redux';
import {registerDispatch} from '../../actions/util';

const LoginForm = ({isFetching, loginUserAction, user, history}) => {
  const [rememberMe, setRememberMe] = useState(
    !!localStorage.getItem('rememberMe')
  );

  return (
    <FormHook
      submit={values => {
        loginUserAction(values.fileNumber, values.lastName, rememberMe, history);
      }}
      defaultValues={
        rememberMe ? {fileNumber: user.user, lastName: user.password} : {}
      }
      className='col-12 col-sm-6 offset-sm-3 col-md-4 offset-md-4 mt-4'
    >
      <h4>Member Registration </h4>
      <FormGroup className='mt-2'>
        <Label>File No.</Label>
        <input maxLength='7' name='fileNumber' required/>
      </FormGroup>
      <FormGroup>
        <Label>Last Name</Label>
        <input maxLength='20' name='lastName' required/>
      </FormGroup>
      <FormGroup>
        <CustomInput
          type='checkbox'
          id='rememberMe'
          label='Remember me'
          inline
          checked={rememberMe}
          onChange={e => {
            setRememberMe(e.target.checked);
          }}
        />
      </FormGroup>
      <div style={{alignItems: 'center'}}>
        <Button color='primary' size='sm'>
          Sign in {isFetching ? '...' : ''}
        </Button>
        {/* <span className='ml-2'>or </span>
        <Link to={paths.LoginRetireeForm} style={{ color: '#17a2b8' }}>
          Retiree Login
        </Link>
        */}
      </div>
    </FormHook>
  );
};

const mapStateToProps = ({user, isFetching}) => ({
  user,
  isFetching
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    loginUserAction
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(LoginForm);
