import React, {useState} from 'react';
import {Button, InputGroup, InputGroupAddon, Pagination, PaginationItem, PaginationLink} from 'reactstrap';
import {getUniqueKey} from "../../util/FormReactRedux/FormReactRedux";

const MyPagination = ({count, limit, skip, action}) => {

  const [go, setGo] = useState('');

  let items = [], cantItems = 10, actualPage = skip / limit, pages = count / limit, firstItem = actualPage - parseInt(limit / 2, 10);

  for (let i = firstItem < 0 ? 0 : firstItem; i < pages; i++) {

    if (cantItems > 0) {
      items.push(
        <PaginationItem key={getUniqueKey()} active={i === actualPage} onClick={() => {
          action(limit * i)
        }}>
          <PaginationLink>
            {i + 1}
          </PaginationLink>
        </PaginationItem>);
      cantItems--;
    }

  }

  return (
    <div className="my-pagination">
      <div style={{marginTop: "8px"}}>Page {actualPage + 1} of {(/\./.test(pages.toString()) ? parseInt(pages, 10) + 1 : parseInt(pages, 10))}</div>

      <Pagination>

        <InputGroup style={{width: "120px", marginRight: "30px", height: "36px"}}>
          <input type="text" className="form-control" value={go} onChange={e => {
            const value = e.target.value;
            if (!value || (/^\d+$/.test(value) && (/\./.test(pages.toString()) ? parseInt(pages, 10) : parseInt(pages, 10) - 1) >= value - 1)){
              setGo(value)
            }
          }} style={{marginRight: "-4px", height: "36px"}} placeholder="Page"
                 onKeyUp={(e) => {
                   if (e.keyCode === 27) {
                     action(0);
                     setGo('');
                   }else {
                     const value = e.target.value;
                     if (e.keyCode === 13) {
                       if (!value) {
                         action(0)
                       } else {
                         action(limit * (value - 1))
                       }
                     }
                   }
                 }}/>
          <InputGroupAddon addonType="prepend"><Button style={{padding: "5px 10px"}} onClick={() => {
            if (!go) {
              action(0)
            } else {
              action(limit * (go - 1))
            }
          }}>Go</Button></InputGroupAddon>
        </InputGroup>

        {items}

        <PaginationItem>
          <PaginationLink disabled={actualPage - 1 < 0} onClick={() => {
            action(0)
          }}>{"«"}</PaginationLink>
        </PaginationItem>

        <PaginationItem>
          <PaginationLink disabled={actualPage - 1 < 0} onClick={() => {
            action(limit * (actualPage - 1))
          }}>{"‹"}</PaginationLink>
        </PaginationItem>

        <PaginationItem>
          <PaginationLink disabled={actualPage + 1 >= pages} onClick={() => {
            action(limit * (actualPage + 1))
          }}>{"›"}</PaginationLink>
        </PaginationItem>
        <PaginationItem>
          <PaginationLink disabled={actualPage + 1 >= pages} onClick={() => {
            action(limit * (/\./.test(pages.toString()) ? parseInt(pages, 10) : parseInt(pages, 10) - 1))
          }}>{"»"}</PaginationLink>
        </PaginationItem>
      </Pagination>
    </div>
  );
};

export default MyPagination;
