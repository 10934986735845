import * as types from "../types";
import {dispatch, getTodayStringValue} from "./util";
import {usermanagerService, userService} from "../services";
import {saveAs} from "file-saver";
import messages from "../util/messages";

export const loginUserAction = (fileNumber, lastName, rememberMe, history) => {
  dispatch(types.FETCHING);
  userService().loginUser({fileNumber, lastName}).then(res => {
    const user = {
      fileNumber,
      lastName,
      rol: "Member"
    };
    localStorage.setItem('user', user.fileNumber);
    if (rememberMe) {
      localStorage.setItem('ELI_tokenAuth', res.data.token);
      localStorage.setItem('rememberMe', "true");
    } else {
      localStorage.removeItem('rememberMe');
      sessionStorage.setItem('ELI_tokenAuth', res.data.token);
    }
    localStorage.setItem('rol', user.rol);
    dispatch(types.FETCHING_END);
    dispatch(types.RESET_RETIREE_LOGIN);
    dispatch(types.LOGIN_SUCCESS);
    dispatch(types.ADD_USER, {data: user});
    history.push({pathname: '/'});
  }).catch((err) => {
    dispatch(types.ADD_ALERT_NOTIFICATION, {message: (err && err.response && err.response.data ? err.response.data : messages[types.LOGIN_FAILURE]), color: 'danger'});
    dispatch(types.FETCHING_END);
    dispatch(types.LOGIN_FAILURE);
  });
};

export const loginUserRetireeAction = (userName, password, rememberMe, history) => {
  dispatch(types.FETCHING);
  userService().loginUserRetiree({userName, password}).then(res => {
    const user = {
      userName,
      password,
      rol: res.data.roles
    };
    localStorage.setItem('userRetiree', user.userName);
    localStorage.setItem('userRetireeId', res.data.eliId);
    if (rememberMe) {
      localStorage.setItem('rememberMeRetiree', "true");
      localStorage.setItem('ELI_tokenAuth', res.data.token);
    } else {
      localStorage.removeItem('rememberMeRetiree');
      sessionStorage.setItem('ELI_tokenAuth', res.data.token);
    }
    localStorage.setItem('rol', user.rol);
    localStorage.setItem('retiree', "true");
    dispatch(types.FETCHING_END);
    dispatch(types.LOGIN_SUCCESS);
    dispatch(types.RETIREE_LOGIN);
    dispatch(types.ADD_RETIREE_USER, {data: user});
    history.push({pathname: '/'});
  }).catch((err) => {
    dispatch(types.ADD_ALERT_NOTIFICATION, {message: (err && err.response && err.response.data ? err.response.data : messages[types.LOGIN_FAILURE]), color: 'danger'});
    dispatch(types.FETCHING_END);
    dispatch(types.RESET_RETIREE_LOGIN);
    dispatch(types.LOGIN_FAILURE);
  });
};
export const addUserAction = (name, username, email, mobilePhone, iS_ACTIVE, externaL_ID, password) => {
  dispatch(types.FETCHING);
  usermanagerService().addUser({
    Name: name,
    userName: username,
    email: email,
    mobilePhone: mobilePhone,
    IS_ACTIVE: iS_ACTIVE,
    EXTERNAL_ID: externaL_ID,
    password: password
  }).then(res => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_USER_SUCCESS, {
      data: {
        name,
        username,
        email,
        mobilePhone,
        externaL_ID,
        iS_ACTIVE,
        password
      }
    });
    dispatch(types.TOGGLE_USER_FROM);
    dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.ADD_USER_SUCCESS], color: 'info'});

  }).catch((err, a) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_USER_FAILURE);
    dispatch(types.ADD_ALERT_NOTIFICATION, {message: err && err.response && err.response.data ? err.response.data : messages[types.ADD_USER_FAILURE], color: 'danger'});

  });
};
export const getUserAction = (id) => {
  dispatch(types.FETCHING);
  usermanagerService().getUser({id}).then(res => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_USER, res);
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_USER, {data: {}});
  });
};

let cancelGetUsersAction = false;
export const cancelGetUsersActionFn = (option = false) => {cancelGetUsersAction = option};

export const getUsersAction = (limit, skip, filter, columnSort, descending) => {
  dispatch(types.FETCHING);
  usermanagerService().getUsers(limit, skip, filter, columnSort, descending).then(res => {
    if (!cancelGetUsersAction) {
      dispatch(types.GET_USERS_SUCCESS, res);
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_USER_PAGINATION, {
        data: {
          count: parseInt(res.headers['x-total-count'], 10),
          limit: parseInt(res.headers['x-total-limit'], 10),
          skip: parseInt(res.headers['x-total-skip'], 10),
          filter, columnSort, descending
        }
      });
    } else {
      cancelGetUsersAction = false
    }
  }).catch((err) => {
    if (!cancelGetUsersAction) {
      dispatch(types.FETCHING_END);
    } else {
      cancelGetUsersAction = false
    }
    console.log(err)
  });
};

export const updateUserAction = (user) => {
  dispatch(types.FETCHING);
  usermanagerService().updateUser({
    Name: user.name,
    userName: user.userName,
    email: user.email,
    mobilePhone: user.mobilePhone,
    iS_ACTIVE: user.iS_ACTIVE,
    external_id: user.externaL_ID,
    password: user.password
  }, user.eliId).then(res => {
    dispatch(types.UPDATE_USER_SUCCESS, {});
    dispatch(types.FETCHING_END);
    dispatch(types.TOGGLE_USER_FROM);
    dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.UPDATE_USER_SUCCESS], color: 'info'});
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {message: err && err.response && err.response.data ? err.response.data : messages[types.UPDATE_USER_FAILURE], color: 'danger'});
  });
};

export const deleteUserAction = (id) => {
  dispatch(types.FETCHING);
  usermanagerService().deleteUser(id).then(res => {
    dispatch(types.FETCHING_END);
    getUsersAction(10, 0)
    dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.DELETE_USER_SUCCESS], color: 'info'});
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {message: err && err.response && err.response.data ? err.response.data : messages[types.DELETE_USER_FAILURE], color: 'danger'});
  });
};

export const deleteRoleUserAction = (userid, rol) => {
  dispatch(types.FETCHING);
  usermanagerService().deleteRoleUser(userid, rol).then(res => {
    dispatch(types.FETCHING_END);
    getRolesUserAction(userid);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'Delete role',
      color: 'info'
    });
    // dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.DELETE_SITE_SUCCESS], color: 'info'});
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'Delete role',
      color: 'danger'
    });
    // dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.DELETE_SITE_FAILURE], color: 'danger'});
  });
};

export const getRolesUserAction = (id) => {
  dispatch(types.FETCHING);
  usermanagerService().getRolesUser(id).then(res => {
    dispatch(types.GET_ROL_USER_SUCCESS, res);
    dispatch(types.FETCHING_END);
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    console.log(err)
  });
};

export const getRolesAction = () => {
  dispatch(types.FETCHING);
  usermanagerService().getRoles().then(res => {
    dispatch(types.GET_ROL_SUCCESS, res);
    dispatch(types.FETCHING_END);
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    console.log(err)
  });
};
export const addRolAction = (id, rol, cb) => {
  dispatch(types.FETCHING);
  usermanagerService().addRol(id, rol).then(res => {
    dispatch(types.FETCHING_END);
    getRolesAction();
    getRolesUserAction(id);
    cb();
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'Add role',
      color: 'info'
    });
  }).catch((err) => {
    console.log(err);
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ROL_FAILURE);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'Add role',
      color: 'danger'
    });
  });
};

export const getUsersExcelAction = (filter) => {
  dispatch(types.FETCHING);
  usermanagerService().getUsersExcel(filter).then(res => {
    dispatch(types.FETCHING_END);
    saveAs(res.data, "Users" + getTodayStringValue() + ".xlsx");
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'Users file downloaded',
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'Users file downloaded',
      color: 'info'
    });
  });
};

export const refreshTokenAction = () => {

  userService().refreshToken().then(res => {
    addToken(res.data.token);
  }).catch((err) => {
    removeToken()
    localStorage.removeItem('retiree');
    // dispatch(types.LOGIN_FAILURE);
    window.location = '/';
  });
};

export const isRememberMe = () => {
  const isRetiree = !!localStorage.getItem('retiree');
  return !!localStorage.getItem(isRetiree ? 'rememberMeRetiree' : 'rememberMe');
};

export const removeToken = () => {
  if (isRememberMe()) {
    localStorage.removeItem('ELI_tokenAuth');
  } else {
    sessionStorage.removeItem('ELI_tokenAuth');
  }
};

export const isLogin = () => {
  if (isRememberMe()) {
    return !!localStorage.getItem('ELI_tokenAuth');
  }
  return !!sessionStorage.getItem('ELI_tokenAuth');
};

export const addToken = (token) => {
  if (isRememberMe()) {
    localStorage.setItem('ELI_tokenAuth', token);
  } else {
    sessionStorage.setItem('ELI_tokenAuth', token);
  }
};

export const getToken = () => {
  if (isRememberMe()) {
    return localStorage.getItem('ELI_tokenAuth');
  } else
  return sessionStorage.getItem('ELI_tokenAuth');
};

let refreshTokenInterval = 0;

clearInterval(refreshTokenInterval);
refreshTokenInterval = setInterval(() => {
  const isToken = !!localStorage.getItem('ELI_tokenAuth');
  const isRetiree = !!localStorage.getItem('retiree');
  const isRememberMe = !!localStorage.getItem(isRetiree ? 'rememberMeRetiree' : 'rememberMe');
  if (isToken && isRememberMe) {
    refreshTokenAction();
  }
}, 60 * 1000 * 60);


