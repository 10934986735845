export const myDispatch = (type, data = {}) => {
  let object = {type};
  for (let i in data) {
    object[i] = data[i];
  }
  return object;
};

let dispatchAction;

export const registerDispatch = fn => dispatchAction = fn;
export const resetDispatch = () => dispatchAction = null;
export const getDispatch = () => dispatchAction;
export const dispatch = (type, data) => dispatchAction(myDispatch(type, data));


export const dateFromISOToLocal = (date) => {
  if (!date) {
    return new Date()
  }
  if (/T/.test(date) && !/Z$/.test(date)) {
    date = date + "Z"
  }
  return new Date(date)
};

export const getDate = (date) => {
  if (!date) {
    return undefined;
  }
  return new Date(date)
};

export const getDateValue = (value) => {
  return !value ? value : "" + new Date(value).getFullYear() + getZero(new Date(value).getMonth() + 1) + "" + getZero(new Date(value).getDate());
};

export const getTodayStringValue = () => {
  const today = new Date();
  return "" + today.getFullYear() + getZero(today.getMonth() + 1) + "" + getZero(today.getDate()) + "" + getZero(today.getHours()) + "" + getZero(today.getMinutes()) + "" + getZero(today.getSeconds());
};

export const parseTimeFromDate = (date, time, defaultTime = '08:30 am') => {
  if (!(time && time.length === 8)) {
    return defaultTime
  }
  date = dateFromISOToLocal(date)
  let tmp = "", hours = date.getHours()
  if (hours === 0) {
    tmp = "12"
  } else if (hours > 12) {
    tmp = getZero(hours - 12)
  } else {
    tmp = getZero(hours)
  }
  tmp += " " + getZero(date.getMinutes()) + (hours >= 12 ? " pm" : " am")
  return tmp
};

export const parseDateFromTime = (date, time) => {
  let hours = 0
  if (time.substr(6, 2) === "am") {
    if (parseInt(time.substr(0, 2), 10) === 12) {
      hours = 0
    } else {
      hours = time.substr(0, 2)
    }
  } else {
    if (parseInt(time.substr(0, 2), 10) === 12) {
      hours = time.substr(0, 2)
    } else {
      hours = parseInt(time.substr(0, 2), 10) + 12
    }
  }
  return new Date(date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    hours,
    parseInt(time.substr(3, 2), 10));
};

export const getDateValue1 = (value) => {
  if (!value) {
    return value
  }
  value = dateFromISOToLocal(value);
  return "" + getZero(new Date(value).getMonth() + 1) + "/" + getZero(new Date(value).getDate()) + "/" + new Date(value).getFullYear();
};

export const getZero = (date) => {
  date = date.toString();
  return date.toString().length === 1 ? 0 + "" + date : date;
};
