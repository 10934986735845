import React, {useState} from 'react';
import FormHook from '../../util/FormHook/FormHook';
import {Button, CustomInput, FormGroup, Label} from 'reactstrap';
import {loginUserRetireeAction} from '../../actions/user';
import {connect} from 'react-redux';
import {registerDispatch} from '../../actions/util';
import {Link} from 'react-router-dom';

const LoginForm = ({
                     isFetching,
                     loginUserRetireeAction,
                     userRetiree,
                     history
                   }) => {
  const [rememberMe, setRememberMe] = useState(
    !!localStorage.getItem('rememberMeRetiree')
  );

  return (
    <FormHook
      submit={values => {
        loginUserRetireeAction(
          values.username,
          values.password,
          rememberMe,
          history
        );
      }}
      defaultValues={
        rememberMe
          ? {username: userRetiree.userName, password: userRetiree.password}
          : {}
      }
      className='col-12 col-sm-6 offset-sm-3 col-md-4 offset-md-4 mt-4'
    >
      <h4>ELI Registration</h4>
      <FormGroup className='mt-2'>
        <Label>Username</Label>
        <input name='username' required/>
      </FormGroup>
      <FormGroup>
        <Label>Password</Label>
        <input type='password' name='password' required/>
      </FormGroup>
      <FormGroup>
        <CustomInput
          type='checkbox'
          id='rememberMe'
          label='Remember me'
          inline
          checked={rememberMe}
          onChange={e => {
            setRememberMe(e.target.checked);
          }}
        />
      </FormGroup>
      <div style={{alignItems: 'center'}}>
        <Button color='primary' size='sm'>
          Sign in {isFetching ? '...' : ''}
        </Button>
        <span className='ml-2'>or </span>
        <Link to={'/'} style={{color: '#17a2b8'}}>
          Member Registration
        </Link>
      </div>
    </FormHook>
  );
};

const mapStateToProps = ({userRetiree, isFetching}) => ({
  userRetiree,
  isFetching
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    loginUserRetireeAction
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(LoginForm);
