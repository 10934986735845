import React from 'react';
import {dateFromISOToLocal, getDateValue1, registerDispatch} from '../../actions/util';
import {connect} from 'react-redux';
import {MyTooltip} from "../index";
import {addMemberByMemberLoginRegistrationAction} from '../../actions/member';
import StarRatings from 'react-star-ratings';
import {addRatingWorkShopAction} from '../../actions/workshop';

const EventsMemberViewItemsLast = ({index, event, addMemberByMemberLoginRegistrationAction, paginationEventMemberView}) => {

  return (
    <tr>
      <td id={'toggler-event-member-' + index} className='cursor-pointer'>
        {event.name + " at " + (event.eventLocation ? event.eventLocation.name : '')} <br/> {event.workshop.title}
      </td>
      <td id={'toggler-event-member-' + index} className='cursor-pointer'>
        {getDateValue1(event.startdate) + " to " + getDateValue1(event.enddate)} <br/>
        <div style={{color: 'gray', fontSize: '11px'}}> {event.starttime + " to " + event.endtime}</div>
      </td>
      <td style={{width: '160px'}}>
        {dateFromISOToLocal(event.enddate) > Date.now() ?
          <span>You can rate this workshop after it ends</span>
          :
        <StarRatings
          rating={event && event.workshop && event.workshop.ratings && event.workshop.ratings[0] && event.workshop.ratings[0].rating ? event.workshop.ratings[0].rating : 0}
          starRatedColor='blue'
          changeRating={value => {
            addRatingWorkShopAction(event.workshop.id, value, paginationEventMemberView);
          }}
          starDimension='20px'
          starSpacing='3px'
          numberOfStars={5}
          name='rating'
        />}
      </td>

      <MyTooltip placement="right" target={"toggler-event-member-" + index}>
        Detail View
      </MyTooltip>
    </tr>
  );
};

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    addMemberByMemberLoginRegistrationAction,
    addRatingWorkShopAction
  };
};
export default connect(undefined, mapDispatchProps)(EventsMemberViewItemsLast);
