import React, {useEffect, useState} from 'react';
import {Button, Col, FormGroup, Label, ListGroup, ListGroupItem, Row} from 'reactstrap';
import {addEventInstructorAction, deleteEventInstructorAction, getEventInstructorsAction} from '../../actions/event';
import {getInstructorsAction} from '../../actions/instructor';
import {myDispatch, registerDispatch} from '../../actions/util';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {connect} from 'react-redux';
import Select from 'react-select';
import confirm from 'reactstrap-confirm';
import * as types from "../../types";
import {SuperUser} from "../index";

const InstructorList = ({
                          event,
                          instructors,
                          getInstructorsAction,
                          eventInstructors,
                          getEventInstructorsAction,
                          index
                        }) => {
  const sectionStyle = {
    border: '1px solid #e9e9e9',
    borderRadius: '6px',
    padding: '.75rem .75rem 0',
    marginBottom: '5px',
    marginTop: '10px'
  };
  const customStyles = {
    control: base => ({
      ...base,
      minHeight: 30
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: 4
    }),
    clearIndicator: base => ({
      ...base,
      padding: 4
    }),
    multiValue: base => ({
      ...base
    }),
    valueContainer: base => ({
      ...base,
      padding: '0px 6px'
    }),
    input: base => ({
      ...base,
      margin: 0,
      padding: 0
    })
  };


  useEffect(() => {
    if (event.id) {
      getInstructorsAction(1000, 0);
      getEventInstructorsAction(event.id);
    }

    return () => {
      myDispatch(types.RESET_INSTRUCTOR);
    }

    // eslint-disable-next-line
  }, [getInstructorsAction, getEventInstructorsAction, event]);

  let selectItems = [];

  const isAlready = instructor => {
    for (let i in eventInstructors) {
      if (instructor.id === eventInstructors[i].instructorId) {
        return true;
      }
    }
    return false;
  };

  if (instructors.length) {
    for (let i in instructors) {
      if (!isAlready(instructors[i])) {
        selectItems.push({
          label: `${instructors[i].name} ${instructors[i].lastName}`,
          value: instructors[i].id
        });
      }
    }
  }


  const [instructorName, setInstructorName] = useState();
  return (
    <div>
      <Row style={{margin: '0px 0px 10px'}}>
        <h5>Instructor</h5>
      </Row>
      <div style={sectionStyle}>
        <FormGroup row>
          <Label sm={2} style={{marginTop: '5px'}}>
            Instructor
          </Label>
          <Col sm={8} style={{marginTop: '5px'}}>
            <Select
              styles={customStyles}
              options={selectItems}
              value={instructorName}
              onChange={(opt, e) => {
                setInstructorName(opt);
              }}
            />
          </Col>
          <Col sm={2} style={{marginTop: '5px'}}>
            <SuperUser>
              <Button
                style={{width: '100%'}}
                color='primary'
                outline
                disabled={!instructorName}
                onClick={() => {
                  addEventInstructorAction(event.id, instructorName.value, () => {
                    // setInstructorName({ label: '', value: '' });
                    setInstructorName('');
                  });
                }}
              >
                Add
              </Button>
            </SuperUser>
          </Col>
        </FormGroup>
        <div>
          <ListGroup className='mb-2'>
            {eventInstructors.map((e, i) => {
              return (
                <ListGroupItem key={'event-instructor-' + i}>
                  {e.user}
                  <SuperUser>
                    <FontAwesomeIcon
                      className='float-right'
                      style={{
                        color: 'rgba(27,31,35,.3)',
                        cursor: 'pointer',
                        marginTop: '3px'
                      }}
                      onClick={async () => {
                        const result = await confirm({
                          title: "Remove",
                          message:
                            'Are you sure that you want to remove this instructor from the list?',
                          confirmText: 'Yes',
                          cancelText: 'No'
                        });
                        if (result) {
                          deleteEventInstructorAction(e.id, event.id);
                        }
                      }}
                      icon={faTimes}
                    />
                  </SuperUser>
                </ListGroupItem>
              );
            })}
          </ListGroup>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({
                           isFetching,
                           event,
                           getInstructors,
                           getEventInstructors
                         }) => ({
  isFetching,
  event,
  instructors: getInstructors,
  eventInstructors: getEventInstructors
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    getInstructorsAction,
    getEventInstructorsAction
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(InstructorList);
