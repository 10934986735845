import {EventMemberContainer, Page404, RegistrationContainer, ReportView, UserContainer} from './components/index';

export const paths = {
  LoginForm: '/login-form',
  LoginRetireeForm: '/staff',
  ELIRegistration: '/eliregistration',
  RegistrationContainer: '/registration',
  UserContainer: '/users',
  EventMemberContainer: '/workshop-member-list',
  ReportView: '/report-view'
};

const routes = [
  {
    path: ['/', paths.RegistrationContainer, paths.LoginForm, paths.LoginRetireeForm, paths.ELIRegistration],
    component: RegistrationContainer,
    auth: 'retiree',
    exact: true
  },
  //{
  //  path: paths.EventsMemberViewList,
   // component: EventsMemberViewList,
  //  exact: true
 // },
  {
    path: paths.EventMemberContainer,
    component: EventMemberContainer,
    exact: true
  },
  //{
  //  path: "/",
   // component: EventsMemberViewList,
   // exact: true
 // },
  {
    path: "/",
    component: EventMemberContainer,
    exact: true
  },



  {
    path: paths.UserContainer,
    component: UserContainer,
    auth: 'retiree',
    exact: true
  },
  {
    path: paths.ReportView,
    component: ReportView,
    auth: 'retiree',
    exact: true
  }

  /*, {
    path: '/',
    component: ,
    exact: true,
    auth: true,
    roles: ['Administrator']
  },*/
  //otherwise
  , {
    path: '/:otherwise',
    component: Page404,
    auth: 'retiree',
    exact: false
  }
];

export default routes;