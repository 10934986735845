import React from 'react';
import {Button} from 'reactstrap';
import {SuperUser, WorkshopsForm, WorkshopsList} from '../index';
import {connect} from 'react-redux';
import {myDispatch, registerDispatch} from '../../actions/util';
import * as types from '../../types';
import { useHistory, useLocation } from "react-router-dom";

const WorkshopContainer = ({
                             showWorkshopForm,
                             setToggleForm,
                             isActive,
                             setIsActive
                           }) => {

  let history = useHistory();
  let location = useLocation();

  const containerStyle = {
    padding: '20px 0'
  };
  return (
    <div>
      {!(showWorkshopForm && (location.hash === "#edit-workshop" || location.hash === "#clone-workshop" || location.hash === "#add-workshop")) || isActive ? (
        <div style={containerStyle}>
          <SuperUser>
            <Button
              color='primary'
              size='sm'
              style={{marginTop: '4px'}}
              className='float-right'
              onClick={() => {
                history.push({...location, hash: "add-workshop"});
                setToggleForm();
                setIsActive(false);
              }}
            >
              + New Workshop
            </Button>
          </SuperUser>
          <h2>Workshops</h2>
          <WorkshopsList setIsActive={setIsActive}/>
        </div>
      ) : (
        <WorkshopsForm/>
      )}
    </div>
  );
};

const mapStateToProps = ({showWorkshopForm}) => ({
  showWorkshopForm
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    setToggleForm: () => {
      dispatch(myDispatch(types.TOGGLE_WORKSHOP_FROM));
      dispatch(myDispatch(types.RESET_WORKSHOP));
      dispatch(myDispatch(types.CREATE_WORKSHOP));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(WorkshopContainer);
