import {client} from '../util/createRestApiClient';

const base = "ELIUser/";
export default () => ({
    loginUser: (params) => client.request({
      method: 'POST',
      url: base + 'Login',
      data: params
    }),
    loginUserRetiree: (params) => client.request({
      method: 'POST',
      url: base + 'LoginRetiree',
      data: params
    }),
    refreshToken: () => client.request(
      {
        method: 'POST',
        url: base + 'RefreshToken'
      }
    )
  }
);

