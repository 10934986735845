import React from 'react';
import {Button} from 'reactstrap';
import {EventsForm, EventsList, SuperUser} from '../index';
import {connect} from 'react-redux';
import {myDispatch, registerDispatch} from '../../actions/util';
import * as types from '../../types';
import { useHistory, useLocation } from "react-router-dom";

const EventsContainer = ({
                           showEventForm,
                           setToggleForm,
                           isActive,
                           setIsActive
                         }) => {

  let history = useHistory();
  let location = useLocation();

  const containerStyle = {
    padding: '20px 0'
  };
  return (
    <div>
      {!(showEventForm && (location.hash === "#edit-event" || location.hash === "#clone-event" || location.hash === "#add-event")) || isActive ? (
        <div style={containerStyle}>
          <SuperUser>
            <Button
              color='primary'
              size='sm'
              style={{marginTop: '4px'}}
              className='float-right'
              onClick={() => {
                history.push({...location, hash: "add-event"});
                setToggleForm();
                setIsActive(false);
              }}
            >
              + New Event
            </Button>
          </SuperUser>
          <h2>Events</h2>
          <EventsList setIsActive={setIsActive}/>
        </div>
      ) : (
        <EventsForm/>
      )}
    </div>
  );
};

const mapStateToProps = ({showEventForm}) => ({
  showEventForm
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    setToggleForm: () => {
      dispatch(myDispatch(types.TOGGLE_EVENT_FROM));
      dispatch(myDispatch(types.RESET_EVENT));
      dispatch(myDispatch(types.CREATE_EVENT));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(EventsContainer);
