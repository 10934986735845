import * as types from "../types";
import {dispatch, getTodayStringValue} from "./util";
import {locationService} from "../services";
import {saveAs} from "file-saver";
import messages from "../util/messages";


export const addLocationAction = (name, address) => {
  dispatch(types.FETCHING);
  locationService().addLocation({name: name, address: address}).then(res => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_LOCATION_SUCCESS, {
      data: {
        name,
        address
      }
    });
    dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.ADD_LOCATION_SUCCESS], color: 'info'});
    dispatch(types.TOGGLE_LOCATION_FROM);
    dispatch(types.RESET_LOCATION);

  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_LOCATION_FAILURE);
    dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.ADD_LOCATION_FAILURE], color: 'danger'});
  });
};
export const getLocationAction = (id, cb) => {
  dispatch(types.FETCHING);
  locationService().getLocation({id}).then(res => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_LOCATION, res);
    cb(res.data);
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_LOCATION, {data: {}});
  });
};

let cancelGetLocationsAction = false;
export const cancelGetLocationsActionFn = (option = false) => {cancelGetLocationsAction = option};

export const getLocationsAction = (limit, skip, filter, columnSort, descending) => {
  dispatch(types.FETCHING);
  locationService().getLocations(limit, skip, filter, columnSort, descending).then(res => {
    if (!cancelGetLocationsAction) {
      dispatch(types.GET_LOCATION_SUCCESS, res);
      dispatch(types.FETCHING_END);
      dispatch(types.ADD_LOCATION_PAGINATION, {
        data: {
          count: parseInt(res.headers['x-total-count'], 10),
          limit: parseInt(res.headers['x-total-limit'], 10),
          skip: parseInt(res.headers['x-total-skip'], 10),
          filter, columnSort, descending
        }
      });
    } else {
      cancelGetLocationsAction = false
    }
  }).catch((err) => {
    if (!cancelGetLocationsAction) {
      dispatch(types.FETCHING_END);
    } else {
      cancelGetLocationsAction = false
    }
    console.log(err)
  });
};

export const updateLocationAction = (location) => {
  dispatch(types.FETCHING);
  locationService().updateLocation({
    name: location.name,
    address: location.address
  }, location.id).then(res => {
    dispatch(types.UPDATE_LOCATION_SUCCESS, {});
    dispatch(types.FETCHING_END);
    dispatch(types.TOGGLE_LOCATION_FROM);
    dispatch(types.RESET_LOCATION);
    dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.UPDATE_LOCATION_SUCCESS], color: 'info'});
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.UPDATE_LOCATION_FAILURE], color: 'danger'});
  });
};

export const deleteLocationAction = (id) => {
  dispatch(types.FETCHING);
  locationService().deleteLocation(id).then(res => {
    dispatch(types.FETCHING_END);
    getLocationsAction(10, 0)
    dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.DELETE_LOCATION_SUCCESS], color: 'info'});
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.DELETE_LOCATION_FAILURE], color: 'danger'});
  });
};


export const getLocationsExcelAction = (filter) => {
  dispatch(types.FETCHING);
  locationService().getLocationsExcel(filter).then(res => {
    dispatch(types.FETCHING_END);
    saveAs(res.data, "Location" + getTodayStringValue() + ".xlsx");
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'File downloaded location list',
      color: 'info'
    });
  }).catch((err) => {
    dispatch(types.FETCHING_END);
    dispatch(types.ADD_ALERT_NOTIFICATION, {
      message: 'File download location list',
      color: 'danger'
    });
  });
};
