import React from 'react';
import {Button} from 'reactstrap';
import {LocationForm, LocationList, SuperUser} from '../index';
import {connect} from 'react-redux';
import {myDispatch, registerDispatch} from '../../actions/util';
import * as types from '../../types';
import { useHistory, useLocation } from "react-router-dom";

const LocationContainer = ({
                             showLocationForm,
                             setToggleForm,
                             isActive,
                             setIsActive
                           }) => {

  let history = useHistory();
  let location = useLocation();

  const containerStyle = {
    padding: '20px 0'
  };
  return (
    <div>
      {!(showLocationForm && (location.hash === "#edit-location" || location.hash === "#add-location")) || isActive ? (
        <div style={containerStyle}>
          <SuperUser>
            <Button
              color='primary'
              size='sm'
              style={{marginTop: '4px'}}
              className='float-right'
              onClick={() => {
                history.push({...location, hash: "add-location"});
                setToggleForm();
                setIsActive(false);
              }}
            >
              + New Location
            </Button>
          </SuperUser>
          <h2>Locations</h2>
          <LocationList setIsActive={setIsActive}/>
        </div>
      ) : (
        <LocationForm/>
      )}
    </div>
  );
};

const mapStateToProps = ({showLocationForm}) => ({
  showLocationForm
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    setToggleForm: () => {
      dispatch(myDispatch(types.TOGGLE_LOCATION_FROM));
      dispatch(myDispatch(types.RESET_LOCATION));
      dispatch(myDispatch(types.CREATE_LOCATION));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(LocationContainer);
