import React, {useEffect, useState} from 'react';
import {Button, CustomInput, FormGroup, Label, Modal, ModalBody, ModalHeader, Row, Table} from 'reactstrap';
import {ColumnSort, MemberRegistrationItems, MyPagination, SearchMemberList, SuperUser} from '../index';
import {myDispatch, registerDispatch} from '../../actions/util';
import FormHook from '../../util/FormHook/FormHook';
import {connect} from 'react-redux';
import {addMemberRegistrationAction, getMembersAction, getMembersRegistrationAction, getRegistrationsExcelAction, getRegistrationsPDFAction, sendCertificateAction, sendCustomNotificationAction} from '../../actions/member';
import * as types from '../../types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileExcel, faFilePdf} from '@fortawesome/free-solid-svg-icons';
import {getEventReminderAction, sendReminderAction} from '../../actions/event';
import confirm from "reactstrap-confirm";
import {statusService} from "../../services";

const MemberRegistrationList = ({
                                  className,
                                  event,
                                  members,
                                  membersRegistration,
                                  getWaitingMember,
                                  getMembersAction,
                                  getMembersRegistrationAction,
                                  addMemberRegistrationAction,
                                  getRegistrationsExcelAction,
                                  paginationMemberRegistration,
                                  sendCustomNotificationAction,
                                  sendCertificateAction,
                                  sendReminderAction
                                }) => {
  const [status, setStatus] = useState([]);

  useEffect(() => {
    statusService().getStatusAll()
      .then(res => {
        setStatus(res.data || [])
      })
      .catch((err) => {
        setStatus([])
      });
    // eslint-disable-next-line
  }, [setStatus]);

  let selectItems = [];
  const [isWalkInMember, setIsWalkInMember] = useState(false);
  const [isActiveMarkAttendBtn, setIsActiveMarkAttendBtn] = useState(false);

  const [register, setRegister] = useState(false);
  const [custom, setCustom] = useState(false);
  const [registrationType, setRegistrationType] = useState('Register');
  const [showUnregistered, setShowUnregistered] = useState(false);
  const [certificateState, setCertificateState] = useState(event.certificateState ? event.certificateState : event.certificatessent ? "Certificates have been sent." : "");

  useEffect(() => {
    //  getMembersAction();
    getMembersRegistrationAction(event.id, 10, 0, showUnregistered);
    return () => {
      myDispatch(types.RESET_MEMBER_REGISTRATION);
      myDispatch(types.RESET_MEMBERREGISTRATION_PAGINATION);
      sessionStorage.removeItem("showUnregistered")
    }
    // eslint-disable-next-line
  }, [getMembersAction, getMembersRegistrationAction, event]);

  const registerMemberModal = () => {
    setRegister(!register);
  };

  const customModal = () => {
    setCustom(!custom);
  };

  const marginStyle = {
    marginLeft: '10px'
  };

  let items = [];

  if (membersRegistration.length) {
    for (let i in membersRegistration) {
      items.push(
        <MemberRegistrationItems
          event={event}
          key={'memberregistration-item' + i}
          member={membersRegistration[i]}
          index={i}
          isActiveMarkAttendBtn={isActiveMarkAttendBtn}
          paginationMemberRegistration={paginationMemberRegistration}
          status={status}
        />
      );
    }
  }

  const isAlreadyIn = (member) => {
    // if (isWalkInMember) {
    //  return false;
    //  }
    for (let i in membersRegistration) {
      if (membersRegistration[i] && membersRegistration[i].member && member.id === membersRegistration[i].member.id) {
        return true;
      }
    }
    for (let i in getWaitingMember) {
      if (membersRegistration[i] && membersRegistration[i].member && member.id === getWaitingMember[i].member.id) {
        return true;
      }
    }
    return false;
  };

  if (members.length) {
    for (let i in members) {
      if (!isAlreadyIn(members[i])) {
        selectItems.push({label: members[i].lastname, value: members[i]});
      }
    }
  }
  const linkStyle = {
    color: '#28a745'
  };

  return (
    <div className={className + ' mt-5'} style={marginStyle}>
      <div className='card'>
        <div className='col-12'>
          <Row>
            <h3 className='mt-2 ml-2'>Registration</h3>
            <div className='mt-2' style={{marginLeft: "auto", marginRight: "15px"}}>
              <SuperUser>
                {(event.isclosed && membersRegistration.length > 0 && certificateState) && <small>({certificateState})</small>}
                <Button color='primary'
                        outline
                        hidden={!(event.isclosed && membersRegistration.length > 0)}
                        className='ml-2'
                        onClick={async () => {
                          const result = await confirm({
                            title: "Send Certificates",
                            message: 'Are you sure that you want to send the Certificates?',
                            confirmText: 'Yes',
                            cancelText: 'No'
                          });
                          if (result) {
                            setCertificateState("Sending Certificates...");
                            sendCertificateAction(event.id)
                          }
                        }}>Send Certificates</Button>
                <Button
                  color='primary'
                  hidden={event.isclosed}
                  outline
                  className='ml-2'
                  onClick={() => {
                    setIsWalkInMember(false);
                    setRegistrationType('Register');
                    registerMemberModal();
                  }}
                >
                  Member Registration
                </Button>
                <Button
                  hidden={!event.isclosed}
                  color='primary'
                  outline
                  className='ml-2'
                  onClick={() => {
                    setIsActiveMarkAttendBtn(!isActiveMarkAttendBtn)
                  }}
                >
                  {isActiveMarkAttendBtn ? 'Close Mark Attend' : 'Mark Attend'}
                </Button>
                <Button
                  color='primary'
                  outline
                  className='ml-2'
                  onClick={() => {
                    setIsWalkInMember(true);
                    setRegistrationType('Walk');
                    registerMemberModal();
                  }}
                >
                  Walk In Member
                </Button>
                <Button
                  color='primary'
                  outline
                  className='ml-2'
                  onClick={() => {
                    setIsWalkInMember(false);
                    setRegistrationType('Move');
                    registerMemberModal();
                  }}
                >
                  Move Member
                </Button>

                {/*  <Button color='primary' outline className='ml-2'

                      onClick={async () => {
                        const result = await confirm({
                          message:
                            'Are you sure send reminder the member list?',
                          confirmText: 'Yes',
                          cancelText: 'No'
                        });
                        if (result) {
                          sendReminderAction(event.id)
                        }
                      }

                      }>Send Reminder </Button>

              <Button color='primary'
                      hidden={event.isclosed}
                      outline
                      className='ml-2'
                      onClick={() => {

                        customModal();
                      }}
              >
                Custom Message
              </Button>*/}
              </SuperUser>
            </div>
          </Row>
        </div>
        <Row>
          <div className='col-12 mt-2'>
            <Button
              hidden={!membersRegistration.length > 0}
              color='link'
              style={linkStyle}
              onClick={() => {
                getRegistrationsPDFAction(event.id, paginationMemberRegistration.columnSort, paginationMemberRegistration.descending);
              }}
            >
              <FontAwesomeIcon color="#df0011" icon={faFilePdf}/>
              <span style={{marginLeft: '5px'}}>Export to Pdf</span>
            </Button>
            <Button
              hidden={!membersRegistration.length > 0}
              color='link'
              style={linkStyle}
              onClick={() => {
                getRegistrationsExcelAction(event.id, paginationMemberRegistration.columnSort, paginationMemberRegistration.descending);
              }}
            >
              <FontAwesomeIcon icon={faFileExcel}/>
              <span style={{marginLeft: '5px'}}>Export to Excel</span>
            </Button>
            <FormGroup className="ml-2">
              <CustomInput
                id='checkbox2'
                type='checkbox'
                label='Show Unregistered'
                inline
                onClick={() => {
                  setShowUnregistered(!showUnregistered);
                  if (!showUnregistered) {
                    sessionStorage.setItem("showUnregistered", true)
                  } else {
                    sessionStorage.removeItem("showUnregistered")
                  }
                  getMembersRegistrationAction(
                    event.id,
                    10,
                    0,
                    !showUnregistered
                  );
                }}
              />
              <div
                className='float-right'
                style={{padding: '10px 12px 6px', height: '38px'}}
              >
                {paginationMemberRegistration.count > membersRegistration.length &&
                paginationMemberRegistration.skip + 1 + ' to '}
                {paginationMemberRegistration.skip + membersRegistration.length}
                {paginationMemberRegistration.count > membersRegistration.length &&
                ' of ' + paginationMemberRegistration.count}
                &nbsp;{membersRegistration.length === 1 ? 'record' : 'records'}
              </div>

            </FormGroup>

          </div>
        </Row>
        <Table className='col-12' responsive>
          <thead className='card-header'>
          <tr>
            <th>
              Member
              <ColumnSort columnName="Member" columnSort={paginationMemberRegistration.columnSort} descending={paginationMemberRegistration.descending} changeSortAction={(object) => {
                getMembersRegistrationAction(event.id, paginationMemberRegistration.limit, paginationMemberRegistration.skip, showUnregistered, object.columnSort, object.descending);
              }}/>
            </th>
            <th>
              Registration Date
              <ColumnSort columnName="REGISTRATIONDATE" columnSort={paginationMemberRegistration.columnSort} descending={paginationMemberRegistration.descending} changeSortAction={(object) => {
                getMembersRegistrationAction(event.id, paginationMemberRegistration.limit, paginationMemberRegistration.skip, showUnregistered, object.columnSort, object.descending);
              }}/>
            </th>
            <th>Attend</th>
            <SuperUser>
              <th/>
            </SuperUser>
          </tr>
          </thead>
          <tbody>{membersRegistration.length ? (
            items
          ) : (
            <tr>
              <th colSpan='4' className='text-center'>
                No content data...
              </th>
            </tr>
          )}</tbody>
        </Table>

        {membersRegistration.length > 0 && paginationMemberRegistration.limit < paginationMemberRegistration.count && (
          <MyPagination
            count={paginationMemberRegistration.count}
            limit={paginationMemberRegistration.limit}
            skip={paginationMemberRegistration.skip}
            action={skip => {
              getMembersRegistrationAction(event.id, paginationMemberRegistration.limit, skip, showUnregistered, paginationMemberRegistration.columnSort, paginationMemberRegistration.descending);
            }}
          />
        )}
      </div>

      <div>
        <Modal style={{width: 'max-content', margin: '5px,5px', minWidth: '80%'}} isOpen={register} toggle={registerMemberModal}>
          <ModalHeader className='card-header' toggle={registerMemberModal}>
            {registrationType === 'Move' ? 'Move Member' : registrationType === 'Walk' ? 'Walk Member' : 'Member Registration'}
          </ModalHeader>
          <ModalBody>
            <Label><b>Event : </b> {event.name}</Label>

            <SearchMemberList event={event} registrationType={registrationType} isWalkInMember={isWalkInMember}/>

          </ModalBody>
        </Modal>
      </div>

      <div>

        <Modal isOpen={custom} toggle={customModal}>
          <ModalHeader className='card-header' toggle={customModal}>
            Custom Message
          </ModalHeader>
          <ModalBody>
            <FormHook
              submit={values => {
                sendCustomNotificationAction(event.id, values.head, values.body);
                getEventReminderAction(event.id, 10, 0);
                customModal();
              }}
            >
              <FormGroup>
                <h5>Subject :</h5>
                <input className='ml-2 ' name='head'/>
              </FormGroup>

              <FormGroup>
                <h5>Body :</h5>
                <textarea required style={{
                  width: '465px',
                  height: '150px',
                  padding: '10px',
                  borderRadius: '3px'
                }}
                          className='ml-2 mr-2' name='body'/>
              </FormGroup>

              <Button color='primary'>Send Message</Button>
              <Label>&nbsp;or&nbsp;</Label>
              <span
                style={{cursor: 'pointer', color: '#17a2b8', marginBottom: '5px'}}
                onClick={() => {
                  customModal();
                }}
              >
              Cancel
            </span>
            </FormHook>

          </ModalBody>
        </Modal>

      </div>
    </div>
  );
};
const mapStateToProps = ({
                           isFetching,
                           event,
                           getMembers,
                           getRegistrationsMember,
                           getWaitingMember,
                           paginationMemberRegistration
                         }) => ({
  members: getMembers,
  isFetching,
  event,
  membersRegistration: getRegistrationsMember,
  getWaitingMember,
  paginationMemberRegistration
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    getMembersAction,
    addMemberRegistrationAction,
    getMembersRegistrationAction,
    getRegistrationsExcelAction,
    sendCustomNotificationAction,
    sendCertificateAction,
    sendReminderAction
  };
};
export default connect(
  mapStateToProps,
  mapDispatchProps
)(MemberRegistrationList);
