import React, {useState} from 'react';
import {Alert, Button, Col, CustomInput, FormGroup, Label, ListGroup} from 'reactstrap';
import {registerDispatch} from '../../actions/util'
import {connect} from 'react-redux';
import Select from 'react-select';


const CustomizationForm = ({index, value, context}) => {


  const [radioCheck, setRadioCheck] = useState(value);
  return (
    <div>
      <div className={"card"}>
        <div className="card-header">
          <h5>Customization</h5>
        </div>
        <div className="card-body">
          <Alert color="dark">
            You can define if this event is open to all members,<br/> of restricted according the totle or file number.
          </Alert>
          <FormGroup tag="fieldset" row>
            <legend className="col-form-label col-sm-3">Radio Buttons</legend>
            <Col sm={10}>
              <FormGroup check>
                <Label check>
                  <input type="radio" name="radio2" onClick={() => {
                    setRadioCheck(1);
                  }}/>
                  Available to all Members.
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <input type="radio" name="radio2" onClick={() => {
                    setRadioCheck(2);
                  }}/>
                  Available for some <b>Titles</b> only
                </Label>
                <FormGroup>
                  {radioCheck === 2 ?
                    <div>
                      <Select options={[1.2]} value={1} onChange={(opt, e) => {
                        //  setLocationSelect(opt);
                      }}/>
                      <Button>Add</Button>

                      <ListGroup>
                        {}
                      </ListGroup>
                    </div> : ''
                  }
                </FormGroup>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <input type="radio" name="radio2" onClick={() => {
                    setRadioCheck(3);
                  }}/>
                  Available for some <b>File Numbers</b> only
                </Label>
                {radioCheck === 3 ? <div>
                  <Alert color="dark">
                    Import your File Numbers from excel, add manualy or search in member list. You can also export your selection to use in other events.
                    <FormGroup className="col-4">
                      <Label for="exampleCustomFileBrowser">File Browser</Label>
                      <CustomInput type="file" id="exampleCustomFileBrowser" name="customFile"/>
                    </FormGroup>
                    <Button color="success">Export to Excel</Button>
                  </Alert>
                  <input type="text" name="fileno"/>
                  <Button>Add</Button>

                  <ListGroup>
                    {}
                  </ListGroup>
                </div> : ''}

              </FormGroup>

            </Col>
          </FormGroup>

        </div>

      </div>
    </div>
  );
};

const mapStateToProps = ({isFetching}) => ({
  isFetching

});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {}
};

export default connect(mapStateToProps, mapDispatchProps)(CustomizationForm);