import React, {useEffect, useState} from 'react';
import {Button, FormGroup, InputGroup, InputGroupAddon, Row, Table} from 'reactstrap';
import {ColumnSort, InstructorItems, InstructorView, MyPagination, SuperUser} from '../index';
import {myDispatch, registerDispatch} from '../../actions/util';
import {connect} from 'react-redux';
import {getExpertisesAction, getGradeLevelsAction, getInstructorsAction, getInstructorsExcelAction} from '../../actions/instructor';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileExcel} from '@fortawesome/free-solid-svg-icons';
import * as types from "../../types";
import Select from 'react-select';

const InstructorLists = ({
                           instructors,
                           expertises,
                           gradeLevels,
                           getInstructorsAction,
                           getExpertisesAction,
                           getGradeLevelsAction,
                           paginationInstructor,
                           getInstructorsExcelAction,
                           changeSortAction,
                           setIsActive
                         }) => {
  const filterStyle = {
    border: '1px dashed #e9e9e9',
    borderRadius: '6px',
    padding: '.75rem .75rem 0',
    marginBottom: '5px',
    backgroundColor: '#fafafa'
  };
  const linkStyle = {
    color: '#28a745'
  };
  useEffect(() => {
    getInstructorsAction(10, 0);
    getExpertisesAction(10, 0);
    getGradeLevelsAction(10, 0)
    // eslint-disable-next-line
  }, [getInstructorsAction, getExpertisesAction, getGradeLevelsAction]);

  const [filter, setFilter] = useState('');
  const [selectExpertise, setSelectExpertise] = useState('');
  const [selectGradeLevel, setSelectGradeLevel] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [isOpen, setIsOpen] = useState(0);

  let items = [];

  if (instructors.length) {
    for (let i in instructors) {
      items.push(
        <InstructorItems
          key={'instructor-item' + i}
          instructor={instructors[i]}
          index={i}
          setIsActive={setIsActive}
          setAvatar={setAvatar}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      );
      items.push(<tr key={"instructor-view" + i}>
        <th colSpan="7" style={{padding: "0 12px"}}><InstructorView index={i} isOpen={isOpen} avatar={avatar} instructor={instructors[i]}/></th>
      </tr>);
    }
  }

  let selectItems = [];
  let year = 2010;
  let year_now = new Date().getFullYear();
  while (year <= year_now) {
    selectItems.push({label: year + ' - ' + (year + 1), value: year});
    year = year + 1;
  }
  let selectItemsExpertices = [];
  if (expertises.length) {
    selectItemsExpertices.push({label: '', value: 0});
    for (let i in expertises) {
      selectItemsExpertices.push({label: expertises[i].name, value: expertises[i].id});
    }
  }

  let selectItemsGradeLevel = [];
  if (gradeLevels.length) {
    selectItemsGradeLevel.push({label: '', value: 0});
    for (let i in gradeLevels) {
      selectItemsGradeLevel.push({label: gradeLevels[i].name, value: gradeLevels[i].id});
    }
  }


  const customStyles = {
    control: base => ({
      ...base,
      height: 30,
      width: 200

    }),
    dropdownIndicator: base => ({
      ...base,
      padding: 4
    }),
    clearIndicator: base => ({
      ...base,
      padding: 4
    }),
    multiValue: base => ({
      ...base
    }),
    valueContainer: base => ({
      ...base,
      padding: '0px 6px'
    }),
    input: base => ({
      ...base,
      margin: 0,
      padding: 0
    })
  };

  return (
    <div>
      <div style={filterStyle}>
        <Row style={{alignItems: 'center'}}>
          <FormGroup className='col-12 col-md-10'>
            <InputGroup>
              <input
                style={{width: '100px'}}
                type='text'
                value={filter}
                name='filterName'
                className='form-control'
                placeholder='Type the Name'
                onChange={e => {
                  setFilter(e.target.value);
                }}
                onKeyUp={e => {
                  if (e.keyCode === 27) {
                    setFilter('');
                    getInstructorsAction();
                  }
                  if (e.keyCode === 13) {
                    setFilter(e.target.value);
                    getInstructorsAction(
                      undefined,
                      undefined,
                      e.target.value,
                      selectExpertise.value,
                      selectGradeLevel.value
                    );
                  }
                }}
              />
              <Select
                styles={customStyles}
                placeholder='Expertise'
                options={selectItemsExpertices}
                value={selectExpertise}
                onChange={(opt, e) => {
                  setSelectExpertise(opt);
                }}
              />
              <Select
                styles={customStyles}
                placeholder='Grade Levels'
                options={selectItemsGradeLevel}
                value={selectGradeLevel}
                onChange={(opt, e) => {
                  setSelectGradeLevel(opt);
                }}
              />
              <InputGroupAddon addonType='append'>
                <Button
                  outline
                  color='primary'
                  onClick={() => {
                    getInstructorsAction(
                      undefined,
                      undefined,
                      filter,
                      selectExpertise.value,
                      selectGradeLevel.value
                    );
                  }}
                >
                  Search
                </Button>
                <Button
                  outline
                  color='primary'
                  onClick={() => {
                    setFilter('');
                    setSelectExpertise('');
                    setSelectGradeLevel('');
                    getInstructorsAction();
                  }}
                >
                  Reset
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        </Row>
      </div>
      <div>
        <div>
          <Button
            hidden={!instructors.length > 0}
            color='link' style={linkStyle}
            onClick={() => {
              getInstructorsExcelAction();
            }}

          >
            <FontAwesomeIcon icon={faFileExcel}/>
            <span style={{marginLeft: '5px'}}>Export to Excel</span>
          </Button>

          <div
            className='float-right'
            style={{padding: '10px 12px 6px', height: '38px'}}
          >
            {paginationInstructor.count > instructors.length &&
            paginationInstructor.skip + 1 + ' to '}
            {paginationInstructor.skip + instructors.length}
            {paginationInstructor.count > instructors.length &&
            ' of ' + paginationInstructor.count}
            &nbsp;{instructors.length === 1 ? 'record' : 'records'}
          </div>
        </div>
        <Table responsive className='col-12'>
          <thead>
          <tr className='card-header'>
            <th>Name<ColumnSort columnName="Name" columnSort={paginationInstructor.columnSort} descending={paginationInstructor.descending} changeSortAction={(object) => {
              changeSortAction({...paginationInstructor, ...object});
              getInstructorsAction(paginationInstructor.limit, paginationInstructor.skip, paginationInstructor.filter, selectExpertise.value, selectGradeLevel.value, object.columnSort, object.descending)
            }}/></th>
            <th>Last Name<ColumnSort columnName="LastName" columnSort={paginationInstructor.columnSort} descending={paginationInstructor.descending} changeSortAction={(object) => {
              changeSortAction({...paginationInstructor, ...object});
              getInstructorsAction(paginationInstructor.limit, paginationInstructor.skip, paginationInstructor.filter, selectExpertise.value, selectGradeLevel.value, object.columnSort, object.descending)
            }}/></th>
            <SuperUser><th/></SuperUser>
          </tr>
          </thead>
          <tbody>
          {instructors.length ? (
            items
          ) : (
            <tr>
              <th colSpan='6' className='text-center'>
                No content data...
              </th>
            </tr>
          )}
          </tbody>
        </Table>
        {instructors.length > 0 &&
        paginationInstructor.limit < paginationInstructor.count && (
          <MyPagination
            count={paginationInstructor.count}
            limit={paginationInstructor.limit}
            skip={paginationInstructor.skip}
            action={skip => {
              getInstructorsAction(paginationInstructor.limit, skip, filter, selectExpertise.value, selectGradeLevel.value, paginationInstructor.columnSort, paginationInstructor.descending);
            }}
          />
        )}

      </div>
    </div>
  );
};

const mapStateToProps = ({isFetching, getInstructors, getExpertises, getGradeLevels, paginationInstructor}) => ({
  isFetching,
  instructors: getInstructors,
  expertises: getExpertises,
  gradeLevels: getGradeLevels,
  paginationInstructor
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    getInstructorsAction,
    getInstructorsExcelAction,
    getExpertisesAction,
    getGradeLevelsAction,
    changeSortAction: (data) => {
      dispatch(myDispatch(types.ADD_INSTRUCTOR_PAGINATION, {data}));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(InstructorLists);
