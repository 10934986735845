import React, {useEffect, useState} from 'react';
import {Button, FormGroup, InputGroup, InputGroupAddon, Row, Table} from 'reactstrap';
import {ColumnSort, GroupItems, MyPagination, SuperUser} from '../index';
import {myDispatch, registerDispatch} from '../../actions/util';
import {connect} from 'react-redux';
import {getGroupsAction} from '../../actions/group';
import * as types from "../../types";

const GroupList = ({
                     groups,
                     getGroupsAction,
                     paginationGroup,
                     changeSortAction,
                     setIsActive
                   }) => {
  const filterStyle = {
    border: '1px dashed #e9e9e9',
    borderRadius: '6px',
    padding: '.75rem .75rem 0',
    marginBottom: '5px',
    backgroundColor: '#fafafa'
  };

  useEffect(() => {
    getGroupsAction(10, 0);
    // eslint-disable-next-line
  }, []);

  const [filter, setfilter] = useState('');

  let items = [];

  if (groups.length) {
    for (let i in groups) {
      items.push(
        <GroupItems
          key={'group-item' + i}
          group={groups[i]}
          index={i}
          setIsActive={setIsActive}
        />
      );
    }
  }

  return (
    <div>
      <div style={filterStyle}>
        <Row style={{alignItems: 'center'}}>
          <FormGroup className='col-12 col-md-8'>
            <InputGroup>
              <input
                type='text'
                value={filter}
                name='filterName'
                className='form-control col-12'
                placeholder='Name or Description'
                onChange={e => {
                  setfilter(e.target.value);
                }}
                onKeyUp={e => {
                  if (e.keyCode === 27) {
                    setfilter('');
                    getGroupsAction(
                      paginationGroup.limit,
                      0
                    );
                  }
                  if (e.keyCode === 13) {
                    setfilter(e.target.value);
                    getGroupsAction(
                      paginationGroup.limit,
                      0,
                      e.target.value
                    );
                  }
                }}
              />
              <InputGroupAddon addonType='append'>
                <Button
                  outline
                  color='primary'
                  onClick={() => {
                    getGroupsAction(
                      paginationGroup.limit,
                      0,
                      filter
                    );
                  }}
                >
                  Search
                </Button>
                <Button
                  outline
                  color='primary'
                  onClick={() => {
                    setfilter('');
                    getGroupsAction(
                      paginationGroup.limit,
                      paginationGroup.skip
                    );
                  }}
                >
                  Reset
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        </Row>
      </div>
      <div>
        <div>
          <div
            className='float-right'
            style={{padding: '10px 12px 6px', height: '38px'}}
          >
            {paginationGroup.count > groups.length &&
            paginationGroup.skip + 1 + ' to '}
            {paginationGroup.skip + groups.length}
            {paginationGroup.count > groups.length &&
            ' of ' + paginationGroup.count}
            &nbsp;{groups.length === 1 ? 'record' : 'records'}
          </div>
        </div>
        <Table responsive className='col-12'>
          <thead>
          <tr className='card-header'>
            <th>Name <ColumnSort columnName="name" columnSort={paginationGroup.columnSort} descending={paginationGroup.descending} changeSortAction={(object) => {
              changeSortAction({...paginationGroup, ...object});
              getGroupsAction(paginationGroup.limit, paginationGroup.skip, filter, object.columnSort, object.descending)
            }}/></th>
            <th>Description<ColumnSort columnName="description" columnSort={paginationGroup.columnSort} descending={paginationGroup.descending} changeSortAction={(object) => {
              changeSortAction({...paginationGroup, ...object});
              getGroupsAction(paginationGroup.limit, paginationGroup.skip, filter, object.columnSort, object.descending)
            }}/></th>
            <SuperUser><th/></SuperUser>
          </tr>
          </thead>
          <tbody>
          {groups.length ? (
            items
          ) : (
            <tr>
              <th colSpan='6' className='text-center'>
                No content data
              </th>
            </tr>
          )}
          </tbody>
        </Table>
        {groups.length > 0 && paginationGroup.limit < paginationGroup.count && (
          <MyPagination
            count={paginationGroup.count}
            limit={paginationGroup.limit}
            skip={paginationGroup.skip}
            action={skip => {
              getGroupsAction(paginationGroup.limit, skip, filter, paginationGroup.columnSort, paginationGroup.descending);
            }}
          />
        )}

      </div>
    </div>
  );
};

const mapStateToProps = ({isFetching, getGroups, paginationGroup}) => ({
  isFetching,
  groups: getGroups,
  paginationGroup
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    getGroupsAction,
    changeSortAction: (data) => {
      dispatch(myDispatch(types.ADD_GROUP_PAGINATION, {data}));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(GroupList);
