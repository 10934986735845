import React, {useEffect, useState} from 'react';
import {Button, FormGroup, InputGroup, InputGroupAddon, Row, Table} from 'reactstrap';
import {ColumnSort, MyPagination, UserItems} from '../index';
import {myDispatch, registerDispatch} from '../../actions/util';
import {connect} from 'react-redux';
import {getUsersAction, getUsersExcelAction} from '../../actions/user';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileExcel} from '@fortawesome/free-solid-svg-icons';
import * as types from "../../types";

const UserList = ({isFetching, users, getUsersAction, paginationUser, changeSortAction, setIsActive, getUsersExcelAction}) => {
  const filterStyle = {
    border: '1px dashed #e9e9e9',
    borderRadius: '6px',
    padding: '.75rem .75rem 0',
    marginBottom: '5px',
    backgroundColor: '#fafafa'
  };

  const [filter, setFilter] = useState('');

  const getUsersFn = (limit = paginationUser.limit, skip = paginationUser.skip, filterValue = filter, columnSort = paginationUser.columnSort, descending = paginationUser.descending) => {
    getUsersAction(limit, skip, filterValue, columnSort, descending);
  };

  useEffect(() => {
    getUsersFn();
    // eslint-disable-next-line
  }, [getUsersAction]);


  const linkStyle = {
    color: '#28a745'
  };
  let items = [];

  if (users.length) {
    for (let i in users) {
      items.push(
        <UserItems
          key={'user-item' + i}
          user={users[i]}
          index={i}
          setIsActive={setIsActive}
        />
      );
    }
  }
  return (
    <div>
      <div style={filterStyle}>
        <Row style={{alignItems: 'center'}}>
          <FormGroup className='col-12 col-md-8'>
            <InputGroup>
              <input
                type='text'
                name='filterName'
                value={filter}
                className='form-control'
                placeholder='Name or Username'
                onChange={e => {
                  setFilter(e.target.value);
                }}
                onKeyUp={e => {
                  if (e.keyCode === 27) {
                    setFilter('');
                    getUsersFn(undefined, undefined, '');
                  }
                  if (e.keyCode === 13) {
                    setFilter(e.target.value);
                    getUsersFn(undefined, undefined, e.target.value);
                  }
                }}
              />
              <InputGroupAddon addonType='append'>
                <Button
                  outline
                  color='primary'
                  onClick={() => {
                    getUsersFn()
                  }}
                >
                  Search
                </Button>
                <Button
                  outline
                  color='primary'
                  onClick={() => {
                    setFilter('');
                    getUsersFn(undefined, undefined, '');
                  }}
                >
                  Reset
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        </Row>
      </div>
      <div>
        <div>
          <Button
            hidden={!users.length > 0}
            color='link'
            style={linkStyle}
            onClick={() => {
              getUsersExcelAction(filter);
            }}
          >
            <FontAwesomeIcon icon={faFileExcel}/>
            <span style={{marginLeft: '5px'}}>Export to Excel</span>
          </Button>
          <div
            className='float-right'
            style={{padding: '10px 12px 6px', height: '38px'}}
          >
            {paginationUser.count > users.length &&
            paginationUser.skip + 1 + ' to '}
            {paginationUser.skip + users.length}
            {paginationUser.count > users.length &&
            ' of ' + paginationUser.count}
            &nbsp;{users.length === 1 ? 'record' : 'records'}
          </div>
        </div>

        <Table responsive className='col-12'>
          <thead className='card-header'>
          <tr>
            <th>Name <ColumnSort columnName="name" columnSort={paginationUser.columnSort} descending={paginationUser.descending} changeSortAction={(object) => {
              changeSortAction({...paginationUser, ...object});
              getUsersFn(undefined, undefined, undefined, object.columnSort, object.descending)
            }}/></th>
            <th>Username<ColumnSort columnName="username" columnSort={paginationUser.columnSort} descending={paginationUser.descending} changeSortAction={(object) => {
              changeSortAction({...paginationUser, ...object});
              getUsersFn(undefined, undefined, undefined, object.columnSort, object.descending)
            }}/></th>
            <th>Email<ColumnSort columnName="email" columnSort={paginationUser.columnSort} descending={paginationUser.descending} changeSortAction={(object) => {
              changeSortAction({...paginationUser, ...object});
              getUsersFn(undefined, undefined, undefined, object.columnSort, object.descending)
            }}/></th>
            <th>Mobile Phone<ColumnSort columnName="phone" columnSort={paginationUser.columnSort} descending={paginationUser.descending} changeSortAction={(object) => {
              changeSortAction({...paginationUser, ...object});
              getUsersFn(undefined, undefined, undefined, object.columnSort, object.descending)
            }}/></th>
            <th/>
          </tr>
          </thead>
          <tbody>
          {users.length ? (
            items
          ) : (
            <tr>
              <th colSpan='6' className='text-center'>
                No content data...
              </th>
            </tr>
          )}
          </tbody>
        </Table>
        {users.length > 0 && paginationUser.limit < paginationUser.count && (
          <MyPagination
            count={paginationUser.count}
            limit={paginationUser.limit}
            skip={paginationUser.skip}
            action={skip => {
              getUsersFn(undefined, skip);
            }}
          />
        )}

      </div>
    </div>
  );
};

const mapStateToProps = ({isFetching, getUsers, paginationUser}) => ({
  isFetching,
  users: getUsers,
  paginationUser
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    getUsersAction,
    getUsersExcelAction,
    changeSortAction: (data) => {
      dispatch(myDispatch(types.ADD_USER_PAGINATION, {data}));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(UserList);
