import axios from 'axios';
import {polyfill} from 'es6-promise';
import {API_URL} from "../config/app";
import {dispatch} from "../actions/util";
import * as types from "../types";
import messages from "./messages";
import {getToken, isLogin, removeToken} from "../actions/user";

polyfill();

class RestApiClient {
  constructor(config) {
    this.client = axios.create(config);
  }

  request(options) {
    if (isLogin()) {
      if (!options['headers']) {
        options['headers'] = {};
      }
      if (!options['headers']['authorization']) {
        options['headers']['authorization'] = {};
      }

      options['headers']['authorization'] = `Bearer ${getToken()}`;
    }
    let tmp = this.client.request(options);
    tmp.catch((err) => {
      if (err && err.response && err.response.status === 401) {
        removeToken();
        localStorage.removeItem('retiree');
        dispatch(types.LOGIN_FAILURE);
        //window.location = '/';
      }
      if (err && err.message === "Network Error") {
        dispatch(types.ADD_ALERT_NOTIFICATION, {message: messages[types.CONNECTION_ERROR], color: 'danger'})
      }
    });
    return tmp;
  }
}

const createRestApiClient = () => ({
  withConfig: config => new RestApiClient(config)
});

export default createRestApiClient;

export const client = createRestApiClient().withConfig({baseURL: API_URL});
