import React, {useState} from 'react';
import {Button, Col, CustomInput, FormGroup, Label, Row} from 'reactstrap';
import FormHook from '../../util/FormHook/FormHook';
import {addUserAction, cancelGetUsersActionFn, updateUserAction} from '../../actions/user';
import {connect} from 'react-redux';
import * as types from '../../types';
import {myDispatch, registerDispatch} from '../../actions/util';
import {UserRolesList} from '../index';
import { useHistory, useLocation } from "react-router-dom";

let lastPass = "";

const UserForm = ({
                    CRUDUser,
                    user,
                    isFetching,
                    addUserAction,
                    updateUserAction,
                    toggleForm
                  }) => {

  let history = useHistory();
  let location = useLocation();

  const [active, setActive] = useState(typeof user.iS_ACTIVE === "undefined" || user.iS_ACTIVE);
  const sectionStyle = {
    border: '1px solid #e9e9e9',
    borderRadius: '6px',
    padding: '.75rem .75rem 0',
    marginBottom: '5px'
  };
  const actionsStyle = {
    margin: '15px 0',
    padding: '10px 0',
    width: '100%',
    borderTop: '1px solid #e9e9e9'
  };
  return (
    <div style={{padding: '20px 0px'}}>
      <Row style={{margin: '0px 0px 10px'}}>
        <span
          style={{cursor: 'pointer', color: '#17a2b8', fontSize: '12px'}}
          onClick={() => {
            history.push({...location, hash: ""});
            toggleForm();
          }}
        >
          Users >&nbsp;
        </span>
        <span style={{color: 'rgb(108, 117, 125)', fontSize: '12px'}}>
          {CRUDUser === types.CREATE_USER ? 'New User' : 'Update User'}
        </span>
      </Row>
      <Row>
        <FormHook
          className={CRUDUser === types.UPDATE_USER ? 'col-md-6' : 'col-md-8'}
          defaultValues={CRUDUser === types.UPDATE_USER ? user : {}}
          submit={values => {
            cancelGetUsersActionFn(false);
            if (CRUDUser === types.CREATE_USER) {
              addUserAction(
                values.name,
                values.userName,
                values.email,
                values.mobilePhone,
                active,
                values.externaL_ID,
                values.password
              );
            } else if (CRUDUser === types.UPDATE_USER) {
              updateUserAction({...values, iS_ACTIVE: active});
            }
          }}
        >
          <div>
            <Row style={{marginLeft: '0px'}}>
              <h5>{CRUDUser === types.CREATE_USER ? 'New' : 'Update'} User</h5>
            </Row>
            <div style={{maxWidth: '800px', marginTop: '10px'}}>
              {CRUDUser === types.UPDATE_USER && user.eliId && (
                <input name='eliId' type='hidden'/>
              )}
              <div style={sectionStyle}>
                <FormGroup row>
                  <Label sm={3}>Name</Label>
                  <Col sm={9}>
                    <input name='name' autoComplete="off" required/>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={3}>Username</Label>
                  <Col sm={9}>
                    <input name='userName' autoComplete="off" required/>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={3}>Email</Label>
                  <Col sm={9}>
                    <input name='email' autoComplete="off" required type='email'/>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={3}>Mobile Phone</Label>
                  <Col sm={9}>
                    <input name='mobilePhone' autoComplete="off" type='text' format="phone"/>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={3}>External Id</Label>
                  <Col sm={9}>
                    <input name='externaL_ID' autoComplete="off"/>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <CustomInput
                    type='checkbox'
                    id='checkbox2'
                    label='Active'
                    inline
                    checked={active}
                    onChange={e => {
                      setActive(!active);
                    }}
                  />
                </FormGroup>
              </div>
              <div style={sectionStyle}>
                <FormGroup row>
                  <Label sm={3}>Password</Label>
                  <Col sm={9}>
                    <input name='password' autoComplete="off" onChange={(e) => {
                      lastPass = e.target.value
                    }} type='password'/>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={3}>Confirm Password</Label>
                  <Col sm={9}>
                    <input name='confirmpassword' autoComplete="off" validation={(value) => value === lastPass} type='password'/>
                  </Col>
                </FormGroup>
              </div>
            </div>
            <div style={actionsStyle}>
              <Button color='primary'>
                {CRUDUser === types.CREATE_USER ? 'Save' : 'Update'}
                {isFetching ? '...' : ''}
              </Button>
              <Label>&nbsp;or&nbsp;</Label>
              <span
                style={{cursor: 'pointer', color: '#17a2b8'}}
                onClick={() => {
                  history.push({...location, hash: ""});
                  toggleForm();
                }}
              >
                Cancel
              </span>
            </div>
          </div>
        </FormHook>
        {CRUDUser === types.UPDATE_USER && (
          <div className='col-md-6 col-12'>
            <UserRolesList/>
          </div>
        )}
      </Row>
    </div>
  );
};

const mapStateToProps = ({isFetching, CRUDUser, user}) => ({
  isFetching,
  CRUDUser,
  user
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    addUserAction,
    updateUserAction,
    toggleForm: () => {
      cancelGetUsersActionFn(false);
      dispatch(myDispatch(types.TOGGLE_USER_FROM));
      dispatch(myDispatch(types.RESET_USER));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(UserForm);
