import React, {useEffect, useState} from 'react';
import {Button, FormGroup, InputGroup, InputGroupAddon, Row, Table} from 'reactstrap';
import {ColumnSort, MyPagination, SuperUser, WorkshopsItems, WorkshopsModal} from '../index';
import {myDispatch, registerDispatch} from '../../actions/util';
import {connect} from 'react-redux';
import {getWorkShopsAction, getWorkShopsExcelAction} from '../../actions/workshop';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileExcel} from '@fortawesome/free-solid-svg-icons';
import * as types from "../../types";


const WorkshopList = ({
                        workshops,
                        isFetching,
                        getWorkShopsAction,
                        getWorkShopsExcelAction,
                        paginationWorkShop,
                        changeSortAction,
                        setIsActive,
                        isRetiree
                      }) => {
  const filterStyle = {
    border: '1px dashed #e9e9e9',
    borderRadius: '6px',
    padding: '.75rem .75rem 0',
    marginBottom: '5px',
    backgroundColor: '#fafafa'
  };
  const linkStyle = {
    color: '#28a745'
  };
  useEffect(() => {
    getWorkShopsAction(10, 0);
    // eslint-disable-next-line
  }, [getWorkShopsAction]);

  const [filter, setfilter] = useState('');

  const [nextWorkshopId, setNextWorkshopId] = useState([]);


  let items = [];

  if (workshops.length) {
    for (let i in workshops) {
      items.push(
        <WorkshopsItems
          key={'workshops-item' + i}
          workshop={workshops[i]}
          index={i}
          setIsActive={setIsActive}
          paginationWorkShop={paginationWorkShop}
          filter={filter}
          setNextWorkshopId={setNextWorkshopId}
        />
      );
      //toggleWorkshopVersionView={() => {setIsOpen(isOpen === "toggler-workshop" + workshops[i].id ? '' : "toggler-workshop" + workshops[i].id)}}
      // items.push(<tr key={"workshop-view" + i}>
      // <th colSpan="7" style={{padding: "0 12px"}}><WorkshopVersionView isOpen={isOpen} workshop={workshops[i]}/></th>
      //</tr>);
    }
  }

  return (
    <div>
      <div style={filterStyle}>
        <Row style={{alignItems: 'center'}}>
          <FormGroup className='col-12 col-md-8'>
            <InputGroup>
              <input
                type='text'
                value={filter}
                name='filterName'
                className='form-control'
                placeholder='Title or Detail'
                onChange={e => {
                  setfilter(e.target.value);
                }}
                onKeyUp={e => {
                  if (e.keyCode === 27) {
                    setfilter('');
                    getWorkShopsAction();
                  }
                  if (e.keyCode === 13) {
                    setfilter(e.target.value);
                    getWorkShopsAction(
                      undefined,
                      undefined,
                      e.target.value
                    );
                  }
                }}
              />
              <InputGroupAddon addonType='append'>
                <Button
                  outline
                  color='primary'
                  onClick={() => {
                    getWorkShopsAction(
                      undefined,
                      undefined,
                      filter
                    );
                  }}
                >
                  Search
                </Button>
                <Button
                  outline
                  color='primary'
                  onClick={() => {
                    setfilter('');
                    getWorkShopsAction();
                  }}
                >
                  Reset
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        </Row>
      </div>
      <div>
        <div>
          <Button
            color='link'
            style={linkStyle}
            onClick={() => {
              getWorkShopsExcelAction(filter);
            }}
          >
            <FontAwesomeIcon icon={faFileExcel}/>
            <span style={{marginLeft: '5px'}}>Export to Excel</span>
          </Button>
          <div
            className='float-right'
            style={{padding: '10px 12px 6px', height: '38px'}}
          >
            {paginationWorkShop.count > workshops.length &&
            paginationWorkShop.skip + 1 + ' to '}
            {paginationWorkShop.skip + workshops.length}
            {paginationWorkShop.count > workshops.length &&
            ' of ' + paginationWorkShop.count}
            &nbsp;{workshops.length === 1 ? 'record' : 'records'}
          </div>
        </div>
        <Table className='col-12' responsive>
          <thead>
          <tr className='card-header'>
            <th>Title <ColumnSort columnName="title" columnSort={paginationWorkShop.columnSort} descending={paginationWorkShop.descending} changeSortAction={(object) => {
              changeSortAction({...paginationWorkShop, ...object});
              getWorkShopsAction(paginationWorkShop.limit, paginationWorkShop.skip, paginationWorkShop.filter, object.columnSort, object.descending)
            }}/></th>
            <th>Detail<ColumnSort columnName="details" columnSort={paginationWorkShop.columnSort} descending={paginationWorkShop.descending} changeSortAction={(object) => {
              changeSortAction({...paginationWorkShop, ...object});
              getWorkShopsAction(paginationWorkShop.limit, paginationWorkShop.skip, paginationWorkShop.filter, object.columnSort, object.descending)
            }}/></th>
            {!isRetiree &&
            <th>Rating</th>
            }
            <SuperUser><th/></SuperUser>
          </tr>
          </thead>
          <tbody>
          {workshops.length ? (
            items
          ) : (
            <tr>
              <th className='text-center' colSpan='6'>
                No content data.
              </th>
            </tr>
          )}
          </tbody>
        </Table>
        {workshops.length > 0 &&
        paginationWorkShop.limit < paginationWorkShop.count && (
          <MyPagination
            count={paginationWorkShop.count}
            limit={paginationWorkShop.limit}
            skip={paginationWorkShop.skip}
            action={skip => {
              getWorkShopsAction(paginationWorkShop.limit, skip, filter, paginationWorkShop.columnSort, paginationWorkShop.descending);
            }}
          />
        )}

      </div>
      <WorkshopsModal nextWorkshopId={nextWorkshopId} setNextWorkshopId={setNextWorkshopId}/>
    </div>
  );
};

const mapStateToProps = ({isFetching, getWorkShops, paginationWorkShop, isRetiree}) => ({
  isFetching,
  workshops: getWorkShops,
  paginationWorkShop,
  isRetiree
});

const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    getWorkShopsAction,
    getWorkShopsExcelAction,
    changeSortAction: (data) => {
      dispatch(myDispatch(types.ADD_WORKSHOP_PAGINATION, {data}));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(WorkshopList);
