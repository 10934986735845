import {client} from '../util/createRestApiClient';

const base = "Waiting/";
export default () => ({

  getWaitingMembers: (eventId, limit = 10, skip = 0, isWaiting = true, columnSort = "", descending = true) => client.request(
    {
      method: 'GET',
      url: base + 'GetWaitings/' + eventId + '?limit=' + limit + '&skip=' + skip + '&isWaiting=' + isWaiting + '&columnSort=' + columnSort + '&descending=' + descending
    }
  ),
  getWaitingsExcel: (eventId) => client.request(
    {
      method: 'GET',
      responseType: 'blob',
      url: base + 'GetWaitingsExcel/' + eventId
    }
  ),
  unRegisterByLogingMember: (eventId) => client.request(
    {
      method: 'POST',
      url: base + 'UnregisterByLoginMember/' + eventId
    }
  ),
});
