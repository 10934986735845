import React from 'react';
import {registerDispatch} from '../../actions/util';
import {connect} from 'react-redux';
import moment from "moment";


const MemberReminderItems = ({reminder, index}) => {
  const date = moment.utc(reminder.reminderSent).toDate();

  return (
    <tr>
      <td id={
        "reminder" + index
      }
      >
        {
          reminder.type
        }
      </td>
      <td>
        {moment(date).local().format('LL')}
      </td>
      <td>
        {moment(date).local().format('LT')}
      </td>
      <td>
        {reminder.sender}
      </td>
    </tr>
  );
};
const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {}
};

export default connect(undefined, mapDispatchProps)(MemberReminderItems);
