import React from 'react';
import {registerDispatch} from '../../actions/util';
import {connect} from 'react-redux';
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import {addMemberRegistrationAction, moveMemberAction} from '../../actions/member';

const SearchMemberItems = ({
                             event,
                             member,
                             index,
                             registrationType,
                             isWalkInMember,
                             moveMemberAction,
                             addMemberRegistrationAction
                           }) => {

  return (
    <tr>
      <td id={'member' + index} className='cursor-pointer'>
        {member.frstname}
      </td>
      <td id={'member' + index} className='cursor-pointer'>
        {member.lastname}
      </td>
      <td>
        {member.fileno}
      </td>
      <td>
        {member.title}
      </td>
      <td>
        {member.sitecode}
      </td>
      <td>
        <UncontrolledDropdown style={{display: "inline"}}>
          <DropdownToggle id={"tooltip-site-delete" + index} caret outline color="primary">
            <FontAwesomeIcon icon={faPlusCircle}/>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem className="text-primary cursor-pointer" onClick={() => {
              if (registrationType === "Move") {
                moveMemberAction(event.id, member.id, false, isWalkInMember);
              } else {

                addMemberRegistrationAction(event.id, member.id, false, isWalkInMember);
              }
            }}>
              {registrationType} member
            </DropdownItem>
            {registrationType === "Register" && <DropdownItem className="text-primary cursor-pointer" onClick={() => {
              addMemberRegistrationAction(event.id, member.id, true, isWalkInMember);
            }}>
              {registrationType} priority member
            </DropdownItem>
            }
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  );
};
const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    addMemberRegistrationAction,
    moveMemberAction
  };
};

export default connect(
  undefined,
  mapDispatchProps
)(SearchMemberItems);
