import React from 'react';
import {Col, Collapse, Row} from 'reactstrap';
import {registerDispatch} from '../../actions/util';
import {WorkshopVersionList} from '../index';
import {getWorkShopsVersionAction} from '../../actions/workshop';
import {connect} from 'react-redux';

const WorkshopVersionView = ({isOpen, workshop, workshops}) => {


  const filterStyle = {
    border: '1px dashed #e9e9e9',
    borderRadius: '6px',
    padding: '.75rem .75rem 0',
    marginBottom: '5px',
    marginTop: '5px',
    backgroundColor: '#fafafa'
  };

  return (

    <Collapse isOpen={isOpen === "toggler-workshop" + workshop.id}>
      <div style={filterStyle}>
        <Row>
          <Col xs="12">
            <WorkshopVersionList workshop={workshop} workshops={workshops}/>
          </Col>
        </Row>
      </div>

    </Collapse>
  );
};
const mapStateToProps = ({isRetiree, getWorkShopsVersion}) => ({
  isRetiree,
  workshops: getWorkShopsVersion

});
const mapDispatchProps = dispatch => {
  registerDispatch(dispatch);
  return {
    getWorkShopsVersionAction
  };
};
export default connect(
  mapStateToProps,
  mapDispatchProps
)(WorkshopVersionView);