// User Pagination
export const ADD_USER_PAGINATION = 'ADD_USER_PAGINATION';
export const RESET_USER_PAGINATION = 'RESET_USER_PAGINATION';
export const ADD_MEMBERREGISTRATION_PAGINATION = 'ADD_MEMBERREGISTRATION_PAGINATION';
export const RESET_MEMBERREGISTRATION_PAGINATION = 'RESET_MEMBERREGISTRATION_PAGINATION';
export const ADD_MEMBERWAITING_PAGINATION = 'ADD_MEMBERWAITING_PAGINATION';
export const RESET_MEMBERWAITING_PAGINATION = 'RESET_MEMBERWAITING_PAGINATION';


export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_RETIREE_USER_SUCCESS = 'GET_RETIREE_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const GET_RETIREE_USER_FAILURE = 'GET_RETIREE_USER_FAILURE';
export const ADD_USER = 'ADD_USER';
export const ADD_RETIREE_USER = 'ADD_RETIREE_USER';

// CRUD general state
export const GET = 'GET';

export const TOGGLE_FETCHING = 'TOGGLE_FETCHING';
export const FETCHING = 'FETCHING';
export const FETCHING_END = 'FETCHING_END';

export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const MODAL_WORKSHOP_VERSION = 'MODAL_WORKSHOP_VERSION';

// Alert Notification
export const ADD_ALERT_NOTIFICATION = 'ADD_ALERT_NOTIFICATION';
export const DISMISS_ALERT_NOTIFICATION = 'DISMISS_ALERT_NOTIFICATION';
export const RESET_ALERT_NOTIFICATION = 'RESET_ALERT_NOTIFICATION';

// Login
export const SHOW_AUTH_MODAL = 'SHOW_AUTH_MODAL';
export const HIDE_AUTH_MODAL = 'HIDE_AUTH_MODAL';
export const TOKEN_IS_IN_LOCAL_STORAGE = 'TOKEN_IS_IN_LOCAL_STORAGE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const AUTOMATIC_LOGIN = 'AUTOMATIC_LOGIN';
export const RETIREE_LOGIN = 'RETIREE_LOGIN';
export const RESET_RETIREE_LOGIN = 'RESET_RETIREE_LOGIN';

export const CONNECTION_ERROR = 'CONNECTION_ERROR';

// Workshop
export const CREATE_WORKSHOP = 'CREATE_WORKSHOP';
export const UPDATE_WORKSHOP = 'UPDATE_WORKSHOP';
export const DELETE_WORKSHOP = 'DELETE_WORKSHOP';
export const CLONE_WORKSHOP = 'CLONE_WORKSHOP';

export const ADD_WORKSHOP = 'ADD_WORKSHOP';
export const RESET_WORKSHOP = 'RESET_WORKSHOP';
export const ADD_WORKSHOP_SUCCESS = 'ADD_WORKSHOP_SUCCESS';
export const ADD_WORKSHOP_FAILURE = 'ADD_WORKSHOP_FAILURE';
export const CLONE_WORKSHOP_SUCCESS = 'CLONE_WORKSHOP_SUCCESS';
export const CLONE_WORKSHOP_FAILURE = 'CLONE_WORKSHOP_FAILURE';
export const GET_WORKSHOP_SUCCESS = 'GET_WORKSHOP_SUCCESS';
export const TOGGLE_WORKSHOP_FROM = 'TOGGLE_WORKSHOP_FROM';
export const GET_WORKSHOP_FAILURE = 'GET_WORKSHOP_FAILURE';
export const UPDATE_WORKSHOP_SUCCESS = 'UPDATE_WORKSHOP_SUCCESS';
export const UPDATE_WORKSHOP_FAILURE = 'UPDATE_WORKSHOP_FAILURE';
export const DELETE_WORKSHOP_FAILURE = 'DELETE_WORKSHOP_FAILURE';
export const DELETE_WORKSHOP_SUCCESS = 'DELETE_WORKSHOP_SUCCESS';


export const ADD_WORKSHOP_PAGINATION = 'ADD_WORKSHOP_PAGINATION';
export const RESET_WORKSHOP_PAGINATION = 'RESET_WORKSHOP_PAGINATION';
export const GET_WORKSHOPVERSION_SUCCESS = 'GET_WORKSHOPVERSION_SUCCESS';
export const ADD_WORKSHOPVERSION_PAGINATION = 'ADD_WORKSHOPVERSION_PAGINATION';
export const GET_WORKSHOPVERSION_FAILURE = 'GET_WORKSHOPVERSION_FAILURE';
export const RESET_WORKSHOPVERSION_PAGINATION = 'RESET_WORKSHOPVERSION_PAGINATION';


// Instructor
export const CREATE_INSTRUCTOR = 'CREATE_INSTRUCTOR';
export const UPDATE_INSTRUCTOR = 'UPDATE_INSTRUCTOR';
export const DELETE_INSTRUCTOR = 'DELETE_WORKSHOP';


export const ADD_INSTRUCTOR = 'ADD_INSTRUCTOR';
export const RESET_INSTRUCTOR = 'RESET_INSTRUCTOR';
export const ADD_INSTRUCTOR_SUCCESS = 'ADD_INSTRUCTOR_SUCCESS';
export const ADD_INSTRUCTOR_FAILURE = 'ADD_INSTRUCTOR_FAILURE';
export const GET_INSTRUCTOR_SUCCESS = 'GET_INSTRUCTOR_SUCCESS';
export const TOGGLE_INSTRUCTOR_FROM = 'TOGGLE_INSTRUCTOR_FROM';
export const GET_INSTRUCTOR_FAILURE = 'GET_INSTRUCTOR_FAILURE';
export const UPDATE_INSTRUCTOR_SUCCESS = 'UPDATE_INSTRUCTOR_SUCCESS';
export const UPDATE_INSTRUCTOR_FAILURE = 'UPDATE_INSTRUCTOR_FAILURE';
export const DELETE_INSTRUCTOR_FAILURE = 'DELETE_INSTRUCTOR_FAILURE';
export const DELETE_INSTRUCTOR_SUCCESS = 'DELETE_INSTRUCTOR_SUCCESS';

export const GET_EVENT_INSTRUCTOR_SUCCESS = 'GET_EVENT_INSTRUCTOR_SUCCESS';
export const GET_EVENT_INSTRUCTOR_FAILURE = 'GET_EVENT_INSTRUCTOR_FAILURE';

export const ADD_INSTRUCTOR_PAGINATION = 'ADD_INSTRUCTOR_PAGINATION';
export const RESET_INSTRUCTOR_PAGINATION = 'RESET_INSTRUCTOR_PAGINATION';


export const GET_EXPERTISE_INSTRUCTOR_SUCCESS = 'GET_EXPERTISE_INSTRUCTOR_SUCCESS';
export const GET_EXPERTISE_INSTRUCTOR_FAILURE = 'GET_EXPERTISE_INSTRUCTOR_FAILURE';
export const GET_EXPERTISE_SUCCESS = 'GET_EXPERTISE_SUCCESS';
export const GET_EXPERTISE_FAILURE = 'GET_EXPERTISE_FAILURE';

export const GET_GRADELEVELS_INSTRUCTOR_SUCCESS = 'GET_GRADELEVELS_INSTRUCTOR_SUCCESS';
export const GET_GRADELEVELS_INSTRUCTOR_FAILURE = 'GET_GRADELEVELS_INSTRUCTOR_FAILURE';
export const GET_GRADELEVEL_SUCCESS = 'GET_GRADELEVEL_SUCCESS';
export const GET_GRADELEVEL_FAILURE = 'GET_GRADELEVEL_FAILURE';


// User
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';


export const RESET_USER = 'RESET_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';
export const TOGGLE_USER_FROM = 'TOGGLE_USER_FROM';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';

// rol
export const TOGGLE_ROL_FROM = 'TOGGLE_ROL_FROM';
export const ADD_ROL_SUCCESS = 'ADD_ROL_SUCCESS';
export const ADD_ROL_FAILURE = 'GET_ROL_FAILURE';
export const GET_ROL_SUCCESS = 'GET_ROL_SUCCESS';
export const GET_ROL_FAILURE = 'GET_ROL_FAILURE';
export const GET_ROL_USER_SUCCESS = 'GET_ROL_USER_SUCCESS';
export const GET_ROL_USER_FAILURE = 'GET_ROL_USER_FAILURE';

// title
export const GET_TITLES_SUCCESS = 'GET_TITLE_SUCCESS';
export const GET_TITLES_FAILURE = 'GET_TITLE_FAILURE';
export const GET_EVENT_TITLES_SUCCESS = 'GET_EVENT_TITLES_SUCCESS';
export const GET_EVENT_TITLES_FAILURE = 'GET_EVENT_TITLES_FAILURE';
export const ADD_EVENTTITLE_PAGINATION = 'ADD_EVENTTITLE_PAGINATION';
export const RESET_EVENTTITLE_PAGINATION = 'RESET_EVENTTITLE_PAGINATION';


// member

export const GET_MEMBER_SUCCESS = 'GET_MEMBER_SUCCESS';
export const GET_MEMBER_FAILURE = 'GET_MEMBER_FAILURE';
export const ADD_MEMBER_REGISTRATION_SUCCESS = 'ADD_MEMBER_REGISTRATION_SUCCESS';
export const ADD_MEMBER_REGISTRATION_FAILURE = 'ADD_MEMBER_REGISTRATION_FAILURE';
export const CREATE_MEMBER_REGISTRATION = 'CREATE_MEMBER_REGISTRATION';
export const UNREGISTER_MEMBER_REGISTRATION = 'UNREGISTER_MEMBER_REGISTRATION';
export const GET_MEMBER_REGISTRATION_SUCCESS = 'GET_MEMBER_REGISTRATION_SUCCESS';
export const GET_MEMBER_REGISTRATION_FAILURE = 'GET_REGISTRATION_MEMBER_FAILURE';

export const RESET_MEMBER_REGISTRATION = 'RESET_MEMBER_REGISTRATION';
export const TOGGLE_MEMBER_REGISTRATION_FROM = 'TOGGLE_MEMBER_REGISTRATION_FROM';


// Event

export const CREATE_EVENT = 'CREATE_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const CLONE_EVENT = 'CLONE_EVENT';

export const ADD_EVENT = 'ADD_EVENT';
export const RESET_EVENT = 'RESET_EVENT';
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS';
export const ADD_EVENT_FAILURE = 'ADD_EVENT_FAILURE';
export const CLONE_EVENT_SUCCESS = 'CLONE_EVENT_SUCCESS';
export const CLONE_EVENT_FAILURE = 'CLONE_EVENT_FAILURE';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const TOGGLE_EVENT_FROM = 'TOGGLE_EVENT_FROM';
export const GET_EVENT_FAILURE = 'GET_EVENT_FAILURE';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const ADD_EVENT_PAGINATION = 'ADD_EVENT_PAGINATION';
export const RESET_EVENT_PAGINATION = 'RESET_EVENT_PAGINATION';

export const ADD_EVENTMEMBERVIEW_PAGINATION = 'ADD_EVENTMEMBERVIEW_PAGINATION';
export const RESET_EVENTMEMBERVIEW_PAGINATION = 'RESET_EVENTMEMBERVIEW_PAGINATION';
export const GET_EVENTMEMBERVIEW_SUCCESS = 'GET_EVENTMEMBERVIEW_SUCCESS';
export const GET_EVENTMEMBERVIEW_FAILURE = 'GET_EVENTMEMBERVIEW_FAILURE';
export const ADD_EVENTFILENUMBER_PAGINATION = 'ADD_EVENTFILENUMBER_PAGINATION';
export const RESET_EVENTFILENUMBER_PAGINATION = 'RESET_EVENTFILENUMBER_PAGINATION';


// Location

export const CREATE_LOCATION = 'CREATE_LOCATION';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const DELETE_LOCATION = 'DELETE_LOCATION';
export const RESET_LOCATION = 'RESET_LOCATION';

export const ADD_LOCATION = 'ADD_LOCATION';
export const ADD_LOCATION_SUCCESS = 'ADD_LOCATION_SUCCESS';
export const ADD_LOCATION_FAILURE = 'ADD_LOCATION_FAILURE';

export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const TOGGLE_LOCATION_FROM = 'TOGGLE_LOCATION_FROM';
export const GET_LOCATION_FAILURE = 'GET_LOCATION_FAILURE';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_FAILURE = 'UPDATE_LOCATION_FAILURE';
export const DELETE_LOCATION_FAILURE = 'DELETE_LOCATION_FAILURE';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';


export const ADD_LOCATION_PAGINATION = 'ADD_LOCATION_PAGINATION';
export const RESET_LOCATION_PAGINATION = 'RESET_LOCATION_PAGINATION';


// Title
export const GET_TITLE_SUCCESS = 'GET_TITLE_SUCCESS';
export const GET_TITLE_FAILURE = 'GET_TITLE_FAILURE';
export const RESET_GET_TITLE = 'RESET_GET_TITLE';

export const SET_TITLE_FILES = 'SET_TITLE_FILES';
export const RESET_TITLE_FILES = 'RESET_TITLE_FILES';

export const ADD_TITLE = 'ADD_TITLE';
export const ADD_TITLE_SUCCESS = 'ADD_TITLE_SUCCESS';
export const ADD_TITLE_FAILURE = 'ADD_TITLE_FAILURE';

export const UPDATE_TITLE_SUCCESS = 'UPDATE_TITLE_SUCCESS';
export const UPDATE_TITLE_FAILURE = 'UPDATE_TITLE_FAILURE';

export const DELETE_TITLE_SUCCESS = 'DELETE_TITLE_SUCCESS';
export const DELETE_TITLE_FAILURE = 'DELETE_TITLE_FAILURE';

export const ADD_TITLES = 'ADD_TITLES';
export const REMOVE_ITEM_TITLES = 'REMOVE_ITEM_TITLES';
export const ADD_TITLE_PAGINATION = 'ADD_TITLE_PAGINATION';
export const RESET_TITLE_PAGINATION = 'RESET_TITLE_PAGINATION';

export const CREATE_TITLE = 'CREATE_TITLE';
export const UPDATE_TITLE = 'UPDATE_TITLE';
export const DELETE_TITLE = 'DELETE_TITLE';


// Group

export const CREATE_GROUP = 'CREATE_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const RESET_GROUP = 'RESET_GROUP';

export const ADD_GROUP = 'ADD_GROUP';
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';
export const ADD_GROUP_FAILURE = 'ADD_GROUP_FAILURE';

export const GET_GROUP_SUCCESS = 'GET_GROUP_SUCCESS';
export const TOGGLE_GROUP_FROM = 'TOGGLE_GROUP_FROM';
export const GET_GROUP_FAILURE = 'GET_GROUP_FAILURE';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILURE = 'UPDATE_GROUP_FAILURE';
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const ADD_GROUPEVENT_SUCCESS = 'ADD_GROUPEVENT_SUCCESS';
export const ADD_GROUPEVENT_FAILURE = 'ADD_GROUPEVENT_FAILURE';
export const DELETE_GROUPEVENT_SUCCESS = 'DELETE_GROUPEVENT_SUCCESS';
export const DELETE_GROUPEVENT_FAILURE = 'DELETE_GROUPEVENT_FAILURE';
export const ADD_EVENTGROUP_PAGINATION = 'ADD_EVENTGROUP_PAGINATION';
export const RESET_EVENTGROUP_PAGINATION = 'RESET_EVENTGROUP_PAGINATION';


export const ADD_GROUP_PAGINATION = 'ADD_GROUP_PAGINATION';
export const RESET_GROUP_PAGINATION = 'RESET_GROUP_PAGINATION';

// group member

export const GET_MEMBER_GROUP_SUCCESS = 'GET_MEMBER_GROUP_SUCCESS';
export const GET_MEMBER_GROUP_FAILURE = 'GET_MEMBER_GROUP_FAILURE';
export const REMOVE_MEMBER_GROUP = 'REMOVE_MEMBER_GROUP';
export const ADD_MEMBER_GROUP = 'ADD_MEMBER_GROUP';
export const GET_EVENT_GROUP_FAILURE = 'GET_EVENT_GROUP_FAILURE';
export const GET_EVENT_GROUP_SUCCESS = 'GET_EVENT_GROUP_SUCCESS';

// Rating
export const ADD_RATING = 'ADD_RATING';
export const ADD_RATING_SUCCESS = 'ADD_RATING_SUCCESS';
export const RESET_RATING = 'RESET_RATING';
export const ADD_RATING_FAILURE = 'ADD_RATING_FAILURE';

// waiting
export const GET_MEMBER_WAITING_SUCCESS = 'GET_MEMBER_WAITING_SUCCESS';
export const GET_MEMBER_WAITING_FAILURE = 'GET_MEMBER_WAITING_FAILURE';
export const RESET_MEMBER_WAITING = 'RESET_MEMBER_WAITING';

// File No
export const GET_EVENT_FILENO_SUCCESS = 'GET_EVENT_FILENO_SUCCESS';
export const GET_EVENT_FILENUMBER_FAILURE = 'GET_EVENT_FILENUMBER_FAILURE';
export const GET_EVENT_FILENUMBER_SUCCESS = 'GET_EVENT_FILENUMBER_SUCCESS';


export const ADD_MEMBERSEARCH_PAGINATION = 'ADD_MEMBERSEARCH_PAGINATION';
export const RESET_MEMBERSEARCH_PAGINATION = 'RESET_MEMBERSEARCH_PAGINATION';

export const ADD_EVENTREMINDER_PAGINATION = 'ADD_EVENTREMINDER_PAGINATION';
export const RESET_EVENTREMINDER_PAGINATION = 'RESET_EVENTREMINDER_PAGINATION';
export const GET_EVENT_REMINDER_SUCCESS = 'GET_EVENT_REMINDER_SUCCESS';
export const GET_EVENT_REMINDER_FAILURE = 'GET_EVENT_REMINDER_FAILURE';

